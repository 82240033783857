<div *ngIf="showAwaitAnimation == true" class="row full-width loading-animation-voucher no-margin full-centered">
    <div class="col-12 full-centered">
        <div class="loader-payment">Loading...</div>
        <i class="fas fa-file-upload icon-voucher"></i>
        <label class="label-voucher">Enviando comprobante</label>
    </div>
</div>

<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
        <i class="fas fa-file-upload pa-r-10 pa-l-10"></i>
        Envio de comprobante</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeCancelModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <!-- Progress -->
    <div class="row">
        <div class="col-12 full-centered">
            <span *ngIf="fileSourceToUpload.length == 0">

                <div class="image-upload ">
                    <input (change)="updateSource($event)" type="file" accept="image/jpeg"
                        class="custom-file-input pointer-hand" id="inputGroupFile01" />

                    <label class="pointer-hand image-btn normal-shadow max-btn-animate card full-centered" for="inputGroupFile01" style="border-radius: 25px;">
                        <img src="./assets/images/no-image-logo.jpg" class="pa-30" style="    width: 68% !important;">
                        <label class="pa-l-10" for="inputGroupFile01" style="cursor: pointer;">
                            Seleccionar archivo JPG (4 Mb Max)</label>
                    </label>
                </div>
            </span>

            <div *ngIf="fileSourceToUpload.length > 0" class="row full-width full-centered pd-10 ">
                <div class="col-12 ma-b-10-i full-centered">
                    <label class="pointer-hand image-btn normal-shadow max-btn-animate card full-centered" for="inputGroupFile01" style="border-radius: 25px;">
                        <img [src]="fileSourceToUpload" class="pa-30" style="    width: 68% !important;">
                    </label>
                </div>
                <div class="col-12 ma-b-10-i full-centered">
                    <button (click)="cancelImage()" class="btn mr-0 btn-outline-secondary normal-shadow max-btn-animate" type="button">
                        <i class="fas fa-trash-alt"></i>
                        Subir otra imagen
                    </button><br>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button (click)="closeCancelModal();"
        class="btn mr-0 btn-danger button-reset normal-shadow max-btn-animate" type="button">
        <div class="row full-centered font-normal">
            <i class="fas fa-window-close pa-r-10"></i> Cancelar
        </div>
    </button>
    <button 
        class="btn mr-0 btn-success button-reset color-white normal-shadow max-btn-animate"
        type="button" (click)="onSubmit()"
        [disabled]="fileSourceToUpload.length == 0">
        <div class="row full-centered font-normal pa-r-10">
            <i class="fas fa-file-upload pa-r-10"></i> Enviar
        </div>
    </button>
    <!-- <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button> -->
</div>