<div class="modal-header">
    <div class="row full-width no-margin image-background">

    </div>
</div>

<div class="row full-width no-margin close-row">
    <div class="col-1 full-left">
        <button type="button" class="close" aria-label="Close" (click)="closeCancelModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="col-11 full-centered">
        <h4 class="modal-title pa-r-10" id="modal-basic-title">
            <!-- <i class="fas fa-sign-in-alt pa-r-10"></i> -->
            <strong>
                Inicia sesión o regístrate
            </strong>
        </h4>
    </div>
    <div class="col-12 full-left pa-l-20 pa-t-20">
        <h2 class="modal-title pa-r-10" id="modal-basic-title">
            <!-- <i class="fas fa-sign-in-alt pa-r-10"></i> -->
            <strong>
                ¡Bienvenido a Enbibo!
            </strong>
        </h2>
    </div>
    <div class="col-12 full-left pa-l-20 pa-t-20">
        <div class="row full-width form-login-modal-box normal-shadow">
            <div class="col-12 full-centered pa-20">
                <img src="assets/images/personajes.png" alt="logo" style="height: 150px;" />
            </div>
            <div class="col-12 pa-20 pa-t-30">
                <div class="row full-width ">
                    <!-- Login form -->
                    <form *ngIf="isLogin == true" id="myForm" class="pa-t-20 form-inputs-btn" [formGroup]="loginForm"
                        (ngSubmit)="loginUser()">
                        <div class="form-group">
                            <div class="input-group">
                                <div class="row">
                                    <div class="col-1 full-centered">
                                        <i class="fas fa-user icon-size pa-r-10"></i>
                                    </div>
                                    <div class="col-11 full-centered">
                                        <div class="searchformfld">
                                            <input type="text" class="form-control" id="username" name="username"
                                                placeholder=" " required formControlName="userName" />
                                            <label for="username">Usuario</label>
                                        </div>
                                    </div>
                                    <div *ngIf="userName.invalid && userName.touched"
                                        class="validation-label alert alert-danger no-margin">
                                        <div *ngIf="userName.errors.required">
                                            Nombre de usuario requerido.
                                        </div>
                                        <div *ngIf="userName.errors.minlength">
                                            El nombre de usuario debe contener por lo menos 8 caracteres.
                                        </div>
                                        <div *ngIf="userName.errors.forbiddenName">
                                            El nombre de usuario cannot be Bob.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group pa-t-10">
                            <div class="input-group">
                                <div class="row">
                                    <div class="col-1 full-centered">
                                        <i class="fas fa-unlock-alt icon-size pa-r-10"></i>
                                    </div>
                                    <div class="col-11 full-centered">
                                        <div class="searchformfld">
                                            <input type="password" class="form-control" id="password" name="password"
                                                placeholder=" " required formControlName="password" />
                                            <label for="password">Contraseña</label>
                                        </div>
                                    </div>
                                    <div *ngIf="password.invalid && password.touched"
                                        class="validation-label alert alert-danger no-margin">
                                        <div *ngIf="password.errors.required">
                                            Contraseña requerida.
                                        </div>
                                        <div *ngIf="password.errors.minlength">
                                            La contraseña debe contener por lo menos 8 caracteres.
                                        </div>
                                        <div *ngIf="password.errors.forbiddenName">
                                            La contraseña cannot be Bob.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 full-centered no-padding pa-t-10">
                            <button class="btn mr-0 btn-primary hover-shadow max-btn-animate full-width"
                                (click)="loginUser()" [disabled]="loginForm.invalid || showBtnLoading">
                                <div class="loader loader-btn" *ngIf="showBtnLoading">Loading...</div>
                                <label *ngIf="!showBtnLoading" class="no-margin">
                                    <strong>Iniciar sesión</strong>
                                </label>
                            </button>
                        </div>
                    </form>
                    <!-- Register form -->
                    <form *ngIf="isLogin == false" id="manualRegisterForm" class="" autocomplete="off"
                        [formGroup]="socialRegisterForm" (ngSubmit)="socialRegisterUser()">
                        <div class="col-12 pa-20">
                            <div class="input-group">
                                <div class="row pa-10">
                                    <div class="col-2 full-right">
                                        <i class="fas fa-user icon-size pa-r-10"></i>
                                    </div>
                                    <div class="col-10 full-centered">
                                        <div class="searchformfld">
                                            <input autocomplete="off" type="text" class="form-control" id="fullName"
                                                name="fullName" placeholder=" " required formControlName="fullName" />
                                            <label for="fullName">Nombre para compartir</label>
                                        </div>
                                    </div>
                                    <div *ngIf="fullName.invalid && fullName.touched"
                                        class="validation-label alert alert-danger no-margin">
                                        <div *ngIf="fullName.errors.required">
                                            Nombre de usuario requerido.
                                        </div>
                                        <div *ngIf="fullName.errors.minlength">
                                            El nombre de usuario debe contener por lo menos 8 caracteres.
                                        </div>
                                        <div *ngIf="fullName.errors.forbiddenName">
                                            El nombre de usuario cannot be Bob.
                                        </div>
                                    </div>
                                </div>

                                <div class="row pa-10">
                                    <div class="col-2 full-right">
                                        <i class="fas fa-at icon-size pa-r-10"></i>
                                    </div>
                                    <div class="col-10 full-centered">
                                        <div class="searchformfld">
                                            <input autocomplete="off" type="text" class="form-control" id="email"
                                                name="email" placeholder=" " required formControlName="email" />
                                            <label for="email">Correo electrónico</label>
                                        </div>
                                    </div>
                                    <div *ngIf="email.invalid && email.touched"
                                        class="validation-label alert alert-danger no-margin">
                                        <div *ngIf="email.errors.required">
                                            Correo electrónico requerido.
                                        </div>
                                        <div *ngIf="email.errors.minlength">
                                            El correo electrónico debe contener por lo menos 8 caracteres.
                                        </div>
                                        <div *ngIf="email.errors.forbiddenName">
                                            El Correo electrónico cannot be Bob.
                                        </div>
                                    </div>
                                </div>

                                <div class="checkbox-inline pa-t-20 full-width full-centered">
                                    <div class="checker" id="uniform-inlineCheckbox9">
                                        <p>
                                            <input type="checkbox" id="test1" formControlName="acceptTerms" />
                                            <label for="test1" class="pa-l-10" style="color: #252525;">
                                                Acepto los
                                                <a target="_blank" routerLink="/terms-conditions">
                                                    Términos y condiciones</a>
                                            </label>
                                        </p>
                                    </div>
                                </div>

                                <div class="row pa-20">
                                    <div class="col-12 full-centered">
                                        <button
                                            [disabled]="fullName.invalid || email.invalid || acceptTerms.value != true || showBtnLoading"
                                            type="button"
                                            class="btn btn-success button-reset color-white normal-shadow max-btn-animate"
                                            (click)="openManualRegister()">
                                            <div class="row full-centered font-normal ">
                                                <svg *ngIf="!fullName.invalid && !email.invalid && acceptTerms.value == true && !showBtnLoading"
                                                    version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 130.2 130.2">
                                                    <circle class="path circle" fill="none" stroke="#f5f5f5"
                                                        stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1"
                                                        r="62.1" />
                                                    <polyline class="path check" fill="none" stroke="#f5f5f5"
                                                        stroke-width="6" stroke-linecap="round" stroke-miterlimit="10"
                                                        points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                                </svg>

                                                <svg *ngIf="fullName.invalid || email.invalid || acceptTerms.value != true || showBtnLoading"
                                                    version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 130.2 130.2">
                                                    <circle class="path circle" fill="none" stroke="#f5f5f5"
                                                        stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1"
                                                        r="62.1" />
                                                    <line class="path line" fill="none" stroke="#f5f5f5"
                                                        stroke-width="6" stroke-linecap="round" stroke-miterlimit="10"
                                                        x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                                                    <line class="path line" fill="none" stroke="#f5f5f5"
                                                        stroke-width="6" stroke-linecap="round" stroke-miterlimit="10"
                                                        x1="95.8" y1="38" x2="34.4" y2="92.2" />
                                                </svg>

                                                <div class="loader loader-btn" *ngIf="showBtnLoading">Loading...</div>

                                                <span *ngIf="!showBtnLoading" class="pa-l-10">
                                                    Registrarte
                                                </span>
                                            </div>
                                        </button>
                                    </div>
                                    <div class="col-12 full-centered pa-t-10">
                                        <label class="label-o">
                                            ó
                                        </label>
                                    </div>
                                    <div class="col-12 full-centered">
                                        <button (click)="signInWithFB()"
                                            class="btn mr-0 btn-facebook hover-shadow max-btn-animate full-width"
                                            [disabled]="showBtnLoading">
                                            <div class="loader loader-btn" *ngIf="showBtnLoading">Loading...</div>
                                            <label *ngIf="!showBtnLoading" class="no-margin">
                                                <i class="fab fa-facebook-square pa-r-10"></i>
                                                <strong>Continuar con Facebook</strong>
                                            </label>
                                        </button>
                                    </div>
                                    <div (click)="signInWithGoogle()" class="col-12 full-centered pa-t-20">
                                        <button class="btn mr-0 btn-google hover-shadow max-btn-animate full-width"
                                            style="width:100%;" [disabled]="showBtnLoading">
                                            <div class="loader loader-btn" *ngIf="showBtnLoading">Loading...</div>
                                            <label *ngIf="!showBtnLoading" class="no-margin full-centered">
                                                <img style="width: 25px;"
                                                    src="https://img.icons8.com/color/50/000000/google-logo.png" />
                                                <strong class="full-centered pa-t-5 pa-l-10">
                                                    Continuar con Google
                                                </strong>
                                            </label>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- Social btns -->
                <div class="row full-width" *ngIf="isLogin == true">
                    <div class="col-12 form-footer full-centered">
                        <div class="row pa-20">
                            <div class="col-12 full-centered">
                                <label class="label-o">
                                    ó
                                </label>
                            </div>
                            <div class="col-12 full-centered">
                                <button (click)="signInWithFB()"
                                    class="btn mr-0 btn-facebook hover-shadow max-btn-animate full-width"
                                    [disabled]="showBtnLoading">
                                    <div class="loader loader-btn" *ngIf="showBtnLoading">Loading...</div>
                                    <label *ngIf="!showBtnLoading" class="no-margin">
                                        <i class="fab fa-facebook-square pa-r-10"></i>
                                        <strong>Continuar con Facebook</strong>
                                    </label>
                                </button>
                            </div>
                            <div (click)="signInWithGoogle()" class="col-12 full-centered pa-t-20">
                                <button class="btn mr-0 btn-google hover-shadow max-btn-animate full-width"
                                    style="width:100%;" [disabled]="showBtnLoading">
                                    <div class="loader loader-btn" *ngIf="showBtnLoading">Loading...</div>
                                    <label *ngIf="!showBtnLoading" class="no-margin full-centered">
                                        <img style="width: 25px;"
                                            src="https://img.icons8.com/color/50/000000/google-logo.png" />
                                        <strong class="full-centered pa-t-5 pa-l-10">
                                            Continuar con Google
                                        </strong>
                                    </label>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #modalUserTownSelection let-modal id="modalUserTownSelection">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            <i class="fas fa-address-card pa-r-10"></i>
            Por favor completa los siguientes campos</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeCancelModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- <div class="row full-centered">
            <i class="fas fa-user-lock" style="font-size: xxx-large;"></i>
        </div> -->
        <div class="row full-width no-margin">

            <form id="socialRegisterForm" autocomplete="off" class="" [formGroup]="socialRegisterForm"
                (ngSubmit)="socialRegisterUser()">
                <div class="col-12">
                    <div class="row">
                        <div class="col-3 full-centered pa-l-10">
                            <img *ngIf="provider.value != 'DEFAULT'" [src]="photoSelected.value">
                            <img *ngIf="provider.value == 'DEFAULT'" src="assets/images/users/comentario-people.png">
                        </div>
                        <div class="col-7">
                            <div class="row" *ngIf="provider.value != 'DEFAULT'">
                                <div class="col-12">
                                    <h2>
                                        {{provider.value}}
                                    </h2>
                                </div>
                                <div class="col-12">
                                    <label>
                                        {{fullName.value}}
                                    </label>
                                </div>
                                <div class="col-12">
                                    <label>
                                        {{email.value}}
                                    </label>
                                </div>
                            </div>
                            <div class="row" *ngIf="provider.value == 'DEFAULT'">
                                <div class="col-12">
                                    <label class="fs-sm no-margin">
                                        <strong>Como te llamaremos</strong>
                                    </label>
                                </div>
                                <div class="col-12">
                                    <label class="text-capitalize">
                                        {{fullName.value}}
                                    </label>
                                </div>
                                <div class="col-12">
                                    <label class="fs-sm no-margin">
                                        <strong>Correo</strong>
                                    </label>
                                </div>
                                <div class="col-12">
                                    <label>
                                        {{email.value}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 pa-20">
                    <div class="input-group">
                        <div class="row pa-10">
                            <div class="col-2 full-right">
                                <i class="fas fa-user icon-size pa-r-10"></i>
                            </div>
                            <div class="col-10 full-centered">
                                <div class="searchformfld">
                                    <input autocomplete="off" type="text" class="form-control" id="userRegisterName"
                                        name="userRegisterName" placeholder=" " required
                                        formControlName="userRegisterName" />
                                    <label for="userRegisterName">Nombre de usuario</label>
                                </div>
                            </div>
                            <div *ngIf="userRegisterName.invalid && userRegisterName.touched"
                                class="validation-label alert alert-danger no-margin">
                                <div *ngIf="userRegisterName.errors.required">
                                    Nombre de usuario requerido.
                                </div>
                                <div *ngIf="userRegisterName.errors.minlength">
                                    El nombre de usuario debe contener por lo menos 8 caracteres.
                                </div>
                                <div *ngIf="userRegisterName.errors.forbiddenName">
                                    El nombre de usuario cannot be Bob.
                                </div>
                            </div>
                        </div>
                        <div class="row pa-10">
                            <div class="col-2 full-right">
                                <i class="fas fa-unlock-alt icon-size pa-r-10"></i>
                            </div>
                            <div class="col-10 full-centered">
                                <div class="searchformfld">
                                    <input autocomplete="off" type="password" class="form-control" id="passwordRegister"
                                        name="passwordRegister" placeholder=" " required
                                        formControlName="passwordRegister" />
                                    <label for="passwordRegister">Contraseña</label>
                                </div>
                            </div>
                            <div *ngIf="passwordRegister.invalid && passwordRegister.touched"
                                class="validation-label alert alert-danger no-margin">
                                <div *ngIf="passwordRegister.errors.required">
                                    Contraseña requerida.
                                </div>
                                <div *ngIf="passwordRegister.errors.minlength">
                                    La contraseña debe contener por lo menos 8 caracteres.
                                </div>
                                <div *ngIf="passwordRegister.errors.forbiddenName">
                                    La contraseña cannot be Bob.
                                </div>
                            </div>
                        </div>
                        <div class="row pa-10">
                            <div class="col-2 full-right">
                                <i class="fas fa-unlock-alt icon-size pa-r-10"></i>
                            </div>
                            <div class="col-10 full-centered">
                                <div class="searchformfld">
                                    <input autocomplete="off" type="password" class="form-control"
                                        id="confirmPassRegister" name="confirmPassRegister" placeholder=" " required
                                        formControlName="confirmPassRegister" />
                                    <label for="confirmPassRegister">Repetir contraseña</label>
                                </div>
                            </div>

                            <div *ngIf="confirmPassRegister.invalid && confirmPassRegister.touched"
                                class="validation-label alert alert-danger no-margin">
                                <div *ngIf="confirmPassRegister.errors.required">
                                    Contraseña requerida.
                                </div>
                                <div *ngIf="confirmPassRegister.errors.minlength">
                                    La contraseña debe contener por lo menos 8 caracteres.
                                </div>
                                <div *ngIf="confirmPassRegister.errors.forbiddenName">
                                    La contraseña cannot be Bob.
                                </div>
                            </div>
                            <div *ngIf="confirmPassRegister.value != passwordRegister.value && confirmPassRegister.touched"
                                class="validation-label alert alert-danger no-margin">
                                Las contraseñas no coinciden
                            </div>
                        </div>

                        <div class="row full-width pa-10">
                            <div class="col-2 full-right no-margin no-padding ">
                                <label class="no-margin pa-r-20 form-label-fixed">
                                    <span class="icon-size">
                                        <i class="fas fa-map-signs"></i>
                                    </span>
                                </label>
                            </div>
                            <div class="col-10 full-left no-margin no-padding">
                                <div class="input-group">
                                    <ng-multiselect-dropdown [disabled]="!socialRegisterForm.enabled"
                                        [ngModelOptions]="{standalone: true}" class="full-width"
                                        [placeholder]="'Seleccione un estado'" [settings]="dropdownStateSettings"
                                        [data]="statesData" [(ngModel)]="selectedState"
                                        (onSelect)="onStateSelect($event)" (onDeSelect)="onStateDeSelect($event)">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="row full-width pa-10">
                            <div class="col-2 full-right no-margin no-padding ">
                                <label class="no-margin pa-r-20 form-label-fixed">
                                    <span class="icon-size">
                                        <i class="fas fa-map-signs"></i>
                                    </span>
                                </label>
                            </div>
                            <div class="col-10 full-left no-margin no-padding">
                                <div class="input-group">
                                    <ng-multiselect-dropdown [disabled]="!socialRegisterForm.enabled"
                                        [ngModelOptions]="{standalone: true}" class="full-width"
                                        [placeholder]="'Seleccione una ciudad'" [settings]="dropdownTownSettings"
                                        [data]="townsData" [(ngModel)]="selectedTown" (onSelect)="onTownSelect($event)"
                                        (onDeSelect)="onTownDeSelect($event)">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="provider.value != 'DEFAULT'"
                            class="checkbox-inline pa-t-20 full-width full-centered">
                            <div class="checker" id="uniform-inlineCheckbox9">
                                <p>
                                    <input type="checkbox" id="test55" formControlName="acceptTerms" />
                                    <label for="test1" class="pa-l-60">
                                        Acepto los
                                        <a target="_blank" routerLink="/terms-conditions">Términos y
                                            condiciones</a></label>
                                </p>
                            </div>
                        </div>

                        <div class="modal-footer full-width">
                            <button (click)="closeCancelModal()" [disabled]="showBtnLoading"
                                class="btn mr-0 btn-danger button-reset max-btn-animate" type="button">
                                <div class="loader loader-btn" *ngIf="showBtnLoading">Loading...</div>
                                <div *ngIf="!showBtnLoading" class="row full-centered font-normal ">
                                    <i class="fas fa-times-circle pa-r-10"></i>
                                    Cancelar
                                </div>
                            </button>
                            <button [disabled]="socialRegisterForm.invalid || confirmPassRegister.value != passwordRegister.value || showBtnLoading ||
                            idState.value == -1 || idTown.value == -1" type="button"
                                class="btn btn-success button-reset color-white normal-shadow max-btn-animate"
                                (click)="socialRegisterUser()">
                                <div class="row full-centered font-normal ">
                                    <svg *ngIf="!socialRegisterForm.invalid && confirmPassRegister.value == passwordRegister.value && !showBtnLoading &&
                                        idState.value != -1 && idTown.value != -1" version="1.1"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle class="path circle" fill="none" stroke="#f5f5f5" stroke-width="6"
                                            stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                        <polyline class="path check" fill="none" stroke="#f5f5f5" stroke-width="6"
                                            stroke-linecap="round" stroke-miterlimit="10"
                                            points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                    </svg>

                                    <svg *ngIf="socialRegisterForm.invalid || confirmPassRegister.value != passwordRegister.value && !showBtnLoading ||
                                    idState.value == -1 || idTown.value == -1" version="1.1"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle class="path circle" fill="none" stroke="#f5f5f5" stroke-width="6"
                                            stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                        <line class="path line" fill="none" stroke="#f5f5f5" stroke-width="6"
                                            stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8"
                                            y2="92.3" />
                                        <line class="path line" fill="none" stroke="#f5f5f5" stroke-width="6"
                                            stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4"
                                            y2="92.2" />
                                    </svg>

                                    <div class="loader loader-btn" *ngIf="showBtnLoading">Loading...</div>

                                    <span *ngIf="!showBtnLoading" class="pa-l-10">
                                        Registrarte
                                    </span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #modalRedirectPartners let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-basic-title">
            <i class="fas fa-medal pa-r-10"></i>
            Bienvenido apreciado socio</h2>
        <button type="button" class="close" aria-label="Close" (click)="closeAll()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!-- <div class="row full-centered">
            <i class="fas fa-user-lock" style="font-size: xxx-large;"></i>
        </div> -->

        <div class="row full-width no-margin">
            <div class="col-10 full-centered">
                <div class="row">
                    <div class="col-12">
                        <button class="btn mr-0 btn-primary hover-shadow max-btn-animate full-width"
                            (click)="closeAll()">
                            <label class="no-margin pointer-hand">
                                <strong>
                                    <i class="fas fa-location-arrow pa-r-10"></i>
                                    Seguir navegando</strong>
                            </label>
                        </button>
                    </div>
                    <div class="col-12 pa-t-20">
                        <button class="btn mr-0 btn-primary hover-shadow max-btn-animate full-width"
                            (click)="redirectUser()">
                            <label class="no-margin pointer-hand">
                                <strong>
                                    <i class="fas fa-columns pa-r-10"></i>
                                    Ir a mi panel de control</strong>
                            </label>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-2 full-centered">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" style="width: 100%;">
                    <circle class="path circle" fill="none" stroke="#2bbb29" stroke-width="6" stroke-miterlimit="10"
                        cx="65.1" cy="65.1" r="62.1" />
                    <polyline class="path check" fill="none" stroke="#2bbb29" stroke-width="6" stroke-linecap="round"
                        stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                </svg>
            </div>
        </div>
    </div>
</ng-template>