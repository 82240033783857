
<div class="navbar-register full-centered" style="position: inherit !important;">
    <!-- ============================================================== -->
    <!-- toggle and nav items -->
    <!-- ============================================================== -->
    <ul class="navbar-nav float-left mr-auto">
        <li class="nav-item d-none d-md-block">
            <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves- black-color" href="javascript:void(0)" data-sidebartype="mini-sidebar">
                <i class="fa-solid fa-bars font-20"></i>
            </a>
        </li>
        <li class="pa-l-20 full-centered ">
            <!-- <p class="waves-effect waves-dark green-18 activated-label" *ngIf="dataSessionService.profileActivated == true">
                <i class="fas fa-circle pa-r-10 pointer-hand"></i>
                <label class="no-margin pointer-hand">Perfil Activo</label>
            </p> -->
            <button class="waves-effect waves-dark green-18 hover-green btn normal-shadow btn-outline-success" *ngIf="dataSessionService.profileActivated == true">
                <i class="far fa-circle pa-r-10 pointer-hand"></i>
                <label *ngIf="dataSessionService.pausedProfile == true  && dataSessionService.profileActivated == true" 
                    class="no-margin pointer-hand" title="Debe completar su perfil">Perfil Pausado</label>
                <label *ngIf="dataSessionService.pausedProfile == false && dataSessionService.profileActivated == true" 
                    class="no-margin pointer-hand">Perfil Activo</label>
            </button>
            <button class="waves-effect waves-dark red-18  btn normal-shadow btn-outline-danger" 
                *ngIf="dataSessionService.profileActivated == false && dataSessionService.profileCreated == true"
                routerLink="/dashboard-event-hall/payments">
                <i class="far fa-circle pa-r-10 pointer-hand"></i>
                <label class="no-margin pointer-hand">Perfil Desactivado</label>
            </button>
            <button [title]="'Aun no se tiene la información general del perfil'" 
                class="waves-effect waves-dark btn normal-shadow btn-outline-warning" 
                *ngIf="dataSessionService.profileActivated == false && dataSessionService.profileCreated == false"
                routerLink="/dashboard-band/information">
                <i class="far fa-circle pa-r-10 pointer-hand"></i>
                <label class="no-margin pointer-hand">Perfil Pausado</label>
            </button>
        </li>
    </ul>
    <!-- ============================================================== -->
    <!-- Right side toggle and nav items -->
    <!-- ============================================================== -->
    <ul class="navbar-nav float-right">
        <!-- ============================================================== -->
        <!-- User profile and search -->
        <!-- ============================================================== -->
        <li class="nav-item" ngbDropdown placement="bottom-right">
            <a routerLink="/terms-conditions" target="_blank" 
                class="nav-link waves-effect terms-label no-margin" style="padding : 0px !important;"
                title="Ayuda">
                <i class="fas fa-question-circle fs-l"></i>
            </a>

            <!-- User requests -->
            <span class="none-favs-mobile pa-10">
                <div class="d-inline-block favs-drop" ngbDropdown #myDropRequest="ngbDropdown">
                    <a class="fav-btn pointer-hand full-centered" id="dropdownRequest" 
                        style="color : #000000;" ngbDropdownToggle>
                        <span class="label-fav" >
                            <i (mouseover)="openDropDownRequests(myDropRequest);"
                                class="fas fa-envelope" style="font-size: 23px;"></i>
                            <!-- <i class="fas fa-chevron-down pa-l-10"></i> -->
                            <span class="pa-l-10">{{dataSessionService.noUnreadMessages}}</span>
                        </span>
                    </a>
                    <div ngbDropdownMenu aria-labelledby="dropdownRequest" [perfectScrollbar]="config"
                        class="dropdown-menu-right user-dd pa-t-10 dropdown-menu-left-fix">
                        <span>
                            <div [routerLink]="['/dashboard-band/messages']" class="pointer-hand card dropdown-item full-left normal-shadow fav-label-top no-margin">
                                <strong>
                                    <i class="fas fa-envelope pa-r-10"></i>
                                    <span class="pa-r-10">
                                        {{dataSessionService.profileOpenenedRequests.length}}
                                    </span>Chats
                                </strong>
                            </div>
                            <!-- <div class="dropdown-divider"></div> -->
                            <div class="full-width">
                                <div *ngIf="dataSessionService.requestReady == false"
                                    class="full-width loading-animation-favorite no-margin" style="border-radius: 0px;">
                                    <div class="col-12 full-height full-centered">
                                        <div class="loader-label-favorite">Loading...</div>
                                    </div>
                                </div>

                                <a [routerLink]="['/dashboard-band/messages']"
                                    [queryParams]="{ idChat: request.idProfile , type : request.type }"
                                    *ngFor="let request of dataSessionService.profileOpenenedRequests"
                                    [class]="'dropdown-item full-left max-btn-animate pointer-hand '+(request.noUnread > 0 ? 'unread-label' : '')"
                                    href="javascript:void(0)">

                                    <div class="mail-contnet" style="width: 100%;">
                                        <div class="row no-margin">
                                            <div class="col-2 full-centered no-padding">
                                                <span *ngIf="request.idRol == 100">
                                                    <img class="rounded-circle img-fluid img-chat"
                                                        src="assets/images/users/admin-user-image.jpg">
                                                </span>
                                                <span *ngIf="request.idRol == 400" class="text-uppercase">
                                                    <img [src]="request.imageUrl" alt="user"
                                                        class="rounded-circle rounded-circle-size">
                                                </span>
                                                <span *ngIf="request.idRol == 310 || request.idRol == 300 || 
                                                    request.idRol == 200 || request.idRol == 210">
                                                    <img style="width: 60px;height: 60px;"
                                                        [src]="request.imageLogoFileImageBlob" alt="users"
                                                        class="rounded-circle" />
                                                </span>
                                            </div>
                                            <div class="col-10">
                                                <div class="row">
                                                    <div class="col-8">
                                                        <h5>
                                                            <span *ngIf="request.idRol == 100"
                                                                class="text-capitalize">Administrador de enbibo <i
                                                                    class="fas fa-user-tag pa-l-10"></i></span>
                                                            <span
                                                                *ngIf="request.idRol == 200 || request.idRol == 210"
                                                                class="text-uppercase">{{request.name}} <i
                                                                    class="fas fa-music pa-l-10"></i></span>
                                                            <span
                                                                *ngIf="request.idRol == 300 || request.idRol == 310"
                                                                class="text-uppercase">{{request.name}} <i
                                                                    class="fas fa-hotel pa-l-10"></i></span>
                                                            <span *ngIf="request.idRol == 400"
                                                                class="text-capitalize">{{request.name}} <i
                                                                    class="fas fa-user-tag pa-l-10"></i></span>
                                                        </h5>
                                                    </div>
                                                    <div class="col-4 full-right" style="font-weight: bold;">
                                                        <span class="time pointer-hand"
                                                            style="color: #5a5858;">{{ utilitiesService.getOnlyTime( request.lasMessageDate )}}</span>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-11 full-centered">
                                                        <label style="width: inherit;" class="no-margin msg-label full-left pointer-hand">
                                                            {{request.lastmsg}}</label>
                                                    </div>
                                                    <div *ngIf="request.noUnread > 0" class="col-1 full-right">
                                                        <span class="label-no-unread pointer-hand">{{ request.noUnread }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </span>
                    </div>
                </div>
            </span>

            <a ngbDropdownToggle #myDropAccount class="sign-in-btn pointer-hand pa-10" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <i class="fas fa-bars pa-l-10"></i>
                <!-- <i class="flag-icon flag-icon-mx" style="padding: 16px;margin: 15px;"></i> -->
                <!-- <img src="assets/images/users/no-user-image.jpg" alt="user" class="rounded-circle" width="31"> -->
                <img *ngIf='dataSessionService.profileLogo.length == 0' 
                    src="assets/images/users/no-user-image.jpg" alt="user" class="rounded-circle" width="31">
                <img *ngIf='dataSessionService.profileLogo.length != 0' style="width: 31px;height: 31px;"
                    [src]="dataSessionService.imageLogoFileImageBlob" alt="users" 
                    class="rounded-circle img-fluid" />
            </a>
            <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
                <span class="with-arrow">
                    <span class="bg-primary"></span>
                </span>
                <div class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
                    <div class="">
                        <!-- <img src="assets/images/users/no-user-image.jpg" alt="user" class="img-circle" width="60"> -->
                        <img *ngIf='dataSessionService.profileLogo.length == 0' 
                            src="assets/images/users/no-user-image.jpg" alt="user" class="img-circle" width="60">
                        <img *ngIf='dataSessionService.profileLogo.length != 0' style="width: 60px;height: 50px;"
                            [src]="dataSessionService.imageLogoFileImageBlob" alt="users" 
                            class="rounded-circle img-fluid" />
                    </div>
                    <div class="m-l-10">
                        <h4 class="m-b-0">{{dataSessionService.user.name}}</h4>
                        <p class=" m-b-0">{{dataSessionService.user.email}}</p>
                    </div>
                </div>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item full-centered max-btn-animate" routerLink="/">
                    <i class="fas fa-home m-r-5 m-l-5"></i> Pagina principal</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item full-centered max-btn-animate" href="javascript:void(0)" (click)="logOut()">
                    <i class="fa fa-power-off m-r-5 m-l-5"></i> Cerrar sesión</a>
                <div class="dropdown-divider"></div>
                <!-- <div class="p-l-30 p-10">
                    <a href="javascript:void(0)" class="btn btn-sm btn-success btn-rounded">View Profile</a>
                </div> -->
            </div>
        </li>
        <!-- ============================================================== -->
        <!-- User profile and search -->
        <!-- ============================================================== -->
    </ul>
</div>
