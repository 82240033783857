import { EventType } from "./eventType.class";
import { MusicalGenre } from "./musicalGenre.class";

export class SearchQuery{
    location : string;
    idTown : number;
    idState : number;
    searchType : number;
    events : EventType[];
    genres : MusicalGenre[];
    persons : number;
    limitPrice : number;

    constructor(){
        this.location ="";
        this.idTown = -1;
        this.idState = -1;
        this.searchType = 0;
        this.events = [];
        this.genres = [];
        this.persons = null;
        this.limitPrice = null;
    }
  }