import { State, Town } from './statesTowns.class ';
import { User } from "./user.class";
import { EventType } from './eventType.class';
import { SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { ServiceProfileCommentDto } from './comments.class';
import { MusicalGenre } from './musicalGenre.class';
import { Instrument } from './instrument.class';

export class ServiceProfile {
  idServiceProfile: number;
  idUser: number;
  idTown: number;
  serviceType: number; // 0 - Band, 1 - Eventhall 
  active: boolean;
  reviews: number;
  score: number;
  name: string;
  integrationDate: Date;
  agent: string;
  photo: string;
  address: string;
  capacity: number;
  phone: string;
  email: string;
  basePrice: number;
  webPage: string;
  facebook: string;
  instagram: string;
  pinterest: string;
  spotify : string;
  description: string;
  latitude: number;
  longitude: number;
  updatedAt: Date;
  createdAt: Date;

  user: User;
  town: Town;
  profileReviews: ProfileReviewReviewsDto[];
  //serviceProfileFavorites: EventHallFavorites[];
  serviceProfileComments: ServiceProfileCommentDto[];
  profileEvents: ProfileEvent[];
  profileGenres: ProfileGenre[];
  profileInstruments: ProfileInstrument[];

  sliders: ProfileSlider[];

  interiors: ProfileInterior[];
  exteriors: ProfileExterior[];
  videos: ProfileVideo[];
  packages: ProfilePackage[];
  schedule : ProfileAgendaEvent[];

  constructor() {
    this.idServiceProfile = -1;
    this.idUser = -1;
    this.idTown = -1;
    this.serviceType = -1;
    this.active = false;
    this.reviews = 0;
    this.score = 0;
    this.name = "";
    this.integrationDate = new Date();
    this.agent = "";
    this.photo = "";
    this.address = "";
    this.capacity = 0;
    this.phone = "";
    this.email = "";
    this.basePrice = 0;
    this.webPage = "";
    this.facebook = "";
    this.instagram = "";
    this.pinterest = "";
    this.spotify = "";
    this.description = "";
    this.latitude = 0;
    this.longitude = 0;
    this.updatedAt = new Date();
    this.createdAt = new Date();

    this.user = new User();
    this.town = new Town();

    this.profileEvents = [];
    this.profileGenres = [];
    this.profileInstruments = [];

    this.interiors = [];
    this.exteriors = [];
    this.videos = [];
    this.packages = [];
    this.schedule = [];

    this.sliders = [];
    this.profileReviews = [];
    this.serviceProfileComments = [];
  }
}

export class ProfileListSlidersDto {
  idServiceProfile : number;
  basePrice : number;
  name : string;
  photo : string;
  profilePhotho : string;
  reviews : number;
  score : number;
  stateName : string;
  townName : string;
  imagesReady : boolean;

  active : boolean;

  type : number;

  imageLogoFileImageBlob : SafeUrl;
  imageSlideFileImageBlob : SafeUrl;

  constructor() {
    this.idServiceProfile = -1;
    this.basePrice = 0;
    this.name = "";
    this.photo = "";
    this.profilePhotho = "";
    this.reviews = 0;
    this.score = 0;
    this.stateName = "";
    this.townName = "";
    this.imagesReady = false;
    this.active = false;
    this.type = -1;

    this.imageLogoFileImageBlob = "";
    this.imageSlideFileImageBlob = "";
  }
}

export class ProfileEvent {
  idEventHallEvent: number;
  idServiceProfile: number;
  idEventType: number;
  //serviceProfile: ServiceProfile;
  eventType: EventType;
}

export class ProfileGenre {
  idProfileGenre : number;
  idMusicalGenre : number;
  idServiceProfile : number;
  createdAt : Date;
  serviceProfile: ServiceProfile;
  musicalGenre: MusicalGenre;
}

export class ProfileInstrument {
  idProfileInstrument : number;
  idInstrument : number;
  idServiceProfile : number;
  createdAt : Date;
  serviceProfile: ServiceProfile;
  instrument: Instrument;
}

export class ProfileSlider {
  idProfileSlider: number;
  idServiceProfile: number;
  url: string;
  order: number;
  comment: string;
  fileName: string;
  approved: any;
  updatedAt: Date;

  imageReady : boolean;
  imageSlideFileBlob : SafeUrl;

  constructor() {
    this.idProfileSlider = -1;
    this.idServiceProfile = -1;
    this.url = "";
    this.order = -1;
    this.comment = "";
    this.fileName = "";
    this.approved = false;
    this.updatedAt = new Date();

    this.imageReady = false;
  }
}

export class ProfileInterior {
  idProfileInterior: number;
  idServiceProfile: number;
  name: string;

  constructor() {
    this.idProfileInterior = -1;
    this.idServiceProfile = -1;
    this.name = '';
  }
}

export class ProfileExterior {
  idEserviceProfileExterior: number;
  idServiceProfile: number;
  name: string;

  constructor() {
    this.idEserviceProfileExterior = -1;
    this.idServiceProfile = -1;
    this.name = '';
  }
}

export class ProfileVideo {
  idProfileVideo: number;
  idServiceProfile: number;
  url: string ;

  constructor() {
    this.idProfileVideo = -1;
    this.idServiceProfile = -1;
    this.url = '';
  }
}

export class ProfilePackage {
  idProfilePackage: number;
  idServiceProfile: number;
  name: string;
  priceType: number;
  description: string;
  updatedAt: Date;
  createdAt: Date;

  prices: ProfilePackagesPrice[];
  extraServices: ProfilePackagesExtraService[] | ProfileExtraService[] | any;

  active?: boolean;

  constructor() {
    this.idProfilePackage = -1;
    this.idServiceProfile = -1
    this.name = "";
    this.priceType = -1
    this.description = "";
    this.updatedAt = new Date();
    this.createdAt = new Date();

    this.prices = [];
    this.extraServices = [];
    this.active = false;
  }
}

export class ProfilePackagesPrice {
  idProfilePackagesPrice: number;
  idProfilePackage: number;
  qtyMin: number;
  qtyMax: number;
  hasMax: boolean;
  price: number;
  updatedAt: Date;
  createdAt: Date;
  isEdit?: boolean;

  constructor() {
    this.idProfilePackagesPrice = -1;
    this.idProfilePackage = -1;
    this.qtyMin = 0;
    this.qtyMax = 0;
    this.hasMax = false;
    this.price = 0;
    this.updatedAt = new Date();
    this.createdAt = new Date();
    this.isEdit = false;
  }
}

export class ProfileExtraService {
  idProfileExtraService: number;
  idServiceProfile: number;
  name: string;
  price: number;
  createdAt: Date;
  updatedAt: Date;

  constructor() {
    this.idProfileExtraService = -1;
    this.idServiceProfile = -1;
    this.name = "";
    this.price = 0;
    this.createdAt = new Date();
    this.updatedAt = new Date();
  }
}

export class ProfilePackagesExtraService {
  idProfilePackagesExtraService: number;
  idProfilePackage: number;
  idProfileExtraService : number;
  extraService : ProfileExtraService;

  constructor() {
    this.idProfilePackagesExtraService = -1;
    this.idProfilePackage = -1;
    this.idProfileExtraService = -1;
    this.extraService = new ProfileExtraService();
  }
}

export class ProfileAgendaEvent {
  idProfileAgendaEvent : number;
  idServiceProfile : number;
  idUser : number;
  isManual : boolean;//
  clientName : string;//
  clientEmail : string;//
  clientPhone : string;//
  eventDate : Date;//
  endTime : Date;//
  description : string;//
  amount : number;//
  status : string;
  confirmedEvent : boolean;
  createdAt : Date;
  updatedAt : Date;

  clientData : User;
  payments : AgendaEventPayment[];

  constructor() {
    this.idProfileAgendaEvent = -1;
    this.idServiceProfile = -1;
    this.idUser = -1;
    this.isManual = true;
    this.clientName = '';
    this.clientEmail = '';
    this.clientPhone = '';
    this.eventDate = new Date();
    this.endTime = new Date();
    this.description = '';
    this.amount = 0;
    this.status = "waiting-payment";
    this.confirmedEvent = false;
    this.createdAt = new Date();
    this.updatedAt = new Date();
    
    this.clientData = new User();

    this.payments = [];
  }
}


export class AgendaEventPayment {
  idAgendaEventPayment : number;
  idProfileAgendaEvent : number;
  idUser : number;
  concept : string;//
  amount : number;//
  createdAt : Date;
  updatedAt : Date;

  product : string;
  status : string;
  commentForUser : string;
  needInvoice : boolean;
  invoiced : boolean;
  idInvoiceFacturapi : string;
  verificationUrl : string;
  object : string;
  idOrderConekta : string;

  isEdit : boolean;

  constructor() {
    this.idAgendaEventPayment = -1;
    this.idProfileAgendaEvent = -1;
    this.idUser = -1;
    this.concept = '';
    this.amount = 0;
    this.createdAt = new Date();
    this.updatedAt = new Date();
    this.isEdit = true;

    this.product = "";
    this.status = "";
    this.commentForUser = "";
    this.needInvoice = false;
    this.invoiced = false;
    this.idInvoiceFacturapi = "";
    this.verificationUrl = "";
    this.object = "";
    this.idOrderConekta = "";
  }
}

export class ProfileReview{
  idProfileReview: number;
  idServiceProfile: number;
  idUser: number;
  rate: number;
  qualityOfService: number;
  responseTime: number;
  professionalism: number;
  priceQualityRatio: number;
  flexibility: number;
  communication: number;
  status: number;
  userCommet: string;
  updatedAt : Date;
  createdAt : Date;

  imagesReview: ProfileImagesReview[];

  constructor(){
    this.idProfileReview = -1;
    this.idServiceProfile = -1;
    this.idUser = -1;
    this.rate = 0;
    this.qualityOfService = 0;
    this.responseTime = 0;
    this.professionalism = 0;
    this.priceQualityRatio = 0;
    this.flexibility = 0;
    this.communication = 0;
    this.status = 0;
    this.userCommet = '';
    this.updatedAt = new Date();
    this.createdAt = new Date();

    this.imagesReview = [];
  }
}

export class ProfileReviewReviewsDto{
  idProfileReview: number;
  idServiceProfile: number;
  idUser: number;
  rate: number;
  qualityOfService: number;
  responseTime: number;
  professionalism: number;
  priceQualityRatio: number;
  flexibility: number;
  communication: number;
  status: number;
  userCommet: string;
  updatedAt : Date;
  createdAt : Date;

  user : User;
  serviceProfile : /* ServiceProfile |  */ProfileListSlidersDto;
  imagesReview: ProfileImagesReview[];

  constructor(){
    this.idProfileReview = -1;
    this.idServiceProfile = -1;
    this.idUser = -1;
    this.rate = 0;
    this.qualityOfService = 0;
    this.responseTime = 0;
    this.professionalism = 0;
    this.priceQualityRatio = 0;
    this.flexibility = 0;
    this.communication = 0;
    this.status = 0;
    this.userCommet = '';
    this.updatedAt = new Date();
    this.createdAt = new Date();
    this.user = new User();
    this.serviceProfile = new ProfileListSlidersDto();
    this.imagesReview = [];
  }
}

export class ProfileImagesReview{
  idProfileImagesReview: number;
  idProfileReview: number;
  url: string;
  order: number;
  approved: boolean;
  haveImage: boolean;
  updatedAt : Date;
  createdAt : Date;
  
  constructor(){
    this.idProfileImagesReview = -1;
    this.idProfileReview = -1;
    this.url = '';
    this.order = 0;
    this.approved = false;
    this.haveImage = false;
    this.updatedAt = new Date();
    this.createdAt = new Date();
  }
}