import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig,
  NgbModalRef
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DataSessionService } from '../../../services/dataSession/data-session.service';
import { LoginModalComponent } from '../login-modal/login-modal.component';
declare var $: any;

@Component({
  selector: 'app-footer-public',
  templateUrl: './footer-public.component.html',
})
export class FooterPublicComponent implements AfterViewInit {
  public config: PerfectScrollbarConfigInterface = {};
  
  constructor(public dataSessionService: DataSessionService,private modalService: NgbModal) {}

  ngAfterViewInit() {}

  logOut(){
    this.dataSessionService.logOut();
  }
  async openLoginModal(isLogin : boolean) {
    let modalLogin : NgbModalRef = await this.modalService.open(
      LoginModalComponent, {
        ariaLabelledBy: 'modal-basic-title', 
        centered: true, 
        size : 'xl',
        windowClass :'full-window-login-modal'
    });

    modalLogin.componentInstance.isLogin = isLogin;

    modalLogin.result.then((result) => {
      //console.log("result");
      //console.log(result);
    }, (reason) => {
      //console.log("reason");
      //console.log(reason);
      if(reason.result == "login-success"){
        //to do
        //this.dataSessionService.checkProfileActiveData();
        //this.loadProfileData();
      }
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
