import { ProfilePackagesPrice, ProfileExtraService, ProfileAgendaEvent, AgendaEventPayment, ProfileReview, ProfileImagesReview, ProfileReviewReviewsDto } from './../../classes/serviceProfile.class';
import { Card } from './../../classes/card.class';
import { MusicalGenre } from './../../classes/musicalGenre.class';
import { EventType } from './../../classes/eventType.class';
import { Product, Quantity, ProductImage, ProductStickers } from './../../classes/product.class';
import { Brand } from './../../classes/brand.class';
import { Injectable } from '@angular/core';
import { deployConf } from './../../utils/config';
import { HttpClient, HttpHeaders, HttpEvent, HttpRequest } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { timeout, catchError } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { ServerMessage } from '../../classes/serverMessage.class';
import { User } from '../../classes/user.class';
import { Instrument } from '../../classes/instrument.class';
import { SliderHome } from '../../classes/slider.class';
import { AdsCodeDto } from '../../classes/adsCodes.class';
import { BillingInformation } from '../../classes/billingInformation.class';
import { ServiceProfile, ProfileSlider, ProfileInterior, ProfileExterior, ProfileVideo, ProfilePackage } from '../../classes/serviceProfile.class';
import { Observable } from 'rxjs';
import { ProfileAdminSlides } from '../../classes/slidesProfile.clas';
import { ChatListMessageDto } from '../../classes/chatMessagesDto.class';
import { SocialRegisterUserDto, SocialLoginUserDto } from '../../components/public/login-modal/login-modal.component';
import { ServiceProfileCommentDto } from '../../classes/comments.class';
import { SearchQuery } from '../../classes/searchQuerys.class';
import { PromotionCode } from '../../classes/promotionCode.class';

@Injectable({
  providedIn: 'root'
})
export class ApiDataService {
  baseURL: string = deployConf.apiUrl;
  token: String;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  setToken(newToken: String) {
    this.token = newToken;
  }


  getImage(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get(url, { headers: headers, responseType: 'blob' })
        .pipe(
          timeout(2000),
          catchError(e => {
            // do something on a timeout
            //reject(e);
            return of(null);
          })
        ).subscribe((imageBlob) => {
          let objectURL = "";
          if (imageBlob != null && imageBlob != undefined) {
            objectURL = URL.createObjectURL(imageBlob);
          }
          resolve(this.sanitizer.bypassSecurityTrustUrl(objectURL));
        }, (error: ServerMessage) => {
          reject(error)
        });
    })
  }

  async uploadImageBrand(formData: FormData) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'uploads/brand-image/', formData, { headers: headers })
        .subscribe((res: ServerMessage) => {
          if (res.error == false) {
            resolve(res);
          } else if (res.error == undefined) {
            console.log("error no llego nada");
            reject(res);
          } else {
            resolve(res);
          }
        }, (error) => {
          reject(error);
        });
    });
  }

  // PUBLIC END-POINTS
  doLogin(username: String, password: String) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const data = { username: username, password: password };

      this.http.post<ServerMessage>(this.baseURL + 'auth/login', data, {}).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  doSocialLogin(loginData: SocialLoginUserDto) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      this.http.post<ServerMessage>(this.baseURL + 'auth/social-login', loginData, {}).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  getSearchBarData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'public/data-search', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  getStatesTownsCatalogData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      this.http.get<ServerMessage>(this.baseURL + 'public/get-states-towns-catalog-data').subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  getPosibleLocations(searchTown: string) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'public/search-locations-coincidences', { searchTown: searchTown }, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  //Index
  getIndexData(idTown: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      /* const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      }); */

      this.http.get<ServerMessage>(this.baseURL + 'public/index/' + idTown/* , { headers: headers } */).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }
  //Search results
  getSearchResults( newQuery : SearchQuery ) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      /* const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      }); */

      this.http.post<ServerMessage>(this.baseURL + 'public/get-search-results' ,newQuery/* , { headers: headers } */).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }
  /* Public eventhall */
  getPublicServiceProfileData(idServiceProfile: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'public/get-public-service-profile-data/' + idServiceProfile, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }
  getPublicServiceProfileSecundaryData(idServiceProfile: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'public/get-public-service-profile-secundary-data/' + idServiceProfile, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }
  getPublicServiceProfileUserData(idServiceProfile: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'public/get-user-event-hall-data/' + idServiceProfile, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }
  
  getUserRole()  : Promise<ServerMessage>{
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'profile-data-final-user/get-user-role', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  getUserServiceProfileReview(idServiceProfile: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'profile-data-final-user/get-user-service-profile-review/' + idServiceProfile, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  updateUserProfileReview(data : { numberImages : number , review : ProfileReview , imagesToDelete : any[]}) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'profile-data-final-user/update-user-service-profile-review', data , { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }
  
  //Image review upload function
  uploadUserServiceProfileReviewImage(file: File, newImagesData : ProfileImagesReview): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    })

    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('dataFileToSet', JSON.stringify(newImagesData));
    /* 
    var length = 12;
    var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var randomFileName = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      randomFileName += charset.charAt(Math.floor(Math.random() * n));
    }

    formData.append('file', file, idServiceProfile + '-' + randomFileName + '.jpg');
    */
    const req = new HttpRequest('POST', this.baseURL + 'images-reviews-final-user/upload-review-image', formData, {
      headers: headers,
      reportProgress: true,
      responseType: 'json',
    });

    return this.http.request(req);
  }
  doCommentEventHall( serviceProfileCommentDto: ServiceProfileCommentDto) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'public/do-user-new-comment-event-hall',serviceProfileCommentDto, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }
  addDeleteFavoriteUserServiceProfile( idServiceProfile: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'public/add-delete-user-favorite-service-profile/'+idServiceProfile, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  checkAvailableDateEventhall( dateRanges: { idServiceProfile : number,fromDate : Date, toDate : Date }) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'public/check-available-date-eventhall',dateRanges, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  checkActiveRequestEventhallChat( newMessageData: { 
    idServiceProfile : number,
    name : string,
    email : string,
    telephone : string, 
    eventDate : Date 
  }) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'user/check-active-eventhall-request',newMessageData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  //USER END-POINTS
  async createNewSocialProfileUser(newSocialUserData: SocialRegisterUserDto) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'user/create-social-register-user', newSocialUserData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  
  async checkEmailAvailable(email: string) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'user/check-email-available-user', {email : email}, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  editFinalUserData( newUserData : User ) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'profile-data-final-user/edit-final-user-data',newUserData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  getUserData(token: String) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })

      this.http.get<ServerMessage>(this.baseURL + 'auth/validate-token', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  getProfileUserActiveFlags() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(this.baseURL + 'service-profile/profile-data/get-active-flags', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  getUserFavorites() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(this.baseURL + 'profile-data-final-user/get-user-favorites', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  getUserComments() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(this.baseURL + 'profile-data-final-user/get-user-comments', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  getUserReviews() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(this.baseURL + 'profile-data-final-user/get-user-reviews', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  /* Final user messages */
  getUserOpenRequest() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(this.baseURL + 'messages-final-user/get-chat-list-final-user', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  getChatRecordListUserViewData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(this.baseURL + 'messages-final-user/get-chat-list-record-final-user', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  getChatMessagesUserData(noMessages: number, chatToOpen: ChatListMessageDto) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(this.baseURL + 'messages-final-user/get-chat-messages-final-user',{
        noMessages: noMessages,
        chatToOpen: chatToOpen
      }, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async updateUserAdminData(newUserData: User) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/update-user-data', newUserData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async createNewProfileUser(newUserData: {
    fullName: string,
    telephone: string,
    userName: string,
    email: string,
    password: string,
    confirmPass: string,
    profileType: string,
    noAgent: number,
    acceptTerms: string,
    recaptcha: string
  }) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'user/create-user-profile', newUserData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  //Messages Event Hall
  getChatRecordListAdminViewData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'messages-admin/get-chat-list-record-admin', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }
  getChatListAdminViewData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'messages-admin/get-chat-list-admin', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }
  getChatMessagesAdminData(noMessages: number, chatToOpen: ChatListMessageDto) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'messages-admin/get-chat-messages-admin', {
        noMessages: noMessages,
        chatToOpen: chatToOpen
      }, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }
  getChatContactsAdminData(search: string, type: number) : Promise<ServerMessage> {//0-event-hall // 1-bands //2 - admin // 3-user
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'messages-admin/get-chat-contacts-admin', {
        search: search,
        type: type
      }, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  //ADMINISTRATOR END-POINTS

  //PROMOTIONS CODES
  getPromotionalCodes() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-promotional-codes', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async createPromotionalCode(newPromotionCode: PromotionCode) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/create-promotional-code', newPromotionCode, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async updatePromotionCode( promotionCode: PromotionCode) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/update-promotional-code', promotionCode, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async deletePromotionCode(idPromotionCode: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'administrator/delete-promotional-code/' + idPromotionCode, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  //EVENT TYPES
  getEventTypesListData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-event-types', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async createEventType(newEventType: EventType) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/create-event-type', newEventType, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async editEventType(updatedType: EventType) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/edit-event-type', updatedType, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async deleteEventType(idEventType: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'administrator/delete-event-type/' + idEventType, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  //INSTRUMENTS
  getInstrumentsListData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-instruments', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async createInstrument(newInstrument: Instrument) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/create-instrument', newInstrument, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async editInstrument(updatedInstrument: Instrument) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/edit-instrument', updatedInstrument, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async deleteInstrument(idInstrument: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'administrator/delete-instrument/' + idInstrument, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  //MUSICAL GENRES
  getMusicalGenresListData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-musical-genres', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async createMusicalGenre(newMusicalGenre: MusicalGenre) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/create-musical-genre', newMusicalGenre, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async editMusicalGenre(updatedMusicalGenre: MusicalGenre) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/edit-musical-genre', updatedMusicalGenre, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async deleteMusicalGenre(idMusicalGenre: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'administrator/delete-musical-genre/' + idMusicalGenre, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  //STATES CITIES
  getStatesCitiesListData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-states-cities', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  //USERS
  getNormalUsersListData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'users-administrator/get-normal-users', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  getBandUsersListData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'users-administrator/get-band-users', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  getEventHallUsersListData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'users-administrator/get-event-hall-users', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  getAgentUsersListData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'users-administrator/get-agent-users', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  getNewDataAgentUser(email: string) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'users-administrator/get-new-agent-user', { email: email }, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async resetUserPassById(idUser: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'users-administrator/reset-user-pass/' + idUser, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async changeProfileUserRole(idUser: number,idRol : number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      let data = {
        idUser : idUser,
        idRol : idRol,
      };

      this.http.post<ServerMessage>(this.baseURL + 'users-administrator/change-profile-user-role' ,data, 
        { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async cancelUserBandSubscriptionById(idUser: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'users-administrator/cancel-user-band-subscription/' + idUser, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async cancelUserEventHallSubscriptionById(idUser: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'users-administrator/cancel-user-event-hall-subscription/' + idUser, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async setUserToAgent(idUser: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'users-administrator/set-user-to-agent/' + idUser, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async setUserNormalUser(idUser: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'users-administrator/set-user-to-normal/' + idUser, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  //INVOICES
  getInvoicesListData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'payments-administrator/get-invoices-list-data', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async createInvoice(dataPayment) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'payments-administrator/create-invoice', dataPayment, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }


  //PAYMENTS
  getPaymentsListData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'payments-administrator/get-payments-list-data', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async validatePaymentVoucher(dataPayment) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'payments-administrator/validate-voucher', dataPayment, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async denyPaymentVoucher(dataPayment) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'payments-administrator/deny-voucher', dataPayment, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  //IMAGES Administrator
  getImagesPendingApproval() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'users-administrator/get-images-pending-approval', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  approveDisapproveSlides(profileAdminSlide: ProfileAdminSlides) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(this.baseURL + 'users-administrator/images-approval', profileAdminSlide, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  /* approveDisapproveEventHallComment(idProfileComment: number) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(this.baseURL + 'users-administrator/approve-disapprove-event-hall-comment/' + idProfileComment, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  } */

  //COMMENTS
  getCommentsListData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'users-administrator/get-comments', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  approveDisapproveBandComment(idBandComment: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(this.baseURL + 'users-administrator/approve-disapprove-band-comment/' + idBandComment, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  approveEventHallComment(idProfileComment: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(this.baseURL + 'users-administrator/approve-event-hall-comment/' + idProfileComment, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  disapproveEventHallComment(idProfileComment: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(this.baseURL + 'users-administrator/disapprove-event-hall-comment/' + idProfileComment, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }
  //REVIEWS
  getReviewsPendingApproval() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(this.baseURL + 'users-administrator/get-reviews-pending-approval', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  approveDisapproveUserReview( profileAdminSlide: ProfileReviewReviewsDto ) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(this.baseURL + 'users-administrator/review-approval', profileAdminSlide, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  /* disapproveEventHallComment(idProfileComment: number) {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(this.baseURL + 'users-administrator/disapprove-event-hall-comment/' + idProfileComment, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  } */

  //SLIDERS END-POINTS

  async getSlidersListData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'sliders-uploads/get-sliders', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async addSlider(newSlider: SliderHome) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'sliders-uploads/add-slider-image', newSlider, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async editSlidersData(newSlidersData: SliderHome[]) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'sliders-uploads/edit-sliders-data', newSlidersData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async uploadSliderFile(formData: FormData) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'sliders-uploads/add-slider-image-file/', formData, { headers: headers })
        .subscribe((res: ServerMessage) => {
          if (res.error == null || res.error == undefined) {
            console.log("error no llego nada");
            reject(res);
          } else if (res.error == false) {
            resolve(res);
          } else {
            resolve(res);
          }
        }, (error) => {
          reject(error);
        });
    });
  }

  async deleteSliderById(idSlider: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'sliders-uploads/delete-slider/' + idSlider, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  //ADS CODES END-POINTS

  async getAdsCodesListData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-ads-codes', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async updateAdCode(adCodeUpdated: AdsCodeDto) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/update-ad-code', adCodeUpdated, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  //STATES BANNERS
  getStatesBannersListData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'banners-uploads/get-states-banners', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async uploadBannerFile(formData: FormData) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'banners-uploads/add-banner-image-file/', formData, { headers: headers })
        .subscribe((res: ServerMessage) => {
          if (res.error == null || res.error == undefined) {
            console.log("error no llego nada");
            reject(res);
          } else if (res.error == false) {
            resolve(res);
          } else {
            resolve(res);
          }
        }, (error) => {
          reject(error);
        });
    });
  }

  deleteImageBannerById(idAdsBanner: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'banners-uploads/delete-banner/' + idAdsBanner, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }


  // PROFILE PAYMENTS
  getPromoByCode(promotionalCode : string) : Promise<ServerMessage>{
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/payments/get-promo-by-code', {
        promotionalCode : promotionalCode
      }, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }
  getPaymentsViewData() : Promise<ServerMessage>{
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'service-profile/payments/payments-view-data', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async addCardProfileService(newCardData: Card) : Promise<ServerMessage>{
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/payments/add-card', newCardData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async editCardSelectedPayment(newCardData: Card) : Promise<ServerMessage>{
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/payments/select-active-card', newCardData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async deleteCardEventHall(idCard: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'service-profile/payments/delete-card/' + idCard, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async changeActiveSubscription() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'service-profile/payments/change-active-subscription', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async changeRecurrentSubscription() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'service-profile/payments/change-recurrent-subscription', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async changeBuyMonths(buyMonths: number) : Promise<ServerMessage>{
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'service-profile/payments/change-active-months-subscription/' + buyMonths, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async paySubscriptionServiceProfile(dataPayment): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/payments/pay-subscription', dataPayment, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async uploadVoucherFileProfileService(fileData: FormData): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + /* 'pdf-uploads/add-pdf-file' */'service-profile/payments/add-voucher-file', fileData, {
        headers: headers,
        reportProgress: true
      })
        .subscribe((res: ServerMessage) => {
          if (res.error == null || res.error == undefined) {
            console.log("error no llego nada");
            reject(res);
          } else if (res.error == false) {
            resolve(res);
          } else {
            resolve(res);
          }
        }, (error) => {
          reject(error);
        });
    });
  }

  //Home Profile service (ServiceProfile & Band)
  getHomeProfileServiceViewData()  : Promise<ServerMessage>{
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(this.baseURL + 'service-profile/profile-data/get-data-home', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  //Messages Event Hall
  getChatRecordListProfileServiceViewData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'messages-profile/get-chat-list-record-profile', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  getChatListProfileServiceViewData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'messages-profile/get-chat-list-profile', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  getChatMessagesEventHallData(noMessages: number, chatToOpen: ChatListMessageDto) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'messages-profile/get-chat-messages-profile', {
        noMessages: noMessages,
        chatToOpen: chatToOpen
      }, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  //Information Event Hall
  getInformationProfileService() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'service-profile/profile-data/get-data-information', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  saveGeneralProfileServiceInformation(generalInformationFixed: ServiceProfile) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/save-general-information', generalInformationFixed, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  saveGenresInstrumentsData( genresInstrumentsData : { genres : MusicalGenre[], instruments : Instrument[] } ) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/save-genres-instruments-information', genresInstrumentsData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  saveContactEventHallInformation(generalInformationFixed: ServiceProfile) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/save-contact-information', generalInformationFixed, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  saveSocialEventHallInformation(generalInformationFixed: ServiceProfile) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/save-social-information', generalInformationFixed, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  //Shedule Profile Service
  getEventSheduleProfileServiceViewData(fromDate: Date, toDate: Date) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/get-event-shedule-data', {
        fromDate: fromDate,
        toDate: toDate
      }, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  addEventSheduleEventHall(newEventHallAgendaEvent: ProfileAgendaEvent) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/add-event-shedule', newEventHallAgendaEvent, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  updateEventSheduleEventHall(newEventData: {
    selectedAgendaEvent: ProfileAgendaEvent,
    paymentsDeleted: AgendaEventPayment[],
  }) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/update-event-shedule', newEventData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  deleteEventSheduleEventHall(idProfileAgendaEvent: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'service-profile/profile-data/delete-event-shedule/' + idProfileAgendaEvent, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  //Gallery Logo Service profile
  getGalleryServiceProfileViewData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(this.baseURL + 'gallery-service-profile/get-gallery-information', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async uploadLogoFileServiceProfile(fileData: FormData): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'gallery-service-profile/upload-logo-file', fileData, { headers: headers })
        .subscribe((res: ServerMessage) => {
          if (res == null || res == undefined) {
            console.log("error no llego nada");
            reject(res);
          } else if (res.error == null || res.error == undefined || res.error == false) {
            resolve(res);
          } else {
            resolve(res);
          }
        }, (error) => {
          reject(error);
        });
    });
  }

  //Gallery Sliders Service Profile
  uploadSlideImageServiceProfile(file: File | Blob , idServiceProfile: number, position: number): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    const formData: FormData = new FormData();
    //formData.append('file', file);

    var length = 20;
    var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var randomFileName = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      randomFileName += charset.charAt(Math.floor(Math.random() * n));
    }

    let format = ".jpg";
    if( file.type == "image/jpeg" ){
      format = ".jpg";
    }else if( file.type == "image/png" ){
      format = ".png";
    }

    formData.append('file', file, idServiceProfile + '-' + randomFileName + format);

    const req = new HttpRequest('POST', this.baseURL + 'gallery-service-profile/upload-slide-file/' + position, formData, {
      headers: headers,
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  async deleteSlideImageEventHallById(idProfileSlider: number) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'gallery-service-profile/delete-slide-image/' + idProfileSlider, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  async editSlideImagesOrderServiceProfile(serviceProfileSlidersData: ProfileSlider[]) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'gallery-service-profile/edit-slide-images', serviceProfileSlidersData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  //Setings Event Hall

  getSettingsProfileService() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'service-profile/profile-data/get-data-settings', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  saveBillinInformation(billingInformationFixed: BillingInformation) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/save-billing-information', billingInformationFixed, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  updatePassword(pass: any) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/update-password', pass, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  //Falilities Service Profile
  getFacilitiesServiceProfileViewData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'service-profile/profile-data/get-facilities-information', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  addFacilitieIndoorEventHall(newProfileInterior: ProfileInterior) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/add-facilitie-indoor', newProfileInterior, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  updateFacilitieIndoorEventHall(newProfileInterior: ProfileInterior) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/update-facilitie-indoor', newProfileInterior, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  deleteFacilitieIndoorEventHall(newProfileInterior: ProfileInterior) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/delete-facilitie-indoor', newProfileInterior, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  addFacilitieOutdoorEventHall(newProfileExterior: ProfileExterior) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/add-facilitie-outdoor', newProfileExterior, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  updateFacilitieOutdoorEventHall(newProfileExterior: ProfileExterior) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/update-facilitie-outdoor', newProfileExterior, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  deleteFacilitieOutdoorEventHall(newProfileExterior: ProfileExterior) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/delete-facilitie-outdoor', newProfileExterior, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  addFacilitieVideoServiceProfile(newProfileVideo: ProfileVideo) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/add-facilitie-video', newProfileVideo, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  deleteFacilitieVideoEventHall(newProfileVideo: ProfileVideo) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/delete-facilitie-video', newProfileVideo, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }
  //Packages Service Profile
  getPackagesServiceProfileViewData() : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'service-profile/profile-data/get-packages-information', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  addPackageServiceProfile(newEventHallPackage: ProfilePackage) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/add-package', newEventHallPackage, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  editPackageEventHall(newData: { selectedPackage: ProfilePackage, pricesDeleted: ProfilePackagesPrice[] }) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/edit-package', newData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }


  detetePackageServiceProfile(serviceProfilePackageToDelete: ProfilePackage) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/delete-package', serviceProfilePackageToDelete, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  addExtraServiceEventHall(newEventHallExtraServices: ProfileExtraService) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/add-extra-service', newEventHallExtraServices, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  editExtraServiceProfileService(selectedExtraService: ProfileExtraService) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/edit-extra-service', selectedExtraService, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  deteteExtraServiceEventHall(newEventHallExtraServices: ProfileExtraService) : Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'service-profile/profile-data/delete-extra-service', newEventHallExtraServices, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    });
  }

  //TO DO : Implementacion Servidor
  /*   async uploadImageUser(formData: FormData) {
      return new Promise((resolve,reject)=>{
        const headers = new HttpHeaders({
          'Authorization': 'Bearer '+this.token,
        });
  
        this.http.post<ServerMessage>(this.baseURL + 'uploads/user-image/', formData, {headers:headers })
          .subscribe((res: ServerMessage) => {
            if (res.error == false) {
              resolve(res);
            } else if( res.error == undefined){
              console.log("error no llego nada");
              reject(res);
            }else{
              resolve(res);
            }
          },(error)=>{
            reject(error);
          },);
      });
    } */

  //TO DO : Implementacion Servidor
  /*   deleteImageUser(idUser){
      return new Promise((resolve,reject)=>{
        const headers = new HttpHeaders({
          'Authorization': 'Bearer '+this.token,
        });
        this.http.get<ServerMessage>(this.baseURL + 'uploads/user-delete-image/'+idUser,{headers:headers}).subscribe((response : ServerMessage)=>{
          resolve(response);
        },(error)=>{
          reject(new ServerMessage(true,"A ocurrido un error inesperado",error));
        });
      });
    } */

  //EJEMPLO DE USO DEL METODO GET 
  /* async getRespuestas( entidad : string ) {
    return new Promise(async (resolve,reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.user.token
      })
       
      this.http.get<ServerMessage>(this.baseURL + 'user/obtener-respuestas',{ headers: headers }).subscribe((response : ServerMessage)=>{
        resolve(response);
      },(error)=>{
        reject(error)
      });
    });
  } */

  //EJEMPLO DE USO DEL METODO POST 
  /* async saveRespuestas(respuestas : any){
    return new Promise((resolve,reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.user.token
      });

      this.http.post<ServerMessage>(this.baseURL + 'user/save-respuestas',respuestas,{ headers: headers }).subscribe((response : ServerMessage)=>{
        resolve(response);
      },(error)=>{
        reject(error)
      });
    })
  } */
}
