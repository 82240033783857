import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    CommonModule,
    LocationStrategy,
    HashLocationStrategy
} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { ToastrModule } from 'ngx-toastr';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';

const maskConfig: Partial<IConfig> = { validation: false };

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 1,
    wheelPropagation: true,
    minScrollbarLength: 20,
};

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { deployConf } from './utils/config';
const config: SocketIoConfig = { url: deployConf.apiUrl, options: {}};
/* import { NgxMaskModule, IConfig } from 'ngx-mask'; 
const maskConfig: Partial<IConfig> = {
    validation: false,
  }; */
//Layouts

import { BlankComponent } from './layouts/blank/blank.component';
import { FullPublicComponent } from './layouts/full-public/full-public.component';
import { FullFinalUserComponent } from './layouts/full-final-user/full-final-user.component';
import { FullAdminComponent } from './layouts/full-admin/full-admin.component';
import { FullBandComponent } from './layouts/full-band/full-band.component';
import { FullEventHallComponent } from './layouts/full-event-hall/full-event-hall.component';
import { FullAgentComponent } from './layouts/full-agent/full-agent.component';

//Components
import { SpinnerComponent } from './components/loading/spinner.component';

/* import { NavigationPublicComponent } from './components/public/header-navigation-public/navigation-public.component';
import { FooterPublicComponent } from './components/public/footer-public/footer-public.component'; */

import { NavigationFinalUserComponent } from './components/final-user/header-navigation-final-user/navigation-final.user.component';
import { NavigationAdminComponent } from './components/admin/header-navigation-admin/navigation-admin.component';
import { NavigationServiceProfileComponent } from './components/service-profile/header-navigation-service-profile/navigation-service-profile.component';
import { NavigationAgentComponent } from './components/agent/header-navigation-agent/navigation-agent.component';

import { SidebarFinalUSerComponent } from './components/final-user/sidebar-final-user/sidebar-final-user.component';
import { SidebarAdminComponent } from './components/admin/sidebar-admin/sidebar-admin.component';
import { SidebarServiceProfileComponent } from './components/service-profile/sidebar-service-profile/sidebar-service-profile.component';
import { SidebarAgentComponent } from './components/agent/sidebar-agent/sidebar-agent.component';

import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';



/* import { SwiperModule } from 'swiper/angular'; */
import { VoucherSenderComponent } from './components/service-profile/voucher-sender/voucher-sender.component';
import { SliderSenderComponent } from './components/service-profile/slider-sender/slider-sender.component';

import { FooterPublicComponent } from './components/public/footer-public/footer-public.component';
import { NavigationPublicComponent } from './components/public/header-navigation-public/navigation-public.component';
/* import { CardComponent } from './components/card/card.component'; */
import {  UrlSerializer } from '@angular/router';
import CustomUrlSerializer from './utils/cutomUrlSerializer.class';
import { LoginModalComponent } from './components/public/login-modal/login-modal.component';

/* SOCIAL LOGIN */
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
    GoogleLoginProvider,
    FacebookLoginProvider
} from 'angularx-social-login';
import { UserReviewComponent } from './components/public/user-review/user-review.component';
import { DatetimerangepickerModule } from 'angular-datetimerangepicker';

@NgModule({
    declarations: [
        AppComponent,
        //Layouts
        BlankComponent,
        FullPublicComponent,
        FullFinalUserComponent,
        FullAdminComponent,
        FullBandComponent,
        FullEventHallComponent,
        FullAgentComponent,
        //Components
        NavigationPublicComponent,
        NavigationFinalUserComponent,
        FooterPublicComponent, 
        NavigationAdminComponent,
        NavigationServiceProfileComponent,
        NavigationAgentComponent,

        BreadcrumbComponent,
        SidebarFinalUSerComponent,
        SidebarAdminComponent,
        SidebarServiceProfileComponent,
        SidebarAgentComponent,
        
        SpinnerComponent,
        //Pages
        VoucherSenderComponent,
        SliderSenderComponent,
        LoginModalComponent,
        UserReviewComponent,
        /* CardComponent, */
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgbModule,
        ToastrModule.forRoot(),
        SlimLoadingBarModule.forRoot(),
        RouterModule.forRoot(Approutes, { scrollPositionRestoration: 'enabled' ,useHash: false }),
        PerfectScrollbarModule,
        Ng2SearchPipeModule,
        NgMultiSelectDropDownModule.forRoot(),
        /* NgxMaskModule.forRoot(maskConfig), */
        SocketIoModule.forRoot(config),
        NgxMaskModule.forRoot(maskConfig),
        CarouselModule,
        NgxCaptchaModule,
        /* SwiperModule, */
        SocialLoginModule,

        DatetimerangepickerModule,
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        { 
            provide: UrlSerializer, 
            useClass: CustomUrlSerializer 
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider('733144629606-k5agvieclcij3peeqhb1d7p9m0msd8jg.apps.googleusercontent.com'/* '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' */)
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider('1407938022714783')
                    }
                ]
            } as SocialAuthServiceConfig,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
