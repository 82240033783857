<div class="navbar-register" id="nav-search-section"
    [style.background-color]="isTop == true ? 'rgba(255, 255, 255, 0)': 'rgba(255, 255, 255, 1)'">
    <div class="max-navbar row">
        <div class="col-4">
            <div class="row">
                <div *ngIf="dataSessionService.user.idUser != -1" class="col-3 full-centered full-right display-menu-final-user">
                    <span class="nav-item d-md-block pa-t-5">
                        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light" href="javascript:void(0)" data-sidebartype="mini-sidebar">
                            <i class="fa-solid fa-bars font-20"></i>
                        </a>
                    </span>
                </div>
                <div class="col full-centered full-left padding-logo-bg">
                    <a routerLink="/">
                        <img *ngIf="isTop == false" class="logo-full-mobile" src="/assets/images/logohd.png">
                        <img *ngIf="isTop == true" class="logo-full-mobile" src="/assets/images/white-logohd.png">

                        <img *ngIf="isTop == false" class="logo-full-web" src="/assets/images/logo-icon.png"
                            style=" width: 35px;">
                        <img *ngIf="isTop == true" class="logo-full-web" src="/assets/images/white-logo-icon.png"
                            style=" width: 35px;">
                    </a>
                </div>
            </div>
        </div>
        <div class="col-4 col-sm-4 full-centered pa-t-10">
            <a routerLink="/" href="javascript:void(0)" class="btn pa-r-30 normal-txt white-hover-color a-links-disable"
                [style.color]="(isTop == true || (isTop == false && openMenu == false)) ? '#ffffff': '#000000'">
                <!-- <i class="far fa-user"></i> -->
                <span class="active-ne">Músicos para eventos
                    <hr [style.color]="(isTop == true || (isTop == false && openMenu == false)) ? '#ffffff': '#000000'"
                        [style.margin-bottom]="(isTop == true || (isTop == false && openMenu == false)) ? '0px': '10px'"
                        [style.border-top]="(isTop == true || (isTop == false && openMenu == false)) ? '3px solid #ffffff': '3px solid #000000'">
                </span>
            </a>
            <a routerLink="/preregister" href="javascript:void(0)"
                class="btn pa-r-30 normal-txt max-btn-animate white-hover-color a-links-disable"
                [style.color]="(isTop == true || (isTop == false && openMenu == false)) ? '#ffffff': '#000000'">
                <!-- <i class="far fa-user"></i> -->
                ¿Quieres ser socio?
                <hr class="hr-for-select" style="width: 10px;"
                    [style.color]="(isTop == true || (isTop == false && openMenu == false)) ? '#ffffff': '#000000'"
                    [style.margin-bottom]="(isTop == true || (isTop == false && openMenu == false)) ? '0px': '10px'"
                    [style.border-top]="(isTop == true || (isTop == false && openMenu == false)) ? '3px solid #ffffff': '3px solid #000000'">
            </a>

            <div [class]="'background-search ' + ((isTop == false && openMenu == true) ? 'background-search-opened': 'background-search-closed')"
                style="top: 60px;left: 0px;"
                [style.background-color]="(isTop == true && openMenu == true) ? '#rgba(255, 255, 255, 0)': 'rgba(255, 255, 255, 1)'">
                s
            </div>
            <div
                [class]="(openMenu == true) ? 'btn-search-top search-button enable-animation search-button-opened': 'btn-search-top search-button enable-animation search-button-closed'">
                <div class="row full-centered" [style.padding]="(openMenu == true) ? '0px': '0px 18px 0px 5px'">
                    <div *ngIf="(openMenu == true)" class="col-11 col-md-11 full-centered no-padding"
                        style="flex: 0 0 80.666667%;max-width: 80.66666667%;">
                        <div class="row full-right full-centered full-width">
                            <div class="col-sm-6 col-lg-3 border-right border-bottom full-centered pa-t-5 pa-b-5 pa-l-0"
                                *ngIf="openCols == true">
                                <div class="searchformfld">
                                    <input autocomplete="off" [(ngModel)]="dataSessionService.queryParams.location"
                                        (ngModelChange)="getPosibleLocations($event)" type="text" id="eventLocation"
                                        name="eventLocation" placeholder=" " />
                                    <label for="eventLocation" class="input-label-search">¿Donde es tu evento?</label>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-3 border-right-2 border-bottom full-centered"
                                *ngIf="openCols == true">
                                <!-- <div class="searchformfldsss full-centered"> -->
                                <!-- <input type="text" id="eventType" name="eventType" placeholder=" "/> -->
                                <ng-multiselect-dropdown id="eventType" name="eventType" style="z-index:1;height: 36px;"
                                    class="full-width multiselect-search" [placeholder]="' '"
                                    [settings]="dropdownConfigEventTypes" [data]="eventTypesData"
                                    [(ngModel)]="dataSessionService.queryParams.events">
                                </ng-multiselect-dropdown>
                                <label
                                    [style.left]="dataSessionService.queryParams.events.length == 0 ? '14px': '10px'"
                                    [style.top]="dataSessionService.queryParams.events.length == 0 ? 
                                        isTop == true ? '4px': '7px' : '-6px'"
                                    [style.color]="dataSessionService.queryParams.events.length == 0 ? '#939393': '#4e4d4d'"
                                    [style.font-size]="dataSessionService.queryParams.events.length == 0 ? 'initial': 'smaller'"
                                    class="no-margin label-fixed-event input-label-search">Tipo de evento</label>
                                <!-- </div> -->
                            </div>
                            <div class="col-sm-6 col-lg-3 border-right-2 border-bottom full-centered"
                                *ngIf="openCols == true && dataSessionService.queryParams.searchType == 0">
                                <!-- <div class="searchformfldsss full-centered"> -->
                                <!-- <input type="text" id="eventType" name="eventType" placeholder=" "/> -->
                                <ng-multiselect-dropdown id="eventType" name="eventType" style="z-index:1;height: 36px;"
                                    class="full-width multiselect-search" [placeholder]="' '"
                                    [settings]="dropdownConfigGenreTypes" [data]="genreTypesData"
                                    [(ngModel)]="dataSessionService.queryParams.genres">
                                </ng-multiselect-dropdown>
                                <label
                                    [style.left]="dataSessionService.queryParams.genres.length == 0 ? '14px': '10px'"
                                    [style.top]="dataSessionService.queryParams.genres.length == 0 ? 
                                        isTop == true ? '4px': '7px' : '-6px'"
                                    [style.color]="dataSessionService.queryParams.genres.length == 0 ? '#939393': '#4e4d4d'"
                                    [style.font-size]="dataSessionService.queryParams.genres.length == 0 ? 'initial': 'smaller'"
                                    class="no-margin label-fixed-event input-label-search">Géneros musicales</label>
                                <!-- </div> -->
                            </div>
                            <div class="col-sm-6 col-lg-3 border-right full-centered pa-b-5 pa-t-5" 
                                *ngIf="openCols == true && dataSessionService.queryParams.searchType == 1">
                                <div class="searchformfld">
                                    <input [(ngModel)]="dataSessionService.queryParams.persons" type="number"
                                        min="0" id="eventNumberPersons" name="eventNumberPersons" placeholder=" " />
                                    <label for="eventNumberPersons" class="input-label-search">Numero de
                                        invitados</label>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-3  full-centered pa-b-5 pa-t-5" *ngIf="openCols == true">
                                <div class="searchformfld">
                                    <input [(ngModel)]="dataSessionService.queryParams.limitPrice" type="number" min="0"
                                        id="eventLimitPrice" name="eventLimitPrice" placeholder=" " />
                                    <label for="eventLimitPrice" class="input-label-search">Precio limite</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div (click)="openMenuNav()" class="col full-centered  no-margin no-padding full-left pointer-hand"
                        *ngIf="(openMenu == false)" style="flex: 0 0 80.666667%;max-width: 80.66666667%;">
                        <label class="normal-txt no-margin pa-l-20 search-buscar">Buscar</label>
                    </div>
                    <div class="col-1 full-centered full-right no-margin"
                        [style.padding]="openMenu == false ? '0px 0px 0px 0px': '4px 8px'">
                        <!-- <label (click)="doSearch()" [class]="'no-margin search-icon-label pointer-hand  max-btn-animate '+
                                (openMenu == true ? 'search-icon-label-opened': 'search-icon-label-closed') "
                            [style.padding]="isTop == true ? '11px 15px': '4px 8px'">
                            <i class="fas fa-search"></i>
                        </label> -->
                        <button [disabled]="dataSessionService.queryParams.idState == -1" (click)="doSearch()" [class]="'no-margin search-icon-label pointer-hand  max-btn-animate '+
                                (openMenu == true ? 'search-icon-label-opened': 'search-icon-label-closed') "
                            [style.padding]="isTop == true ? '11px 15px': '4px 8px'">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>


                <div class="locations-box normal-shadow"
                    *ngIf="dataSessionService.queryParams.location.length > 0 && searchIncidents.length > 0">
                    <!-- <div class="col-12 full-centered full-left">
                            <label  class="normal-txt no-margin pa-l-10">
                                <strong>
                                    <i class="fas fa-search-location pa-r-10"></i>
                                    Seleccione un resultado
                                </strong>
                            </label>
                        </div> -->
                    <div class="col-12 full-centered" *ngFor="let location of searchIncidents">
                        <a (click)="selectLocation(location)" href="javascript:void(0)"
                            class="btn pa-r-30 normal-txt max-btn-animate full-width full-left">
                            <!-- <i class="far fa-user"></i> -->
                            <i class="fas fa-map-marker-alt pa-r-10"></i>
                            {{location.name}}
                            <!-- <hr class="hr-for-select"> -->
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4 col-sm-4 no-padding">
            <span *ngIf="dataSessionService.user.idUser == -1 " class="full-width full-right">
                <span>
                    <div class="d-inline-block" ngbDropdown #myDropLogin="ngbDropdown">
                        <a class="sign-in-btn pointer-hand" id="dropdownManual" ngbDropdownToggle
                            (focus)="myDropLogin.open()">
                            <i class="fas fa-bars pa-r-10 fav-icon-menu"></i>
                            <i class="fas fa-user-circle"></i>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownManual" class="drop-box-user">
                            <span>
                                <!-- <div class="dropdown-divider"></div> -->
                                <a class="dropdown-item full-left max-btn-animate" href="javascript:void(0)"
                                (click)="openLoginModal(false)">
                                    <i class="far fa-edit m-r-5 m-l-5"></i> <strong> Registrarte</strong></a>
                                <!-- <div class="dropdown-divider"></div> -->
                                <a class="dropdown-item full-left max-btn-animate" href="javascript:void(0)"
                                    (click)="openLoginModal(true)">
                                    <i class="fas fa-sign-in-alt m-r-5 m-l-5"></i> Iniciar sesión
                                </a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item full-left max-btn-animate" href="javascript:void(0)"
                                    routerLink="/preregister">
                                    <i class="fas fa-pen-nib m-r-5 m-l-5"></i> Conviértete en socio
                                </a>
                                <a class="dropdown-item full-left max-btn-animate" href="javascript:void(0)"
                                    routerLink="/faq">
                                    <i class="fas fa-question m-r-5 m-l-5"></i>Ayuda
                                </a>
                            </span>
                        </div>
                    </div>
                </span>
            </span>
            <span *ngIf="dataSessionService.user.idUser != -1" class="full-width full-right">

                <!-- User requests -->
                <span class="none-favs-mobile">
                    <div class="d-inline-block favs-drop" ngbDropdown #myDropRequest="ngbDropdown">
                        <a class="fav-btn pointer-hand" id="dropdownRequest" 
                            [style.color]="(isTop == true) ? '#ffffff': '#000000'" ngbDropdownToggle>
                            <span class="label-fav"
                                >
                                <i (mouseover)="openDropDownRequests(myDropRequest);myDropFavs.close();myDropAccount.close();"
                                    class="fas fa-envelope" style="font-size: 23px;"></i>
                                <!-- <i class="fas fa-chevron-down pa-l-10"></i> -->
                                <span class="pa-l-10">{{dataSessionService.noUnreadRequest}}</span>
                            </span>
                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownRequest" [perfectScrollbar]="config"
                            class="dropdown-menu-right user-dd pa-t-10" style="
                                top: 17px !important;left: -110px !important;height: 295px;">
                            <span>
                                <div [routerLink]="['/user-account/requests']" class="card dropdown-item full-left normal-shadow fav-label-top no-margin">
                                    <strong>
                                        <i class="fas fa-envelope pa-r-10"></i>
                                        <span class="pa-r-10">
                                            {{dataSessionService.userOpenenedRequests.length}}
                                        </span>Solicitudes
                                    </strong>
                                </div>
                                <!-- <div class="dropdown-divider"></div> -->
                                <div class="full-width">
                                    <div *ngIf="dataSessionService.requestReady == false"
                                        class="full-width loading-animation-favorite no-margin" style="border-radius: 0px;">
                                        <div class="col-12 full-height full-centered">
                                            <div class="loader-label-favorite">Loading...</div>
                                        </div>
                                    </div>

                                    <a [routerLink]="['/user-account/requests']"
                                        [queryParams]="{ idChat: request.idProfile , type : request.type }"
                                        *ngFor="let request of dataSessionService.userOpenenedRequests"
                                        [class]="'dropdown-item full-left max-btn-animate pointer-hand '+(request.noUnread > 0 ? 'unread-label' : '')"
                                        href="javascript:void(0)">

                                        <div class="mail-contnet" style="width: 100%;">
                                            <div class="row no-margin">
                                                <div class="col-2 full-centered no-padding">
                                                    <span *ngIf="request.idRol == 100">
                                                        <img class="rounded-circle img-fluid img-chat"
                                                            src="assets/images/users/admin-user-image.jpg">
                                                    </span>
                                                    <span *ngIf="request.idRol != 100">
                                                        <img [src]="request.imageLogoFileImageBlob" alt="user"
                                                            class="rounded-circle img-fluid img-chat">
                                                    </span>
                                                </div>
                                                <div class="col-10">
                                                    <div class="row">
                                                        <div class="col-8">
                                                            <h5>
                                                                <span *ngIf="request.idRol == 100"
                                                                    class="text-capitalize">Administrador de enbibo <i
                                                                        class="fas fa-user-tag pa-l-10"></i></span>
                                                                <span
                                                                    *ngIf="request.idRol == 200 || request.idRol == 210"
                                                                    class="text-uppercase">{{request.name}} <i
                                                                        class="fas fa-music pa-l-10"></i></span>
                                                                <span
                                                                    *ngIf="request.idRol == 300 || request.idRol == 310"
                                                                    class="text-uppercase">{{request.name}} <i
                                                                        class="fas fa-hotel pa-l-10"></i></span>
                                                                <span *ngIf="request.idRol == 400"
                                                                    class="text-capitalize">{{request.name}} <i
                                                                        class="fas fa-user-tag pa-l-10"></i></span>
                                                            </h5>
                                                        </div>
                                                        <div class="col-4 full-right" style="font-weight: bold;">
                                                            <span class="time pointer-hand"
                                                                style="color: #5a5858;">{{ utilitiesService.getOnlyTime( request.lasMessageDate )}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-11 full-centered">
                                                            <label class="no-margin msg-label pointer-hand">{{request.lastmsg}}</label>
                                                        </div>
                                                        <div *ngIf="request.noUnread > 0" class="col-1 full-right">
                                                            <span class="label-no-unread pointer-hand">{{ request.noUnread }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </span>
                        </div>
                    </div>
                </span>

                <span class="none-favs-mobile">
                    <div class="d-inline-block favs-drop" ngbDropdown #myDropFavs="ngbDropdown">
                        <a class="fav-btn pointer-hand" id="dropdownFavs"
                            [style.color]="(isTop == true) ? '#ffffff': '#000000'" ngbDropdownToggle>
                            <span (mouseover)="myDropRequest.close();openDropDownFavorites(myDropFavs);myDropAccount.close();"
                                class="label-fav">Favoritos</span>
                            <i class="fas fa-heart icon-fav-mov" 
                                (mouseover)="myDropRequest.close();openDropDownFavorites(myDropFavs);myDropAccount.close();"></i>
                            <i class="fas fa-chevron-down pa-l-10"></i>
                        </a>
                        <!--  <div (mouseover)="changeText=true" (mouseout)="changeText=false">
                            <span *ngIf="!changeText">Hide</span>
                            <span *ngIf="changeText">Show</span>
                          </div> -->
                        <div ngbDropdownMenu aria-labelledby="dropdownFavs" [perfectScrollbar]="config"
                            class="dropdown-menu-right user-dd pa-t-10" style="
                                top: 17px !important;left: -110px !important;height: 295px;">
                            <span>
                                <div class="card dropdown-item full-left fav-label-top" 
                                    style="align-items: start !important;margin-bottom: 0px !important;">
                                    <strong>
                                        <i class="fas fa-heart pa-r-10"></i>
                                        <!-- <span class="pa-r-10">
                                            {{dataSessionService.userServiceProfileFavorites.length}}
                                        </span> -->Favoritos
                                    </strong>
                                </div>
                                <!-- <div class="dropdown-divider"></div> -->
                                <div class="full-width" style="top: 36px;position: absolute;">
                                    <div *ngIf="dataSessionService.favoritesReady == false" style="height: 100%;"
                                        class="full-width full-height loading-animation-favorite no-margin">
                                        <div class="col-12 full-height full-centered">
                                            <div class="loader-label-favorite">Loading...</div>
                                        </div>
                                    </div>
                                    <div class="col-12 full-left full-width pa-t-20"
                                        style="flex-direction: row;"
                                        *ngIf="dataSessionService.userServiceProfileFavorites.length == 0">
                                        <div class="row no-results">
                                            <div class="col-12 full-centered">
                                                <img src="./assets/images/logo-ligth.jpg" class="">
                                            </div>
                                            <div class="col-12 full-centered pa-b-5">
                                                <h2 *ngIf="dataSessionService.favoritesReady == false" >
                                                    Cargando
                                                </h2>
                                                <h2 *ngIf="dataSessionService.favoritesReady == true" >
                                                    Sin favoritos
                                                </h2>
                                            </div>
                                            <div class="col-12 full-centered">
                                                <a routerLink="/" *ngIf="dataSessionService.favoritesReady == true"
                                                (click)="myDropFavs.close()"
                                                    class="btn mr-0 btn-primary hover-shadow max-btn-animate" type="submit">
                                                    <label class="no-margin pointer-hand">Descubrir músicos</label>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    <span *ngFor="let favorite of dataSessionService.userServiceProfileFavorites">
                                        <a class="dropdown-item full-left max-btn-animate fav-link-drop pointer-hand"
                                            [routerLink]="favorite.type == 0 ? ['/musicos'] : ['/salon-de-eventos']" 
                                            [queryParams]="{ id: favorite.idServiceProfile , name : favorite.name }"
                                            href="javascript:void(0)">

                                            <div *ngIf="favorite.imagesReady == true"
                                                class="col-4 full-centered full-centered no-padding">
                                                <img [src]="favorite.imageSlideFileImageBlob"
                                                    class="profile-image-slide fav-image">
                                                <img [src]="favorite.imageLogoFileImageBlob" class="logo-profile-image"
                                                    style="max-height: 130px;">
                                            </div>
                                            <div *ngIf="favorite.imagesReady == false"
                                                class="col-4 full-centered full-centered no-padding">
                                                <img src="./assets/images/logo-ligth.jpg"
                                                    class="profile-image-slide fav-image">
                                            </div>

                                            <div class="col-8 full-centered no-padding">
                                                <div class="row pa-t-10">
                                                    <div class="col-12 full-centered pa-l-10">
                                                        <label>
                                                            <h3
                                                                class="medium-txt fs-20 text-uppercase pointer-hand full-left">
                                                                <b style="width: 250px;overflow: hidden;">
                                                                    {{favorite.name}}
                                                                </b>
                                                            </h3>
                                                            <h4 class="normal-txt fs-16 pointer-hand full-left">
                                                                {{favorite.stateName}}, {{favorite.townName}}
                                                            </h4>
                                                        </label>
                                                    </div>
                                                    <div class="col-5 full-centered pa-l-30 pa-t-10">
                                                        <h4 class="normal-txt fs-16 ">
                                                            <span *ngIf="favorite.reviews > 0">
                                                                <span *ngFor="let item of [1,2,3,4,5] as items; index as i">
                                                                    <i *ngIf="i < (favorite.score/favorite.reviews)"
                                                                        class="fa fa-star checked"></i>
                                                                </span>
                                                                <span *ngFor="let item of [1,2,3,4,5] as items; index as i">
                                                                    <i *ngIf="i < 5 -(favorite.score/favorite.reviews)"
                                                                        class="fa fa-star unchecked"></i>
                                                                </span>
                                                            </span>
                                                            <span *ngIf="favorite.reviews == 0">
                                                                <span *ngFor="let item of [1,2,3,4,5] as items; index as i">
                                                                    <i class="fa fa-star unchecked"></i>
                                                                </span>
                                                            </span>
                                                        </h4>
                                                    </div>
                                                    <div class="col-7">
                                                        <label>
                                                            <h4 class="txt-al-end normal-txt fs-16 pointer-hand">Desde
                                                                <p>MXN $ <span class="org-txt-20 fw-600">
                                                                        {{ favorite.basePrice }}</span> </p>
                                                            </h4>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </span>
                                    
                                </div>
                                
                                <div class="card dropdown-item full-left fav-label-top show-all-favorites">
                                    <strong>
                                        <a routerLink="/user-account/favorites" href="javascript:void(0)">
                                            Ver todos los favoritos
                                        </a>
                                    </strong>
                                </div>
                            </span>
                        </div>
                    </div>
                </span>

                <!-- Account -->
                <span>
                    <div class="d-inline-block" ngbDropdown #myDropAccount="ngbDropdown">
                        <a class="sign-in-btn pointer-hand pa-10 full-centered" id="dropdownManual" ngbDropdownToggle
                        (mouseover)="myDropAccount.open();myDropRequest.close();myDropFavs.close();">
                            <i class="fas fa-bars pa-r-10"></i>

                            <div class="circle-profile-sm">
                                <span *ngIf="dataSessionService.user.idRol == 100">
                                    <img src="assets/images/users/admin-user-image.jpg">
                                </span>
                                <span *ngIf="dataSessionService.user.idRol == 400">
                                    <!-- <img *ngIf="dataSessionService.user.photoSelected== 'DEFAULT'"
                                        src="/styles/images/comentario-people.png"
                                        style="width: 100%;height: auto;padding-top: 10px;"> -->
                                    <i *ngIf="dataSessionService.user.photoSelected== 'DEFAULT'"
                                        class="fas fa-user-circle"></i>
                                    <img *ngIf="dataSessionService.user.photoSelected== 'FACEBOOK'"
                                        [src]="dataSessionService.user.facebookPhoto">
                                    <img *ngIf="dataSessionService.user.photoSelected== 'GOOGLE'"
                                        [src]="dataSessionService.user.googlePhoto">
                                </span>
                                <span
                                    *ngIf="dataSessionService.user.idRol == 310 || dataSessionService.user.idRol == 300 || 
                                    dataSessionService.user.idRol == 210 || dataSessionService.user.idRol == 200">
                                    <i *ngIf='dataSessionService.profileLogo.length == 0'
                                        class="fas fa-user-circle"></i>
                                    <img *ngIf='dataSessionService.profileLogo.length != 0'
                                        style="width: 32px;height: 32px;"
                                        [src]="dataSessionService.imageLogoFileImageBlob" alt="users"
                                        class="rounded-circle img-fluid" />
                                </span>
                            </div>

                        </a>
                        <div ngbDropdownMenu aria-labelledby="dropdownManual" class="drop-box-user">
                            <span>
                                <a routerLink="/user-account/home" class="dropdown-item full-left max-btn-animate" href="javascript:void(0)">
                                    <strong><i class="far fa-user m-r-5 m-l-5"></i>Mi cuenta</strong>-
                                    {{dataSessionService.user.username}}
                                </a>
                                <div class="dropdown-divider"></div>

                                <a *ngIf="dataSessionService.user.idRol == 400" class="dropdown-item full-left max-btn-animate" href="javascript:void(0)"
                                    routerLink="/preregister">
                                    <i class="fas fa-pen-nib m-r-5 m-l-5"></i> Conviértete en socio
                                </a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item full-left max-btn-animate" href="javascript:void(0)"
                                    *ngIf="dataSessionService.user.idRol != 400" (click)="goToDashboards()">
                                    <i class="fas fa-columns m-r-5 m-l-5"></i> Panel de control
                                </a>

                                <a class="full-left pa-10 btn-danger max-btn-animate" style="border-radius: 0px;"
                                    href="javascript:void(0)" (click)="logOut()">
                                    <i class="fa fa-power-off m-r-5 m-l-5"></i> Cerrar sesión</a>
                            </span>
                        </div>
                    </div>
                </span>
            </span>
        </div>
    </div>
</div>