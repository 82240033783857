import { Options } from './../../classes/imageCompressorOptions.class';
import { Injectable/* , ChangeDetectorRef */ } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import imageCompression from 'browser-image-compression';
import* as dayjs from "dayjs";

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  public validYoutubeUrl = /^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$/;
  timeOut : number= 10000;
  constructor(private toastr: ToastrService,public route: Router,/* public changeDetectorRef : ChangeDetectorRef */) {}

  formatNumber(amount : number){
    const options2 = { style: 'currency', currency: 'USD' };
    const numberFormat2 = new Intl.NumberFormat('en-US', options2);

    let price = numberFormat2.format(amount);

    return price.substr( 0, price.length - 3 );
  }
  imageFileCompression(imageFile : File , options : Options) : Promise<any>{
    return new Promise<any>(async (resolve, reject) => {
      try {
        //console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
        //console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

        const compressedFile = await imageCompression(imageFile, options);
        //console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        resolve(compressedFile);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }
  getDateTimepikerFormatDayJs( dateToFix ){
    return  dayjs(dateToFix);
  }
  getDateTimepikerFormat( dateToFix ){
    const fixedType = new Date(dateToFix);

    let montNum = fixedType.getUTCMonth() + 1;
    let fixMont = montNum.toString().length == 1 ? "0" + montNum : montNum;
    let fixDate = fixedType.getDate().toString().length == 1 ? "0" + fixedType.getDate() : fixedType.getDate();
    let dateFixed = "" + fixDate + "." + fixMont + "." + fixedType.getFullYear();

    
    let fixHour = fixedType.getHours().toString().length == 1 ? "0" + fixedType.getHours() : fixedType.getHours();
    let fixMin = fixedType.getMinutes().toString().length == 1 ? "0" + fixedType.getMinutes() : fixedType.getMinutes();
    let timeFixed =  "" + fixHour + ":" + fixMin;

    //console.log(dateFixed + ' ' + timeFixed+':00');
    return dateFixed + ' ' + timeFixed+':00';
  }
  validateYoutubeUrl(url : string){
      return this.validYoutubeUrl.test(url)
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  capitalizeAllWords(string : string){
    //return string.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
    return string.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
  }
  sentenceCase(input, lowercaseBefore = undefined) {
    input = ( input === undefined || input === null ) ? '' : input;
    if (lowercaseBefore) { input = input.toLowerCase(); }
    return input.toString().replace( /(^|\. *)([a-z])/g, function(match, separator, char) {
        return separator + char.toUpperCase();
    });
  }
  findAndReplace(string, target, replacement) {
    var i = 0, length = string.length;
    for (i; i < length; i++) {
      string = string.replace(target, replacement);
    }
    return string;
   }
  camelize(str : string) {
    let temp : string[] = str.split(" ");
    let tempString = this.capitalizeFirstLetter( temp[0] );

    for (let index = 1; index < temp.length; index++) {
      tempString = tempString + " " + this.capitalizeFirstLetter( temp[index] ) ;
      
    }
    return tempString;
  }
  addMonths( months : number ,date : Date = new Date() ) : Date{
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }
  //TOAST
  showSuccessToast(message : string , title : string) {
    this.toastr.success(message, title, { timeOut : this.timeOut });
  }
  
  showErrorToast(message : string , title : string) {
    this.toastr.error(message, title, { timeOut : this.timeOut });
  }

  showWarningToast(message : string , title : string) {
    this.toastr.warning(message, title, { timeOut : this.timeOut });
  }

  showInfoToast(message : string , title : string = null) {
    this.toastr.info(message,title, { timeOut : this.timeOut });
  }
  
  //END. TOAST

  //XX/XX
  getNowDate() {
    /* let montNum = new Date().getUTCMonth() + 1;
    let fixMont = montNum.toString().length == 1 ? "0" + montNum : montNum;
    return "" + new Date().getFullYear() + "-" + fixMont + "-" + new Date().getDate(); */
    return this.formatDateDayName(new Date());
  }
  formatDate1(dateToFix : Date) : string {
    return dateToFix.toLocaleDateString("es-MX",{day: "2-digit",month : "2-digit",year: "numeric"});
  }
  formatDate2(dateToFix : Date) : string {
    return dateToFix.toLocaleDateString("es-MX",{year: "numeric"})+ '-'+
    dateToFix.toLocaleDateString("es-MX",{month : "2-digit"})+ '-'+
    dateToFix.toLocaleDateString("es-MX",{day: "2-digit"});
  }
  getOnlyDate(string) {
    let montNum = new Date(string).getUTCMonth() + 1;
    let fixMont = montNum.toString().length == 1 ? "0" + montNum : montNum;

    let datNum = new Date(string).getDate();
    let fixDay = datNum.toString().length == 1 ? "0" + datNum : datNum;

    return "" + new Date(string).getFullYear() + "-" + fixMont + "-" + fixDay;
  }
  getOnlyTime(string) {
    const birthday = new Date(string);
    let state : string = " AM";

    if( birthday.getHours() > 12 ){
      state = " PM";
    }

    let fixMin = birthday.getMinutes().toString().length == 1 ? "0" + birthday.getMinutes() : birthday.getMinutes();
    return "" + birthday.getHours() + ":" + fixMin + state;
  }
  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  goToLink(url : string): void {
    this.route.navigateByUrl(url);
  }
  formatDateDayName(dateToFixOriginal : Date) : string {
    let dateToFix = dateToFixOriginal//new Date(dateToFixOriginal);

    return  this.capitalizeFirstLetter(dateToFix.toLocaleDateString("es-MX",{/* day: "long"  ,*/weekday : 'long'}))+
            ", "+dateToFix.toLocaleDateString("es-MX",{day: "2-digit"})+
            " de "+
            dateToFix.toLocaleDateString("es-MX",{month : "long"})+
            " de "+
            dateToFix.toLocaleDateString("es-MX",{year: "numeric"});
  }
  formatDateDayName3(dateToFixOriginal : Date) : string {
    let dateToFix = dateToFixOriginal//new Date(dateToFixOriginal);
    //console.log(dateToFixOriginal);
    

    return  this.capitalizeFirstLetter(dateToFix.toLocaleDateString("es-MX",{/* day: "long"  ,*/weekday : 'long'}))+
            ", "+dateToFix.toLocaleDateString("es-MX",{day: "2-digit"})+
            " de "+
            dateToFix.toLocaleDateString("es-MX",{month : "long"})+
            " de "+
            dateToFix.toLocaleDateString("es-MX",{year: "numeric"});
  }
  formatDateDayName2(dateToFixOriginal : Date) : string {
    let dateToFix = new Date(dateToFixOriginal);
    /* if(dateToFix === new Date() ){
      dateToFix = dateToFixOriginal;
    } */

    return  this.capitalizeFirstLetter(dateToFix.toLocaleDateString("es-MX",{/* day: "long"  ,*/weekday : 'long'}))+
            ", "+dateToFix.toLocaleDateString("es-MX",{day: "2-digit"})+
            " de "+
            dateToFix.toLocaleDateString("es-MX",{month : "long"})+
            " de "+
            dateToFix.toLocaleDateString("es-MX",{year: "numeric"});
  }
  downloadBlob(blob, name ) {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);
  
    // Create a link element
    const link = document.createElement("a");
  
    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = name;
  
    // Append link to the body
    document.body.appendChild(link);
  
    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', { 
        bubbles: true, 
        cancelable: true, 
        view: window 
      })
    );
  
    // Remove link from body
    document.body.removeChild(link);
  }
  b64toBlob(b64Data : string, contentType='', sliceSize=512): Blob {
    let fixed = b64Data.slice(23);
    
    const byteCharacters = atob(fixed);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
}
