export class BillingInformation{
    idBillingInformation : number;
    idUser : number;
    businessName : string;
    rfc : string;
    phone : string;
    email : string;
    state : string;
    city : string;
    postalCode : string;
    colony : string;
    street : string;
    number : string;
    tokenClientFacturApi : string;
    updatedAt : Date;

    constructor(){
        this.idBillingInformation = -1;
        this.idUser = -1;
        this.businessName = "";
        this.rfc= "";
        this.phone = "";
        this.email = "";
        this.state = "";
        this.city = "";
        this.postalCode = "";
        this.colony = "";
        this.street = "";
        this.number = "";
        this.tokenClientFacturApi = "";
        this.updatedAt = new Date();
    }
}