import { Injectable } from '@angular/core';
import { Socket, SocketIoConfig } from 'ngx-socket-io'
import { User } from '../../classes/user.class';
import { DataSessionService } from '../dataSession/data-session.service';
import { UtilitiesService } from '../utilities/utilities.service';
import { deployConf } from '../../utils/config';
import { ChatListMessageDto, MessageDto } from '../../classes/chatMessagesDto.class';

class SessionData { 
  idUser: number; 
  idRol: number; 
  email: string; 
  name: string; 
  idProfile: number;

  constructor(){
    this.idUser = -1;
    this.idRol = -1;
    this.email = "";
    this.name = "";
    this.idProfile = -1;
  }
};

@Injectable({
  providedIn: 'root'
})
export class ApiLiveChatDataService {
  socket: Socket;
  firstTimeConnection: boolean;
  isOnline: boolean;

  userSessionData: SessionData;

  constructor(public dataSessionService: DataSessionService , private utilitiesService: UtilitiesService) {
    this.firstTimeConnection = false;
    this.isOnline = false;
    this.userSessionData = new SessionData();
  }

  connect(callBack) {
    if (this.socket == undefined && this.isOnline == false) {
      let conf: SocketIoConfig = { url: deployConf.apiUrl/* deployConf.apiUrl */, options: { path: "/messages" } };
      this.socket = new Socket(conf);

      this.socket.on('connect', async () => {
        let sessionData : SessionData = new SessionData();
        sessionData.idUser = this.dataSessionService.user.idUser;
        sessionData.idRol = this.dataSessionService.user.idRol;
        sessionData.email = this.dataSessionService.user.email;
        sessionData.name = this.dataSessionService.user.name;
  
        this.socket.on('set-user-chat-data-'+this.dataSessionService.user.idUser, async (data : SessionData) => {
          this.userSessionData = data;
          
          this.isOnline = true;
        });
  
        this.socket.emit('set-user-chat-data', sessionData);
      });
      this.socket.on('disconnect', async () => {
        this.isOnline = false;
        //this.disconnect();
        //this.utilitiesService.showInfoToast("El chat a dejado de estar disponible");
      });
    }
    this.socket.connect();
    callBack();
  }

  //TO DO añadir todas las suscripciones y llamar cuando se hace un log out
  disconnect() {
    try {
      this.userSessionData = new SessionData();
      this.isOnline = false;
      this.socket.removeListener('connect', () => { });
      this.socket.removeListener('disconnect', () => { });
      this.socket.removeListener('update-user-chat-list-'+this.dataSessionService.user.idUser, () => { });
      this.socket.removeListener('update-user-chat-record-list-'+this.dataSessionService.user.idUser, () => { });
      this.socket.removeListener('set-user-chat-data-'+this.dataSessionService.user.idUser, () => { });
      //this.socket.removeListener('disconnect', () => { });

      this.socket.disconnect();
    } catch (error) {
      
    }
  }

  suscribeToChats( callBack ){
    this.socket.on('update-user-chat-list-'+this.dataSessionService.user.idUser, async (dataToUpdate : { idChat : number }) => {
      callBack(dataToUpdate);
    });
  }

  suscribeToRecordChats( callBack ){
    this.socket.on('update-user-chat-record-list-'+this.dataSessionService.user.idUser, async (dataToUpdate : { idChat : number }) => {
      callBack(dataToUpdate);
    });
  }

  sendMessage( messageDto : MessageDto ){
    this.socket.emit( 'add-message-to-chat' , messageDto);
  }

  openNewChat( newChatData : {idProfile: number,message: string,type: number} ){
    this.socket.emit( 'add-message-to-new-chat' , newChatData);
  }

  closeChat( chatToClose : ChatListMessageDto ){
    this.socket.emit( 'close-chat' , chatToClose);
  }
}
