import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiDataService } from '../../../services/apiData/api-data.service';
import { DataSessionService } from '../../../services/dataSession/data-session.service';
import { UtilitiesService } from '../../../services/utilities/utilities.service';
import { ServerMessage } from '../../../classes/serverMessage.class';
import { Options } from '../../../classes/imageCompressorOptions.class';

@Component({
  selector: 'app-voucher-sender',
  templateUrl: './voucher-sender.component.html',
  styleUrls: ['./voucher-sender.component.css']
})
export class VoucherSenderComponent implements OnInit {
  fileToUpload: File = null;
  fileSourceToUpload: string = "";

  showAwaitAnimation: boolean;
  // Emit an event when a file has been picked. Here we return the file itself
  @Output() onChange: EventEmitter<File> = new EventEmitter<File>();

  constructor(private modalService: NgbModal, private apiDataService: ApiDataService, public dataSessionService: DataSessionService,
    public utilitiesService: UtilitiesService) { }

  ngOnInit(): void {
  }

  closeCancelModal() {
    this.cancelImage();
    this.modalService.dismissAll({ result: "cancel" });
  }

  closeSuccessModal() {
    this.cancelImage();
    this.modalService.dismissAll({ result: "file-uploaded" });
  }

  // If the input has changed(file picked) we project the file into the img previewer
  async updateSource($event: Event) {
    // We access he file with $event.target['files'][0]
    if ($event.target['files'][0].size > (1048576 * 4)) {
      alert("El archivo es muy grande!");
      this.utilitiesService.showErrorToast("El archivo es muy grande!", "");
    } else {
      //this.fileToUpload = $event.target['files'][0];
      let file = $event.target['files'][0];

      const options: Options = {
        maxSizeMB: 1,             
        useWebWorker: true,        
        initialQuality: 0.8 
      }
       
      this.utilitiesService.imageFileCompression(file, options).then((compressedFile) => {
        let reader = new FileReader;
        // TODO: Define type of 'e'
        reader.onload = (e: any) => {
          // Simply set e.target.result as our <img> src in the layout
          this.fileSourceToUpload = e.target.result;
          //this.showPagesNumber = true;
          this.onChange.emit(compressedFile);
          this.fileToUpload = compressedFile;
        };
        // This will process our file and get it's attributes/data
        reader.readAsDataURL(compressedFile);
      },(error)=>{
        console.log(error);
        this.utilitiesService.showErrorToast("A ocurrido un error comprimiendo la imagen", "Error");
      });
    };
  }

  cancelImage() {
    this.fileSourceToUpload = "";
    this.fileToUpload = null;
  }

  onSubmit() {
    //console.log(this.fileModalForm.value);
    //console.log(this.fileToUpload);
    //console.log(this.fileSourceToUpload);
    let reader = new FileReader;
    this.showAwaitAnimation = true;

    try {
      // TODO: Define type of 'e'
      reader.onload = (e: any) => {
        this.onChange.emit(this.fileToUpload);

        const formData: FormData = new FormData();
        var length = 20;
        var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        var randomFileName = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
          randomFileName += charset.charAt(Math.floor(Math.random() * n));
        }

        formData.append('files[]', this.fileToUpload, this.dataSessionService.user.idUser + '-' + randomFileName + '.jpg');

        //Se puede añadir la variable con los datos a guardar
        //let dataFixed: any = {};
        //formData.append('dataFileToSet', JSON.stringify(dataFixed));

        //Loading de carga
        //Para salon
        if (this.dataSessionService.user.idRol == 200 || this.dataSessionService.user.idRol == 210 ||
          this.dataSessionService.user.idRol == 300 || this.dataSessionService.user.idRol == 310) {
          this.apiDataService.uploadVoucherFileProfileService(formData).then(async (result: ServerMessage) => {
            //console.log("imagen subida eittt");
            //console.log(result);

            if (result.error == false) {
              this.utilitiesService.showSuccessToast(result.message, "Éxito");
              this.cancelImage();
              this.closeSuccessModal();
            } else if (result.error == true) {
              console.log(result);
              this.utilitiesService.showErrorToast(result.message, "Error");
            }
            this.showAwaitAnimation = false;
          }, (error) => {
            console.log(error);
            this.utilitiesService.showErrorToast("A ocurrido un error", "Error");
            this.showAwaitAnimation = false;
          });
        } else {
          console.log("TO DO - FALTA AGREGAR EL SERVICIO DE SUBIDA DEL COMprobante della banda");

        }

      };
      // This will process our file and get it's attributes/data
      reader.readAsArrayBuffer(this.fileToUpload);
    } catch (error) {
      console.log("sin pdf seleccionado");
      this.utilitiesService.showErrorToast("Sin pdf seleccionado", "Error");
    }
  }
}
