
<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
        <i class="fas fa-file-upload pa-r-10 pa-l-10"></i>
        Envio de imágenes
        ( {{selectedFiles == null || selectedFiles == undefined ? 0 :  selectedFiles.length}} /
        {{dataSessionService.user.idRol == 210 || dataSessionService.user.idRol == 310 ? '∞' :  ''}}
        {{dataSessionService.user.idRol == 200 || dataSessionService.user.idRol == 300 ? 5 - actualNumberImages:  ''}}
        )
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="closeCancelModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <!-- Progress -->
    <div class="row">

        <div class="col-12 full-centered" >
            <div class="row full-width">
                <div class="col-6">
                    <span *ngIf="(5 - actualNumberImages) > 0">
                        Selecciona {{5 - actualNumberImages}} al menos imágenes para mostrar
                    </span>
                    <!-- <span *ngIf="(5 - actualNumberImages) <= 0">
                        Selecciona {{5 - actualNumberImages}} al menos imágenes para mostrar
                    </span> -->
                </div>
                <div class="col-6" *ngIf="selectedFiles.length != 0 && resultUploadSuccess == false">
                    <input (change)="selectFiles($event)" type="file" multiple accept="image/jpeg, image/png"
                        class="custom-file-input pointer-hand" id="inputGroupFile025" 
                        style="position: absolute;width: 0;"/>

                    <label class=" normal-shadow max-btn-animate btn btn-primary full-centered" for="inputGroupFile025"
                        style="border-radius: 25px;">
                        <i for="inputGroupFile025" class="pointer-hand fas fa-plus-square p-r-10"></i>
                        <label for="inputGroupFile025" class="pointer-hand no-margin">
                            Añadir Imágenes
                        </label>
                    </label>
                </div>
            </div>
        </div>
        <div class="col-12 full-centered">
            <span *ngIf="selectedFiles.length == 0">
                <div class="image-upload ">
                    <input (change)="selectFiles($event)" type="file" multiple accept="image/jpeg, image/png"
                        class="custom-file-input pointer-hand" id="inputGroupFile01" />

                    <label class="pointer-hand image-btn normal-shadow max-btn-animate card full-centered" for="inputGroupFile01"
                        style="border-radius: 25px;">
                        <img src="./assets/images/logo-ligth.jpg" class="pa-30" style="width: 68% !important;">
                        <label class="pa-l-10" for="inputGroupFile01" style="cursor: pointer;">
                            Seleccionar archivos JPG / PNG (20 Mb Max c/u)</label>
                    </label>
                </div>
            </span>

            <div *ngIf="selectedFiles.length != 0" class="row full-width full-centered pd-10 ">
                <div class="col-12 col-lg-4 col-md-6 ma-b-10-i full-centered"
                    *ngFor="let newSlide of filesSourceToUpload; let i = index">
                    <div class="row">
                        <!-- <div class="col-2 full-centered">
                            <label>{{i}}</label>
                        </div> -->
                        <div class="col-12 full-centered">
                            <img [src]="newSlide" class="pa-30 normal-shadow slide-image-tm">
                            <label class="image-index">{{ i + 1 }}</label>
                            <label (click)="deleteActualSavedSpecificImage(i)"
                                class="image-delete no-margin max-btn-animate">
                                <i class="fas fa-trash"></i>
                            </label>
                            <!-- #18113b -->
                            <div *ngIf="messageErrors[i].loading == true || messageErrors[i].error == true" 
                                class="row full-width loading-animation-image no-margin full-centered"
                                    style="background: rgb(240 240 240 / 84%);">
                                <div class="col-12 full-centered">
                                    <div class="loader-slides">Loading...</div>
                                    <i class="fas fa-image icon-image"></i>
                                    <label class="label-image">Enviando imágenes</label>
                                </div>
                                <div class="col-12 full-centered">
                                    <div class="progress" style="width: 90%;">
                                        <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                                            attr.aria-valuenow="{{ progressInfos[i].value }}" aria-valuemin="0" aria-valuemax="100"
                                            [ngStyle]="{ width: progressInfos[i].value + '%' }">
                                            {{ progressInfos[i].value }}%
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="messageErrors[i].loading == false && messageErrors[i].message.length" 
                                class="row full-width loading-animation-image no-margin full-centered"
                                style="background: rgb(240 240 240 / 84%);">
                                <div class="col-12 full-centered">
                                    <svg *ngIf="messageErrors[i].error == false" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                                        <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                                    </svg>
    
                                    <svg *ngIf="messageErrors[i].error == true" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle class="path circle" fill="none" stroke="#D06079" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                                        <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                                        <line class="path line" fill="none" stroke="#D06079" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
                                    </svg>
                                </div>
                            </div>
                            
                        </div>
                        <div class="col-12 pa-t-10">
                            <label class="fs-16">{{ progressInfos[i].fileName.substring(0,20) }}...</label>
                        </div>      
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 full-centered">
            <div class="row full-width checkbox-inline" style="display: block;">
                <div class="checker" id="uniform-inlineCheckbox-image-sender">
                    <p>
                        <input [(ngModel)]="aceptTerms" type="checkbox" id="acept-images" />
                        <label for="acept-images" class="pa-l-60 no-margin">
                            Al compartir las imágenes aceptas los
                            <a target="_blank" routerLink="/terms-conditions">Términos y
                                condiciones</a></label>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer" *ngIf="resultUploadSuccess == false">
    <button (click)="closeCancelModal();" class="btn mr-0 btn-danger button-reset  normal-shadow max-btn-animate" type="button">
        <div class="row full-centered font-normal">
            <i class="fas fa-window-close pa-r-10"></i> Cancelar
        </div>
    </button>
    <button class="btn mr-0 btn-success button-reset color-white normal-shadow max-btn-animate" type="button"
        [disabled]="!selectedFiles || dataSessionService.profileActivated == false ||
        (5 - actualNumberImages) > selectedFiles.length || selectedFiles.length == 0 || !aceptTerms" 
        (click)="uploadFiles()">
        <div class="row full-centered font-normal pa-r-10">
            <i class="fas fa-file-upload pa-r-10"></i> Subir
            {{selectedFiles == null || selectedFiles == undefined ? 0 :  selectedFiles.length}}
        </div>
    </button>
    <!-- <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button> -->
</div>

<div class="modal-footer" *ngIf="resultUploadSuccess == true">
    <button class="btn mr-0 btn-success button-reset color-white normal-shadow max-btn-animate" type="button"
        [disabled]="!selectedFiles" (click)="closeSuccessModal()">
        <div class="row full-centered font-normal pa-r-10">
            <i class="fas fa-check-circle pa-r-10"></i> Aceptar
        </div>
    </button>
    <!-- <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button> -->
</div>