import { Component, AfterViewInit, EventEmitter, Output, OnInit } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DataSessionService } from '../../../services/dataSession/data-session.service';
import { LoggedResponse } from '../../../classes/loggedResponse.class';
import { UtilitiesService } from '../../../services/utilities/utilities.service';
import { ApiDataService } from '../../../services/apiData/api-data.service';
import { ApiLiveChatDataService } from '../../../services/liveChat/api-live-chat-data.service';
declare var $: any;

@Component({
  selector: 'app-navigation-service-profile',
  templateUrl: './navigation-service-profile.component.html',
  styleUrls: ['./navigation-service-profile.component.scss']
})
export class NavigationServiceProfileComponent implements AfterViewInit, OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;

  constructor(public dataSessionService: DataSessionService, public utilitiesService: UtilitiesService,
    private apiLiveChatDataService : ApiLiveChatDataService,
    ) {}

  ngAfterViewInit() {}

  ngOnInit(): void {
    this.dataSessionService.checkLogin((logedResponse: LoggedResponse) => {
      if (this.dataSessionService.user.idRol == 1000) {                                                    //Agent
        console.log("es un agente");
        this.dataSessionService.navigateByUrl("/dashboard-agent/home");
      } else if (this.dataSessionService.user.idRol == 400) {                                                //Normal
        console.log("es normal");
        this.dataSessionService.navigateByUrl("/");
      } else if (this.dataSessionService.user.idRol == 310 || this.dataSessionService.user.idRol == 300 ||  //Event Hall
        this.dataSessionService.user.idRol == 210 || this.dataSessionService.user.idRol == 200) {           //Band
        //console.log("es salon");
        //this.dataSessionService.navigateByUrl("/dashboard-event-hall/home");
        //this.dataSessionService.navigateByUrl("/dashboard-band/home");

        this.dataSessionService.checkProfileActiveData();
      } else if (this.dataSessionService.user.idRol == 100) {                                              //Admin
        console.log("es admin");
        this.dataSessionService.navigateByUrl("/dashboard-admin/home");
      } else {
        this.utilitiesService.showErrorToast("Usuario desconocido.", "Error!");
        this.dataSessionService.logOut();
      }
    }, (noLoginResponse: LoggedResponse) => {
      //console.log(noLoginResponse);
      this.dataSessionService.logOut();
    });
  }

  openDropDownRequests(myDropRequest){
    this.dataSessionService.getActiveRequests();
    /* if(this.alreadyFavOpen == true){ 
      this.alreadyFavOpen=true;
      myDropFavs.open();
    } */
    myDropRequest.open();
  }

  logOut(){
    this.apiLiveChatDataService.disconnect();
    this.dataSessionService.logOut();
  }
}
