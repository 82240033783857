import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SEOServiceService } from '../../services/SEOService/seoservice.service';
import { DataSessionService } from '../../services/dataSession/data-session.service';

@Component({
  selector: 'app-full-event-hall-layout',
  templateUrl: './full-event-hall.component.html',
  styleUrls: ['./full-event-hall.component.scss']
})
export class FullEventHallComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};

  constructor(public router: Router, private sEOServiceService: SEOServiceService,private dataSessionService : DataSessionService) {
    this.sEOServiceService.updateMeta(() => { });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
      document.querySelector('.navbar-register').classList.add('fixed-shadow');
    } else {
      document.querySelector('.navbar-register').classList.remove('fixed-shadow');
    }
  }

  tabStatus = 'justified';

  public isCollapsed = false;

  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = false;

  options = {
    theme: 'light', // two possible values: light, dark
    dir: 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // fixed value. shouldn't be changed.
    sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin5', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin5' // six possible values: skin(1/2/3/4/5/6)
  };

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  ngOnInit() {
    if (this.router.url === '/') {
      this.router.navigate(['/dashboard/dashboard1']);
    }
    this.defaultSidebar = this.options.sidebartype;
    this.handleSidebar();

    
    if (this.dataSessionService.user.idRol == 310 || this.dataSessionService.user.idRol == 300) { //Event Hall
      //console.log("es salon");
      //this.dataSessionService.navigateByUrl("/dashboard-event-hall/home");
    } else if (this.dataSessionService.user.idRol == 210 || this.dataSessionService.user.idRol == 200) { //Band
      //console.log("es banda");
      this.dataSessionService.navigateByUrl("/dashboard-band/home");
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case 'full':
      case 'iconbar':
        if (this.innerWidth < 1170) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      case 'overlay':
        if (this.innerWidth < 767) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  toggleSidebarType() {
    switch (this.options.sidebartype) {
      case 'full':
      case 'iconbar':
        this.options.sidebartype = 'mini-sidebar';
        break;

      case 'overlay':
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case 'mini-sidebar':
        if (this.defaultSidebar === 'mini-sidebar') {
          this.options.sidebartype = 'full';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }
}
