import { RouteInfo } from './sidebar-final-user.metadata';

export const ADMINMENU: RouteInfo[] = [
    {
        path: '/user-account/home',
        title: 'Mi cuenta',
        icon: 'far fa-user',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/user-account/requests',
        title: 'Solicitudes',
        icon: 'far fa-envelope',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/user-account/favorites',
        title: 'Favoritos',
        icon: 'far fa-heart',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/user-account/reviews',
        title: 'Mis reseñas',
        icon: 'fas fa-star',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/user-account/comments',
        title: 'Comentarios',
        icon: 'far fa-comments',
        class: '',
        extralink: false,
        submenu: []
    },
    /*{
        path: '/apps/email',
        title: 'Email',
        icon: 'icon-Mailbox-Empty',
        class: '',
        extralink: false,
        submenu: []
    }, */
];
