<div class="modal-header">
    <h2 *ngIf="dataSessionService.user.idUser != -1 && actualReview.idProfileReview == -1" class="modal-title"
        id="modal-basic-title">
        <strong>
            <i class="fas fa-star-half-alt pa-r-10"></i>
            Comparte tu experiencia
        </strong>
    </h2>
    <h3 *ngIf="dataSessionService.user.idUser != -1 && actualReview.idProfileReview != -1" class="modal-title"
        id="modal-basic-title">
        <strong>
            <i class="fas fa-star-half-alt pa-r-10"></i>
            Tu experiencia actual en <span class="text-uppercase">{{profileName}}</span>
        </strong>
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="dismissCancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body full-centered">
    <div *ngIf="dataSessionService.user.idUser == -1" class="row full-width full-centered">
        <h2 class="col-12 full-centered pa-b-20 no-margin">
            Para poder calificar
        </h2>
        <div class="col-12 full-centered">
            <i class="fas fa-sign-in-alt icon-login"></i>
        </div>
        <div class="form-group text-center">
            <div class="col-12 p-b-20">
                <!-- <button routerLink="/dashboard/admin/home" class="btn btn-block btn-lg btn-info" type="submit">Iniciar Sesión</button> -->
                <button (click)="openLoginModal(false)" class="btn mr-0 btn-primary hover-shadow max-btn-animate"
                    type="submit">
                    <label class="no-margin">Registrate</label>
                </button>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-12 no-margin full-centered">
                <div class="custom-control no-padding">
                    <h3 id="to-recover" class="text-dark float-center">
                        Ó</h3>
                </div>
            </div>
        </div>
        <div class="form-group text-center">
            <div class="col-12 p-b-20">
                <!-- <button routerLink="/dashboard/admin/home" class="btn btn-block btn-lg btn-info" type="submit">Iniciar Sesión</button> -->
                <button class="btn mr-0 btn-primary hover-shadow max-btn-animate" (click)="openLoginModal(true)">
                    <label class="no-margin">Inicia Sesión</label>
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="dataSessionService.user.idUser != -1 && actualReview.idProfileReview == -1" class="row">
        <div class="col-7 full-left pa-b-20">
            <div class="row">
                <div class="col-12 pa-b-10">
                    <p class="fs-xl full-width" style="text-align: justify;">
                        Cuéntanos como fue tu experiencia de tu evento 
                        <span *ngIf="type == 0">con </span>
                        <span *ngIf="type == 1">en </span>
                        <span class="text-uppercase">{{profileName}}</span> .<span class="red-color">*</span>
                    </p>
                </div>
                <div class="col-12">
                    <textarea [disabled]="resultUploadSuccess == true || showLoadingUpload == true" required
                        [(ngModel)]="actualReview.userCommet" class="full-width"></textarea>
                </div>
            </div>
        </div>
        <div class="col-5 full-right pa-b-20">
            <div class="row card no-margin">
                <div class="col-12 full-centered pa-t-10 pa-b-5">
                    <span style="font-size: 60px;">
                        {{ (( actualReview.qualityOfService + actualReview.responseTime + actualReview.professionalism + 
                            actualReview.priceQualityRatio + actualReview.flexibility + actualReview.communication ) / 6).toFixed(1) }}
                    </span>
                </div>
                <div class="col-12 full-centered">
                    de 5.0
                </div>
                <div class="col-12 full-centered">
                    <ngb-rating class="star-color pointer-hand" [max]="5" style="font-size: 36px;" [readonly]="true"
                        [rate]="(( actualReview.qualityOfService + actualReview.responseTime + actualReview.professionalism + 
                        actualReview.priceQualityRatio + actualReview.flexibility + actualReview.communication ) / 6)">
                    </ngb-rating>
                </div>
            </div>
        </div>
        <div class="col-12 card no-margin">
        </div>

        <div class="col-12 card no-margin">
            <div class="row">
                <div *ngIf="resultUploadSuccess == true || showLoadingUpload == true"
                    class="full-width loading-animation-favorite no-margin full-height" style="height: 100%;">
                    <div class="col-12 full-height full-centered">
                        <div *ngIf="resultUploadSuccess == false && showLoadingUpload == true"
                            class="loader-label-favorite">Loading...</div>
                        <svg *ngIf="resultUploadSuccess == true && showLoadingUpload == false" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                            <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6"
                                stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                            <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6"
                                stroke-linecap="round" stroke-miterlimit="10"
                                points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                        </svg>
                    </div>
                </div>

                <div class="col-12 col-sm-4 full-left label-title">
                    <div class="no-margin">
                        Calidad del servicio
                    </div>
                </div>
                <div class="col-12 col-sm-8">
                    <div class="row full-centered pa-10">
                        <div class="col-6 full-centered">
                            <!-- <ngb-rating class="star-color pointer-hand" [max]="5" style="font-size: 36px;" 
                                [readonly]="true" [rate]="this.actualReview.qualityOfService"></ngb-rating> -->
                            <span class="pa-r-10">
                                0
                            </span>
                            <div class="progress full-width card no-margin" style="height: 13px;">
                                <div class="progress-bar" role="progressbar" [style]="'width: '
                                            +((this.actualReview.qualityOfService*100)/5).toFixed(2)+
                                            '%; height: 13px;background-color: #e8be47 !important;'" aria-valuenow="25"
                                    aria-valuemin="0" aria-valuemax="100">
                                </div>
                            </div>
                            <span class="pa-l-10">
                                5
                            </span>
                        </div>
                        <div class="col-2 full-centered">
                            <button class="btn btn-primary hover-shadow max-btn-animate"
                                (click)="minusQualityOfService()" [disabled]="this.actualReview.qualityOfService <= 0">
                                <strong>
                                    <i class="fas fa-minus fs-xl"></i>
                                </strong>
                            </button>
                        </div>
                        <div class="col-2 full-centered label-title">
                            <strong>
                                {{actualReview.qualityOfService}}.0
                            </strong>
                        </div>
                        <div class="col-2 full-centered">
                            <button class="btn btn-primary hover-shadow max-btn-animate"
                                (click)="plusQualityOfService()" [disabled]="this.actualReview.qualityOfService >= 5">
                                <strong>
                                    <i class="fas fa-plus fs-xl"></i>
                                </strong>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-12 card no-margin">
                </div>
                <div class="col-12 col-sm-4 full-left label-title">
                    <div class="no-margin">
                        Tiempo de respuesta
                    </div>
                </div>
                <div class="col-12 col-sm-8">
                    <div class="row full-centered pa-10">
                        <div class="col-6 full-centered">
                            <!-- <ngb-rating class="star-color pointer-hand" [max]="5" style="font-size: 36px;" 
                                [readonly]="true" [rate]="this.actualReview.qualityOfService"></ngb-rating> -->
                            <span class="pa-r-10">
                                0
                            </span>
                            <div class="progress full-width card no-margin" style="height: 13px;">
                                <div class="progress-bar" role="progressbar" [style]="'width: '
                                            +((this.actualReview.responseTime*100)/5).toFixed(2)+
                                            '%; height: 13px;background-color: #e8be47 !important;'" aria-valuenow="25"
                                    aria-valuemin="0" aria-valuemax="100">
                                </div>
                            </div>
                            <span class="pa-l-10">
                                5
                            </span>
                        </div>
                        <div class="col-2 full-centered">
                            <button class="btn btn-primary hover-shadow max-btn-animate" (click)="minusResponseTime()"
                                [disabled]="this.actualReview.responseTime <= 0">
                                <strong>
                                    <i class="fas fa-minus fs-xl"></i>
                                </strong>
                            </button>
                        </div>
                        <div class="col-2 full-centered label-title">
                            <strong>
                                {{actualReview.responseTime}}.0
                            </strong>
                        </div>
                        <div class="col-2 full-centered">
                            <button class="btn btn-primary hover-shadow max-btn-animate" (click)="plusResponseTime()"
                                [disabled]="this.actualReview.responseTime >= 5">
                                <strong>
                                    <i class="fas fa-plus fs-xl"></i>
                                </strong>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="col-12 card no-margin">
                </div>
                <div class="col-12 col-sm-4 full-left label-title">
                    <div class="no-margin">
                        Profesionalidad
                    </div>
                </div>
                <div class="col-12 col-sm-8">
                    <div class="row full-centered pa-10">
                        <div class="col-6 full-centered">
                            <!-- <ngb-rating class="star-color pointer-hand" [max]="5" style="font-size: 36px;" 
                                [readonly]="true" [rate]="this.actualReview.qualityOfService"></ngb-rating> -->
                            <span class="pa-r-10">
                                0
                            </span>
                            <div class="progress full-width card no-margin" style="height: 13px;">
                                <div class="progress-bar" role="progressbar" [style]="'width: '
                                            +((this.actualReview.professionalism*100)/5).toFixed(2)+
                                            '%; height: 13px;background-color: #e8be47 !important;'" aria-valuenow="25"
                                    aria-valuemin="0" aria-valuemax="100">
                                </div>
                            </div>
                            <span class="pa-l-10">
                                5
                            </span>
                        </div>
                        <div class="col-2 full-centered">
                            <button class="btn btn-primary hover-shadow max-btn-animate"
                                (click)="minusProfessionalism()" [disabled]="this.actualReview.professionalism <= 0">
                                <strong>
                                    <i class="fas fa-minus fs-xl"></i>
                                </strong>
                            </button>
                        </div>
                        <div class="col-2 full-centered label-title">
                            <strong>
                                {{actualReview.professionalism}}.0
                            </strong>
                        </div>
                        <div class="col-2 full-centered">
                            <button class="btn btn-primary hover-shadow max-btn-animate" (click)="plusProfessionalism()"
                                [disabled]="this.actualReview.professionalism >= 5">
                                <strong>
                                    <i class="fas fa-plus fs-xl"></i>
                                </strong>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="col-12 card no-margin">
                </div>
                <div class="col-12 col-sm-4 full-left label-title">
                    <div class="no-margin">
                        Relación calidad/precio
                    </div>
                </div>
                <div class="col-12 col-sm-8">
                    <div class="row full-centered pa-10">
                        <div class="col-6 full-centered">
                            <!-- <ngb-rating class="star-color pointer-hand" [max]="5" style="font-size: 36px;" 
                                [readonly]="true" [rate]="this.actualReview.qualityOfService"></ngb-rating> -->
                            <span class="pa-r-10">
                                0
                            </span>
                            <div class="progress full-width card no-margin" style="height: 13px;">
                                <div class="progress-bar" role="progressbar" [style]="'width: '
                                            +((this.actualReview.priceQualityRatio*100)/5).toFixed(2)+
                                            '%; height: 13px;background-color: #e8be47 !important;'" aria-valuenow="25"
                                    aria-valuemin="0" aria-valuemax="100">
                                </div>
                            </div>
                            <span class="pa-l-10">
                                5
                            </span>
                        </div>
                        <div class="col-2 full-centered">
                            <button class="btn btn-primary hover-shadow max-btn-animate"
                                (click)="minusPriceQualityRatio()"
                                [disabled]="this.actualReview.priceQualityRatio <= 0">
                                <strong>
                                    <i class="fas fa-minus fs-xl"></i>
                                </strong>
                            </button>
                        </div>
                        <div class="col-2 full-centered label-title">
                            <strong>
                                {{actualReview.priceQualityRatio}}.0
                            </strong>
                        </div>
                        <div class="col-2 full-centered">
                            <button class="btn btn-primary hover-shadow max-btn-animate"
                                (click)="plusPriceQualityRatio()" [disabled]="this.actualReview.priceQualityRatio >= 5">
                                <strong>
                                    <i class="fas fa-plus fs-xl"></i>
                                </strong>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="col-12 card no-margin">
                </div>
                <div class="col-12 col-sm-4 full-left label-title">
                    <div class="no-margin">
                        Flexibilidad
                    </div>
                </div>
                <div class="col-12 col-sm-8">
                    <div class="row full-centered pa-10">
                        <div class="col-6 full-centered">
                            <!-- <ngb-rating class="star-color pointer-hand" [max]="5" style="font-size: 36px;" 
                                [readonly]="true" [rate]="this.actualReview.qualityOfService"></ngb-rating> -->
                            <span class="pa-r-10">
                                0
                            </span>
                            <div class="progress full-width card no-margin" style="height: 13px;">
                                <div class="progress-bar" role="progressbar" [style]="'width: '
                                            +((this.actualReview.flexibility*100)/5).toFixed(2)+
                                            '%; height: 13px;background-color: #e8be47 !important;'" aria-valuenow="25"
                                    aria-valuemin="0" aria-valuemax="100">
                                </div>
                            </div>
                            <span class="pa-l-10">
                                5
                            </span>
                        </div>
                        <div class="col-2 full-centered">
                            <button class="btn btn-primary hover-shadow max-btn-animate" (click)="minusFlexibility()"
                                [disabled]="this.actualReview.flexibility <= 0">
                                <strong>
                                    <i class="fas fa-minus fs-xl"></i>
                                </strong>
                            </button>
                        </div>
                        <div class="col-2 full-centered label-title">
                            <strong>
                                {{actualReview.flexibility}}.0
                            </strong>
                        </div>
                        <div class="col-2 full-centered">
                            <button class="btn btn-primary hover-shadow max-btn-animate" (click)="plusFlexibility()"
                                [disabled]="this.actualReview.flexibility >= 5">
                                <strong>
                                    <i class="fas fa-plus fs-xl"></i>
                                </strong>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="col-12 card no-margin">
                </div>
                <div class="col-12 col-sm-4 full-left label-title">
                    <div class="no-margin">
                        Comunicación
                    </div>
                </div>
                <div class="col-12 col-sm-8">
                    <div class="row full-centered pa-10">
                        <div class="col-6 full-centered">
                            <!-- <ngb-rating class="star-color pointer-hand" [max]="5" style="font-size: 36px;" 
                                [readonly]="true" [rate]="this.actualReview.qualityOfService"></ngb-rating> -->
                            <span class="pa-r-10">
                                0
                            </span>
                            <div class="progress full-width card no-margin" style="height: 13px;">
                                <div class="progress-bar" role="progressbar" [style]="'width: '
                                            +((this.actualReview.communication*100)/5).toFixed(2)+
                                            '%; height: 13px;background-color: #e8be47 !important;'" aria-valuenow="25"
                                    aria-valuemin="0" aria-valuemax="100">
                                </div>
                            </div>
                            <span class="pa-l-10">
                                5
                            </span>
                        </div>
                        <div class="col-2 full-centered">
                            <button class="btn btn-primary hover-shadow max-btn-animate" (click)="minusCommunication()"
                                [disabled]="this.actualReview.communication <= 0">
                                <strong>
                                    <i class="fas fa-minus fs-xl"></i>
                                </strong>
                            </button>
                        </div>
                        <div class="col-2 full-centered label-title">
                            <strong>
                                {{actualReview.communication}}.0
                            </strong>
                        </div>
                        <div class="col-2 full-centered">
                            <button class="btn btn-primary hover-shadow max-btn-animate" (click)="plusCommunication()"
                                [disabled]="this.actualReview.communication >= 5">
                                <strong>
                                    <i class="fas fa-plus fs-xl"></i>
                                </strong>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-12 card no-margin">
        </div>
        <div class="col-12 card no-margin">
            <div class="row">
                <div class="col-8 pa-10">
                    <h3>
                        Imágenes de tu evento ({{(selectedFiles.length + actualReview.imagesReview.length)}}/{{limit}})
                    </h3>
                    <h6>
                        Por favor incluye por lo menos una imagen de tu evento <span class="red-color">*</span>
                    </h6>
                </div>
                <div *ngIf="resultUploadSuccess == false && showLoadingUpload == false" class="col-4 full-right pa-10">
                    <!-- <button class="btn btn-primary hover-shadow max-btn-animate fs-l" (click)="plusCommunication()">
                        Agregar <i class="fas fa-plus pa-l-10"></i>
                    </button> -->
                    <input (change)="selectFiles($event)" type="file" multiple accept="image/jpeg"
                        class="custom-file-input pointer-hand" id="inputGroupFile01" />

                    <label class="btn btn-primary hover-shadow max-btn-animate fs-l" for="inputGroupFile01"
                        style="border-radius: 25px;">
                        <!-- <img src="./assets/images/logo-ligth.jpg" class="pa-30"
                            style="    width: 68% !important;"> -->
                        Agregar <i class="fas fa-plus pa-l-10"></i>
                    </label>
                </div>
            </div>
        </div>
        <div class="col-12 no-margin">
            <div class="row">
                <div *ngIf="(selectedFiles.length + actualReview.imagesReview.length) == 0"
                    class="col-12 full-centered pa-20">
                    <h3>
                        <i class="far fa-images pa-r-10"></i> Sin Imágenes
                    </h3>
                </div>

                <div *ngIf="(selectedFiles.length + actualReview.imagesReview.length) > 0" class="col-12 full-centered">
                    <div class="row full-width full-centered pd-10 ">

                        <!-- Actual Image Reviews -->
                        <div class="col-12 col-lg-4 col-md-6 ma-b-10-i full-centered"
                            *ngFor="let actualImageReview of actualReview.imagesReview; let i = index">
                            <div class="row">
                                <div class="col-12 full-centered">
                                    <img [src]="dataSessionService.baseURL + actualImageReview.url"
                                        class="pa-30 normal-shadow slide-image-tm">
                                    <label *ngIf="resultUploadSuccess == true || showLoadingUpload == false"
                                        class="image-index">{{ i + 1 }}</label>

                                    <label (click)="deleteActualSavedSpecificImage(i)"
                                        class="image-delete no-margin max-btn-animate">
                                        <i class="fas fa-trash"></i>
                                    </label>

                                    <div *ngIf="resultUploadSuccess == false && showLoadingUpload == true"
                                        class="row full-width loading-animation-image no-margin full-centered"
                                        style="background: rgb(240 240 240 / 84%);">
                                        <div class="col-12 full-centered">
                                            <div class="loader-slides">Loading...</div>
                                            <i class="fas fa-image icon-image"></i>
                                            <label class="label-image">Actualizando</label>
                                        </div>
                                    </div>
                                    <div *ngIf="resultUploadSuccess == true && showLoadingUpload == false"
                                        class="row full-width loading-animation-image no-margin full-centered"
                                        style="background: rgb(240 240 240 / 84%);">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                            <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6"
                                                stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                            <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6"
                                                stroke-linecap="round" stroke-miterlimit="10"
                                                points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                        </svg>
                                    </div>

                                    <!-- <label (click)="openModalFullGallerySliders(i)" title="Ver en pantalla completa" class="image-index max-btn-animate pointer-hand">
                                        <i class="fas fa-expand"></i>
                                    </label> -->

                                    <div class="hover-verify">
                                        <label *ngIf="actualReview.status == 0" class="icon-image-wait max-btn-animate">
                                            <i title="En espera de aprobación" class="fas fa-clock"
                                                style="z-index: 8;"></i>
                                            <span class="label-verify pa-l-10">En revision</span>
                                        </label>
                                        <label *ngIf="actualReview.status != 0 && actualImageReview.approved == true"
                                            class="icon-image-approved max-btn-animate">
                                            <i title="Aprobada" class="fas fa-check-circle" style="z-index: 8;"></i>
                                            <span class="label-verify pa-l-10">Aprobada</span>
                                        </label>
                                        <label *ngIf="actualReview.status != 0 && actualImageReview.approved == false"
                                            class="icon-image-deny max-btn-animate">
                                            <i title="Imagen rechazada por incumplir las normas"
                                                class="fas fa-times-circle" style="z-index: 8;"></i>
                                            <span class="label-verify pa-l-10">Rechazada</span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12 pa-t-10">
                                    <label class="fs-16">
                                        Subida el {{ utilitiesService.getOnlyDate( actualImageReview.createdAt ) }}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <!-- New images -->
                        <div class="col-12 col-lg-4 col-md-6 ma-b-10-i full-centered"
                            *ngFor="let newSlide of filesSourceToUpload; let i = index">
                            <div class="row">
                                <div class="col-12 full-centered">
                                    <img [src]="newSlide" class="pa-30 normal-shadow slide-image-tm">
                                    <label *ngIf="resultUploadSuccess == true || showLoadingUpload == false"
                                        class="image-index">
                                        {{ actualReview.imagesReview.length + i + 1 }}
                                    </label>

                                    <label (click)="deleteSpecificImage(i)"
                                        class="image-delete no-margin max-btn-animate">
                                        <i class="fas fa-trash"></i>
                                    </label>

                                    <div *ngIf="messageErrors[i].loading == true || messageErrors[i].error == true"
                                        class="row full-width loading-animation-image no-margin full-centered"
                                        style="background: rgb(240 240 240 / 84%);">
                                        <div class="col-12 full-centered">
                                            <div class="loader-slides">Loading...</div>
                                            <i class="fas fa-image icon-image"></i>
                                            <label class="label-image">Enviando imágenes</label>
                                        </div>
                                        <div class="col-12 full-centered">
                                            <div class="progress" style="width: 90%;">
                                                <div class="progress-bar progress-bar-info progress-bar-striped"
                                                    role="progressbar" attr.aria-valuenow="{{ progressInfos[i].value }}"
                                                    aria-valuemin="0" aria-valuemax="100"
                                                    [ngStyle]="{ width: progressInfos[i].value + '%' }">
                                                    {{ progressInfos[i].value }}%
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="messageErrors[i].loading == false && messageErrors[i].message.length"
                                        class="row full-width loading-animation-image no-margin full-centered"
                                        style="background: rgb(240 240 240 / 84%);">
                                        <div class="col-12 full-centered">
                                            <svg *ngIf="messageErrors[i].error == false" version="1.1"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                                <circle class="path circle" fill="none" stroke="#73AF55"
                                                    stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1"
                                                    r="62.1" />
                                                <polyline class="path check" fill="none" stroke="#73AF55"
                                                    stroke-width="6" stroke-linecap="round" stroke-miterlimit="10"
                                                    points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                            </svg>

                                            <svg *ngIf="messageErrors[i].error == true" version="1.1"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                                <circle class="path circle" fill="none" stroke="#D06079"
                                                    stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1"
                                                    r="62.1" />
                                                <line class="path line" fill="none" stroke="#D06079" stroke-width="6"
                                                    stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9"
                                                    x2="95.8" y2="92.3" />
                                                <line class="path line" fill="none" stroke="#D06079" stroke-width="6"
                                                    stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38"
                                                    x2="34.4" y2="92.2" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 pa-t-10">
                                    <label class="fs-16">{{ progressInfos[i].fileName.substring(0,20) }}...</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="dataSessionService.user.idUser != -1 && actualReview.idProfileReview != -1" class="row">
        <div class="col-6">
            <label class="full-width ">Comentario</label>
        </div>
        <div class="col-6">
            <label
                class="full-width full-right">{{ utilitiesService.formatDateDayName2( actualReview.createdAt ) }}</label>
        </div>
        <div class="col-12 pa-10 full-width ">
            <div class="card no-margin pa-10">
                <h3 class="full-width ">{{ utilitiesService.capitalizeFirstLetter(actualReview.userCommet) }}</h3>
            </div>
        </div>
        <div class="col-12 col-sm-9 full-left pa-b-20">
            <div class="row full-width">
                <div class="col-12 col-lg-4 full-left">
                    <h4 class="no-margin">
                        Calidad del servicio
                    </h4>
                </div>
                <div class="col-12 full-centered col-lg-8">
                    <div class="row full-width full-centered pa-10">
                        <div class="col-12 col-sm-10 full-centered">
                            <span class="pa-r-10">
                                0
                            </span>
                            <div class="progress full-width card no-margin" style="height: 13px;">
                                <div class="progress-bar" role="progressbar" [style]="'width: '
                                            +((this.actualReview.qualityOfService*100)/5).toFixed(2)+
                                            '%; height: 13px;background-color: #e8be47 !important;'" aria-valuenow="25"
                                    aria-valuemin="0" aria-valuemax="100">
                                </div>
                            </div>
                            <span class="pa-l-10">
                                5
                            </span>
                        </div>
                        <div class="col-12 col-sm-2 full-centered label-title no-padding">
                            <strong>
                                {{actualReview.qualityOfService}}.0
                            </strong>
                        </div>
                    </div>
                </div>
                <div class="col-12 card no-margin">
                </div>
                <div class="col-12 col-lg-4 full-left">
                    <h4 class="no-margin">
                        Tiempo de respuesta
                    </h4>
                </div>
                <div class="col-12 full-centered col-lg-8">
                    <div class="row full-width full-centered pa-10">
                        <div class="col-12 col-sm-10 full-centered">
                            <span class="pa-r-10">
                                0
                            </span>
                            <div class="progress full-width card no-margin" style="height: 13px;">
                                <div class="progress-bar" role="progressbar" [style]="'width: '
                                            +((this.actualReview.responseTime*100)/5).toFixed(2)+
                                            '%; height: 13px;background-color: #e8be47 !important;'" aria-valuenow="25"
                                    aria-valuemin="0" aria-valuemax="100">
                                </div>
                            </div>
                            <span class="pa-l-10">
                                5
                            </span>
                        </div>
                        <div class="col-12 col-sm-2 full-centered label-title no-padding">
                            <strong>
                                {{actualReview.responseTime}}.0
                            </strong>
                        </div>
                    </div>
                </div>
                <div class="col-12 card no-margin">
                </div>
                <div class="col-12 col-lg-4 full-left">
                    <h4 class="no-margin">
                        Profesionalidad
                    </h4>
                </div>
                <div class="col-12 full-centered col-lg-8">
                    <div class="row full-width full-centered pa-10">
                        <div class="col-12 col-sm-10 full-centered">
                            <span class="pa-r-10">
                                0
                            </span>
                            <div class="progress full-width card no-margin" style="height: 13px;">
                                <div class="progress-bar" role="progressbar" [style]="'width: '
                                            +((this.actualReview.professionalism*100)/5).toFixed(2)+
                                            '%; height: 13px;background-color: #e8be47 !important;'" aria-valuenow="25"
                                    aria-valuemin="0" aria-valuemax="100">
                                </div>
                            </div>
                            <span class="pa-l-10">
                                5
                            </span>
                        </div>
                        <div class="col-12 col-sm-2 full-centered label-title no-padding">
                            <strong>
                                {{actualReview.professionalism}}.0
                            </strong>
                        </div>
                    </div>
                </div>
                <div class="col-12 card no-margin">
                </div>
                <div class="col-12 col-lg-4 full-left">
                    <h4 class="no-margin">
                        Relación calidad/precio
                    </h4>
                </div>
                <div class="col-12 full-centered col-lg-8">
                    <div class="row full-width full-centered pa-10">
                        <div class="col-12 col-sm-10 full-centered">
                            <span class="pa-r-10">
                                0
                            </span>
                            <div class="progress full-width card no-margin" style="height: 13px;">
                                <div class="progress-bar" role="progressbar" [style]="'width: '
                                            +((this.actualReview.priceQualityRatio*100)/5).toFixed(2)+
                                            '%; height: 13px;background-color: #e8be47 !important;'" aria-valuenow="25"
                                    aria-valuemin="0" aria-valuemax="100">
                                </div>
                            </div>
                            <span class="pa-l-10">
                                5
                            </span>
                        </div>
                        <div class="col-12 col-sm-2 full-centered label-title no-padding">
                            <strong>
                                {{actualReview.priceQualityRatio}}.0
                            </strong>
                        </div>
                    </div>
                </div>
                <div class="col-12 card no-margin">
                </div>
                <div class="col-12 col-lg-4 full-left">
                    <h4 class="no-margin">
                        Flexibilidad
                    </h4>
                </div>
                <div class="col-12 full-centered col-lg-8">
                    <div class="row full-width full-centered pa-10">
                        <div class="col-12 col-sm-10 full-centered">
                            <span class="pa-r-10">
                                0
                            </span>
                            <div class="progress full-width card no-margin" style="height: 13px;">
                                <div class="progress-bar" role="progressbar" [style]="'width: '
                                            +((this.actualReview.flexibility*100)/5).toFixed(2)+
                                            '%; height: 13px;background-color: #e8be47 !important;'" aria-valuenow="25"
                                    aria-valuemin="0" aria-valuemax="100">
                                </div>
                            </div>
                            <span class="pa-l-10">
                                5
                            </span>
                        </div>
                        <div class="col-12 col-sm-2 full-centered label-title no-padding">
                            <strong>
                                {{actualReview.flexibility}}.0
                            </strong>
                        </div>
                    </div>
                </div>
                <div class="col-12 card no-margin">
                </div>
                <div class="col-12 col-lg-4 full-left">
                    <h4 class="no-margin">
                        Comunicación
                    </h4>
                </div>
                <div class="col-12 full-centered col-lg-8">
                    <div class="row full-width full-centered pa-10">
                        <div class="col-12 col-sm-10 full-centered">
                            <span class="pa-r-10">
                                0
                            </span>
                            <div class="progress full-width card no-margin" style="height: 13px;">
                                <div class="progress-bar" role="progressbar" [style]="'width: '
                                            +((this.actualReview.communication*100)/5).toFixed(2)+
                                            '%; height: 13px;background-color: #e8be47 !important;'" aria-valuenow="25"
                                    aria-valuemin="0" aria-valuemax="100">
                                </div>
                            </div>
                            <span class="pa-l-10">
                                5
                            </span>
                        </div>
                        <div class="col-12 col-sm-2 full-centered label-title no-padding">
                            <strong>
                                {{actualReview.communication}}.0
                            </strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-3 full-centered pa-b-20">
            <div class="row">
                <div class="col-12 full-centered pa-10">
                    <div class="row full-width">
                        <div class="col-12 full-centered pa-10">
                            <label *ngIf="actualReview.status == 0" style="position: initial;"
                                class="icon-image-wait max-btn-animate">
                                <i title="En espera de aprobación" class="fas fa-clock" style="z-index: 8;"></i>
                                <span class="label-verify pa-l-10">En revision</span>
                            </label>

                            <button *ngIf="actualReview.status != 0"
                                (click)="imagesToDelete = [];actualReview.idProfileReview = -1;"
                                style="position: initial;font-size: x-large;" class="btn btn-primary max-btn-animate">
                                <i title="Aprobada" class="fas fa-edit" style="z-index: 8;"></i>
                                <span class="label-verify pa-l-10">Editar</span>
                            </button>
                        </div>
                        <div class="col-12 full-centered pa-10" *ngIf="actualReview.status != 0">
                            <label *ngIf="actualReview.status == 1" (click)="actualReview.idProfileReview = -1"
                                style="position: initial;" class="">
                                <i title="Aprobada" class="fas fa-check-circle green-color" style="z-index: 8;"></i>
                                <span class="label-verify pa-l-10">Aprobado</span>
                            </label>
                            <label *ngIf="actualReview.status == 2" style="position: initial;" class="">
                                <i title="Reseña rechazada por incumplir las normas"
                                    class="fas fa-times-circle red-color" style="z-index: 8;"></i>
                                <span class="label-verify pa-l-10">Rechazada</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row card no-margin">
                        <div class="col-12 full-centered pa-t-10 pa-b-5">
                            <span style="font-size: 60px;">
                                {{ (( actualReview.qualityOfService + actualReview.responseTime + actualReview.professionalism + 
                                    actualReview.priceQualityRatio + actualReview.flexibility + actualReview.communication ) / 6).toFixed(1) }}
                            </span>
                        </div>
                        <div class="col-12 full-centered">
                            de 5.0
                        </div>
                        <div class="col-12 full-centered">
                            <ngb-rating class="star-color pointer-hand" [max]="5" style="font-size: 36px;"
                                [readonly]="true"
                                [rate]="(( actualReview.qualityOfService + actualReview.responseTime + actualReview.professionalism + 
                                actualReview.priceQualityRatio + actualReview.flexibility + actualReview.communication ) / 6)">
                            </ngb-rating>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 card no-margin">
            <div class="row">
                <div class="col-12 pa-10">
                    <h3>
                        Imágenes de tu evento ({{actualReview.imagesReview.length}}/{{limit}})
                    </h3>
                    <!-- <h6>
                        Por favor incluye por lo menos una imagen de tu evento <span class="red-color">*</span>
                    </h6> -->
                </div>
            </div>
        </div>
        <div class="col-12 no-margin">
            <div class="row">
                <div *ngIf="actualReview.imagesReview.length == 0" class="col-12 full-centered pa-20">
                    <h3>
                        <i class="far fa-images pa-r-10"></i> Sin Imágenes
                    </h3>
                </div>

                <div *ngIf="actualReview.imagesReview.length > 0" class="col-12 full-centered">
                    <div class="row full-width full-centered pd-10 ">
                        <div class="col-12 col-lg-4 col-md-6 ma-b-10-i full-centered"
                            *ngFor="let actualImageReview of actualReview.imagesReview; let i = index">
                            <div class="row">
                                <!-- <div class="col-2 full-centered">
                            <label>{{i}}</label>
                        </div> -->
                                <div class="col-12 full-centered">
                                    <img [src]="dataSessionService.baseURL +  actualImageReview.url"
                                        class="pa-30 normal-shadow slide-image-tm">
                                    <label (click)="openModalFullGallerySliders(i)" title="Ver en pantalla completa"
                                        class="image-index max-btn-animate pointer-hand">
                                        <i class="fas fa-expand"></i>
                                    </label>

                                    <div class="hover-verify">
                                        <label *ngIf="actualReview.status == 0" class="icon-image-wait max-btn-animate">
                                            <i title="En espera de aprobación" class="fas fa-clock"
                                                style="z-index: 8;"></i>
                                            <span class="label-verify pa-l-10">En revision</span>
                                        </label>
                                        <label *ngIf="actualReview.status != 0 && actualImageReview.approved == true"
                                            class="icon-image-approved max-btn-animate">
                                            <i title="Aprobada" class="fas fa-check-circle" style="z-index: 8;"></i>
                                            <span class="label-verify pa-l-10">Aprobada</span>
                                        </label>
                                        <label *ngIf="actualReview.status != 0 && actualImageReview.approved == false"
                                            class="icon-image-deny max-btn-animate">
                                            <i title="Imagen rechazada por incumplir las normas"
                                                class="fas fa-times-circle" style="z-index: 8;"></i>
                                            <span class="label-verify pa-l-10">Rechazada</span>
                                        </label>
                                    </div>
                                    <!-- <label (click)="deleteSpecificImage(i)"
                                        class="image-delete no-margin max-btn-animate">
                                        <i class="fas fa-trash"></i>
                                    </label> -->
                                </div>
                                <div class="col-12 pa-t-10">
                                    <label class="fs-16 full-width">
                                        Subida el {{ utilitiesService.getOnlyDate(actualImageReview.createdAt) }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer" *ngIf="dataSessionService.user.idUser == -1" (click)="dismissCancel()">
    <button class="btn full-width mr-0 btn-danger hover-shadow max-btn-animate" type="button">
        <div class="row full-centered font-normal ">
            <i class="fas fa-times-circle pa-r-10"></i> Aceptar
        </div>
    </button>
</div>

<div class="modal-footer"
    *ngIf="resultUploadSuccess == false && dataSessionService.user.idUser != -1 && actualReview.idProfileReview == -1">
    <button (click)="dismissCancel();" class="btn mr-0 btn-danger button-reset  normal-shadow max-btn-animate"
        type="button">
        <div class="row full-centered font-normal">
            <i class="fas fa-window-close pa-r-10"></i> Cancelar
        </div>
    </button>
    <button *ngIf="actualReview.status == 0"
        class="btn mr-0 btn-success button-reset color-white normal-shadow max-btn-animate" type="button" [disabled]="(selectedFiles.length + actualReview.imagesReview.length) == 0 || actualReview.userCommet.length < 1 || 
                    resultUploadSuccess == true || showLoadingUpload == true" (click)="uploadNewReviewAndFiles()">
        <div class="row full-centered font-normal pa-r-10">
            <i class="fas fa-share-square pa-r-10"></i> Compartir
            <!-- {{selectedFiles.length}} -->
        </div>
    </button>
    <button *ngIf="actualReview.status != 0"
        class="btn mr-0 btn-success button-reset color-white normal-shadow max-btn-animate" type="button" [disabled]="(selectedFiles.length + actualReview.imagesReview.length) == 0 || actualReview.userCommet.length < 1 || 
                    resultUploadSuccess == true || showLoadingUpload == true" (click)="uploadUpdatedReviewAndFiles()">
        <div class="row full-centered font-normal pa-r-10">
            <i class="fas fa-save pa-r-10"></i> Actualizar 
        </div>
    </button>
    <!-- <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button> -->
</div>

<div class="modal-footer"
    *ngIf="resultUploadSuccess == true && dataSessionService.user.idUser != -1 && actualReview.idProfileReview == -1">
    <button class="btn mr-0 btn-success button-reset color-white normal-shadow max-btn-animate" type="button"
        (click)="ngOnInit()">
        <div class="row full-centered font-normal pa-r-10">
            <i class="fas fa-check-circle pa-r-10"></i> Aceptar
        </div>
    </button>
    <!-- <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button> -->
</div>

<div class="modal-footer" *ngIf="dataSessionService.user.idUser != -1 && actualReview.idProfileReview != -1">
    <button class="btn mr-0 btn-success button-reset color-white normal-shadow max-btn-animate" type="button"
        (click)="dismissCancel()">
        <div class="row full-centered font-normal pa-r-10">
            <i class="fas fa-check-circle pa-r-10"></i> Aceptar
        </div>
    </button>
    <!-- <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button> -->
</div>

<ng-template #modalSeeFullSlider let-modal>
    <div class="modal-header white-color">
        <div class="row full-width">
            <div class="col-2">
                <h4 class="modal-title" id="modal-basic-title">
                    <i class="far fa-image fs-xxl pa-l-10"></i>
                </h4>
            </div>
            <div class="col-8 full-centered">
                {{parseInt(active.substr(0,active.length - 5)) + 1}} / {{actualReview.imagesReview.length}}
            </div>
            <div class="col-2 pa-r-10 ">
                <button type="button" class="close fs-xxl pa-r-10  white-color" data-aria-label="Close"
                    (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <div class="row row-images">
            <ngb-carousel [interval]="10000" (slide)="test($event)" [(activeId)]="active" #carouselImages
                class="full-width">
                <ng-template ngbSlide *ngFor="let image of actualReview.imagesReview;  index as i" class="full-centered"
                    [id]="i+'-full'">
                    <img [src]="dataSessionService.baseURL +  image.url">
                    <!-- <div class="carousel-caption">
                        <h3>First slide label</h3>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </div> -->
                </ng-template>
            </ngb-carousel>
        </div>
    </div>
</ng-template>