import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar';
import { DataSessionService } from './services/dataSession/data-session.service';
import { ApiLiveChatDataService } from './services/liveChat/api-live-chat-data.service';
import { LoggedResponse } from './classes/loggedResponse.class';
import { UtilitiesService } from './services/utilities/utilities.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy, OnInit {
    private sub: any;

    constructor(private slimLoader: SlimLoadingBarService, private router: Router,private utilitiesService: UtilitiesService,
        private dataSessionService: DataSessionService, private apiLiveChatDataService: ApiLiveChatDataService) {
        // Listen the navigation events to start or complete the slim bar loading
        this.sub = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.slimLoader.start();
            } else if (event instanceof NavigationEnd ||
                event instanceof NavigationCancel ||
                event instanceof NavigationError) {
                this.slimLoader.complete();
            }
        }, (error: any) => {
            this.slimLoader.complete();
        });
    }

    ngOnDestroy(): any {
        this.sub.unsubscribe();
    }

    ngOnInit(): void {
        this.dataSessionService.checkLogin((logedResponse: LoggedResponse) => {
            /* Init chat data */
            if (this.apiLiveChatDataService.isOnline == false) {
                this.apiLiveChatDataService.connect(async () => {
                    while (this.apiLiveChatDataService.isOnline == false) {
                        await this.utilitiesService.sleep(500);
                    }
                    /* nuevos mensajes */
                    this.apiLiveChatDataService.suscribeToChats((dataToUpdate: { idChat: number }) => {
                        /* if( dataToUpdate.idChat == this.actualChatSelected.idChat ){
                          this.getChatMessagesEventHallData(this.actualChatSelected);
                        }else{
                          this.getChatListEventHallViewData();
                        } */
                        this.dataSessionService.getActiveRequests();
                    });
                });
            }
        }, (noLoginResponse: LoggedResponse) => {
            //console.log(noLoginResponse);

        });
    }
}
