import { Component, AfterViewInit, OnInit, ChangeDetectorRef } from '@angular/core';
import { DataSessionService } from '../../../services/dataSession/data-session.service';

//MENUS
import { EVENTHALLMENU , BANDMENU } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { UtilitiesService } from '../../../services/utilities/utilities.service';

declare var $: any;

@Component({
  selector: 'app-sidebar-service-profile',
  templateUrl: './sidebar-service-profile.component.html',
  styleUrls: ['./sidebar-service-profile.component.scss']
})
export class SidebarServiceProfileComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  /* public sidebarNavItems: RouteInfo[]; */

  constructor(
    public dataSessionService: DataSessionService,private utilitiesService : UtilitiesService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  
  async ngOnInit() {
    this.dataSessionService.reloadMenu();
  }

  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  // End open close

  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }
}
