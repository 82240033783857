import { RouteInfo } from './sidebar.metadata';

export const EVENTHALLMENU: RouteInfo[] = [
    {
        path: '/dashboard-event-hall/home',
        title: 'Inicio',
        icon: 'fas fa-home',
        class: '',
        extralink: false,
        submenu: [],
        showAlert : false,
    }/* ,{
        path: '/dashboard-event-hall/test',
        title: 'Pre visualizar perfil',
        icon: 'fas fa-eye',
        class: '',
        extralink: false,
        submenu: []
    } */,
    {
        path: '/dashboard-event-hall/payments',
        title: 'Pagos',
        icon: 'fas fa-money-check-alt',
        class: '',
        extralink: false,
        submenu: [],
        showAlert : false,
    },
    {
        path: '/dashboard-event-hall/information',
        title: 'Información',
        icon: 'fas fa-info-circle',
        class: '',
        extralink: false,
        submenu: [],
        showAlert : false,
    },
    {
        path: '/dashboard-event-hall/schedule',
        title: 'Agenda',
        icon: 'fas fa-calendar-week',
        class: '',
        extralink: false,
        submenu: [],
        showAlert : false,
    },
    {
        path: '/dashboard-event-hall/messages',
        title: 'Mensajes',
        icon: 'fas fa-comments',
        class: '',
        extralink: false,
        submenu: [],
        showAlert : false,
    },
    {
        path: '/dashboard-event-hall/gallery',
        title: 'Galería',
        icon: 'fas fa-images',
        class: '',
        extralink: false,
        submenu: [],
        showAlert : false,
    },
    {
        path: '/dashboard-event-hall/place-facilities',
        title: 'Instalaciones',
        icon: 'fas fa-home',
        class: '',
        extralink: false,
        submenu: [],
        showAlert : false,
    },
    {
        path: '/dashboard-event-hall/packages',
        title: 'Paquetes',
        icon: 'fas fa-boxes',
        class: '',
        extralink: false,
        submenu: [],
        showAlert : false,
    },
    {
        path: '/dashboard-event-hall/account-settings',
        title: 'Ajustes de la cuenta',
        icon: 'fas fa-cogs',
        class: '',
        extralink: false,
        submenu: [],
        showAlert : false,
    },
    /*     {
        path: '/apps/email',
        title: 'Email',
        icon: 'icon-Mailbox-Empty',
        class: '',
        extralink: false,
        submenu: []
    }, */
];

export const BANDMENU: RouteInfo[] = [
    {
        path: '/dashboard-band/home',
        title: 'Inicio',
        icon: 'fas fa-home',
        class: '',
        extralink: false,
        submenu: [],
        showAlert : false,
    }/* ,{
        path: '/dashboard-band/test',
        title: 'Pre visualizar perfil',
        icon: 'fas fa-eye',
        class: '',
        extralink: false,
        submenu: []
    } */,
    {
        path: '/dashboard-band/payments',
        title: 'Pagos',
        icon: 'fas fa-money-check-alt',
        class: '',
        extralink: false,
        submenu: [],
        showAlert : false,
    },
    {
        path: '/dashboard-band/information',
        title: 'Información',
        icon: 'fas fa-info-circle',
        class: '',
        extralink: false,
        submenu: [],
        showAlert : false,
    },
    {
        path: '/dashboard-band/schedule',
        title: 'Agenda',
        icon: 'fas fa-calendar-week',
        class: '',
        extralink: false,
        submenu: [],
        showAlert : false,
    },
    {
        path: '/dashboard-band/messages',
        title: 'Mensajes',
        icon: 'fas fa-comments',
        class: '',
        extralink: false,
        submenu: [],
        showAlert : false,
    },
    {
        path: '/dashboard-band/gallery',
        title: 'Galería',
        icon: 'fas fa-images',
        class: '',
        extralink: false,
        submenu: [],
        showAlert : false,
    },
    {
        path: '/dashboard-band/videos',
        title: 'Videos',
        icon: 'fab fa-youtube',
        class: '',
        extralink: false,
        submenu: [],
        showAlert : false,
    },
    {
        path: '/dashboard-band/packages',
        title: 'Paquetes',
        icon: 'fas fa-boxes',
        class: '',
        extralink: false,
        submenu: [],
        showAlert : false,
    },
    {
        path: '/dashboard-band/account-settings',
        title: 'Ajustes de la cuenta',
        icon: 'fas fa-cogs',
        class: '',
        extralink: false,
        submenu: [],
        showAlert : false,
    },
    /*{
        path: '/apps/email',
        title: 'Email',
        icon: 'icon-Mailbox-Empty',
        class: '',
        extralink: false,
        submenu: []
    }, */
];
