import { BannerDto } from "./banners.class";

export class State {
    idState: number;
    name: string;
    towns: Town[];

    constructor() {
        this.idState = -1;
        this.name = "";
        this.towns = [];
    }
}

export class Town {
    idTown: number;
    idState: number;
    name: string;

    state : State;

    constructor() {
        this.idTown = -1;
        this.idState = -1;
        this.name = "";

        this.state = new State();
    }
}

export class StateDtoList {
    idState: number;
    name: string;
    noBands : number;
    noEventHall : number;
    towns: Town[];

    constructor() {
        this.idState = -1;
        this.name = "";
        this.towns = [];
    }
}

export class StateBannerDtoList {
    idState: number;
    name: string;
    noBands : number;
    noEventHall : number;
    noBannersUploaded : number;
    adsBanners: BannerDto[];

    constructor() {
        this.idState = -1;
        this.name = "";
        this.noBands = 0;
        this.noEventHall = 0;
        this.noBannersUploaded = 0;
        this.adsBanners = [];
    }
}