<div class="footer">
    <div class="responsive-width">
        <div class="one">
            <p class="footer-txt"><strong>Busca <!-- lugares y m -->músicos para todo tipo de evento, en todo México.</strong></p>
            <img src="/assets/images/personajes-icon.png">
            
        </div>
        <div class="two">
            <ul class="features-box">
                <p class="footer-txt"><strong>Nosotros</strong></p>
                <li><a class="info footer-normal-txt pa-t-10" routerLink="/about-us">Nosotros</a></li>
                <li><a class="info footer-normal-txt pa-t-10" routerLink="/terms-conditions">Términos y Condiciones</a></li>
                <li><a class="info footer-normal-txt pa-t-10" routerLink="/privacy-notice">Aviso de privacidad</a></li>
            </ul>
            <ul class="features-box">
                <p class="footer-txt"><strong>Afiliados</strong></p>
                <li><a class="footer-normal-txt pa-t-10" routerLink="/preregister">Registrar Mi Negocio</a></li>
                <li *ngIf="dataSessionService.user.idUser != -1">
                    <a class="footer-normal-txt pa-t-10" routerLink="/user-account/home">Mi cuenta</a></li>
                <li *ngIf="dataSessionService.user.idUser == -1">
                    <a class="footer-normal-txt pa-t-10 pointer-hand" (click)="openLoginModal(true)">Iniciar Sesión</a></li>
            </ul>
        </div>
        <div class="line"></div>
        <div class="three">
            <ul>
                <li><p class="footer-txt"><strong>Contáctanos</strong></p></li>
                <li><a class="footer-normal-txt pa-t-10" href="mailto:contacto@enbibo.com">contacto@enbibo.com</a></li>
                <!-- <li><p class="footer-normal-txt pa-t-10">Llámanos o envíanos un WhatsApp al 614 397 45 40</p></li> -->
                <li><p class="footer-normal-txt pa-t-10">Síguenos en 
                        <a class="social" href="https://www.facebook.com/enbibo-1394613807476137/" target="_blank">
                            <i class="fab fa-facebook-f  max-btn-animate"></i></a>
                        <a class="social" href="https://www.instagram.com/enbibo/"  target="_blank">
                            <i class="fab fa-instagram  max-btn-animate" target="_blank"></i></a>
                        <a class="social" href="https://twitter.com/enbiboCom" target="_blank">
                            <i class="fab fa-twitter  max-btn-animate"></i></a>
                        <a class="social" href="https://www.youtube.com/channel/UCJ0WlAWm1DsVyTxLIFAlOMQ/videos" target="_blank">
                            <i class="fab fa-youtube  max-btn-animate"></i></a>
                    </p>
                </li>
                <li class="pa-t-30">
                    <img class="app-img" src="/assets/images/logo-app.png">
                    <!-- <a href="https://play.google.com/store/apps/details?id=com.enbibo.clientes" target="_blank">
                        <img class="app-img  max-btn-animate" src="/assets/images/app-store.png"></a> -->
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="small-footer reading-footer">
    <div class="responsive-width">
        <p class="pa-t-10 pa-b-10">2022 enbibo. Todos los derechos reservados</p>
    </div>
</div>