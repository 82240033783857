import { SafeUrl } from '@angular/platform-browser';
import { UtilitiesService } from './../utilities/utilities.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User, EventHallFavoritesDtoNav } from '../../classes/user.class';
import { ApiDataService } from '../apiData/api-data.service';
import { ServerMessage } from '../../classes/serverMessage.class';
import { LoggedResponse } from '../../classes/loggedResponse.class';
import { SearchQuery } from '../../classes/searchQuerys.class';
import { SocialAuthService, SocialUser } from "angularx-social-login";
import { ChatListMessageDto } from '../../classes/chatMessagesDto.class';
import { RouteInfo } from '../../components/service-profile/sidebar-service-profile/sidebar.metadata';
import { Howl } from 'howler';

//MENUS
import { EVENTHALLMENU , BANDMENU } from './../../components/service-profile/sidebar-service-profile/menu-items';

@Injectable({
  providedIn: 'root'
})
export class DataSessionService {
  baseURL: string;
  token: string;

  queryParams: SearchQuery;
  
  alreadyLoged: Boolean = false;

  user: User;
  userServiceProfileFavorites : EventHallFavoritesDtoNav[];
  favoritesReady : boolean = false;
  /* Final user chats */
  userOpenenedRequests : ChatListMessageDto[];
  noUnreadRequest : number;
  requestReady : boolean = false;
  /* Profile service chats */
  profileOpenenedRequests : ChatListMessageDto[];
  noUnreadMessages : number;
  requestProfileReady : boolean = false;

  /* Profile Status Flags */
  profileActivated: boolean;
  haveCards : boolean;
  profileCreated : boolean;
  pausedProfile : boolean;
  profileLogo : string;
  imageLogoFileImageBlob : SafeUrl;
  
  loginAlreadyFirst : boolean = false;

  constructor(private apiDataService: ApiDataService, private route: Router, private utilitiesService: UtilitiesService,
    public authService: SocialAuthService,) {
    this.token = "";
    this.user = new User();
    this.sidebarNavItems = [];
    this.userServiceProfileFavorites = [];
    this.userOpenenedRequests = [];
    this.noUnreadRequest = 0;
    this.profileOpenenedRequests = [];
    this.noUnreadMessages = 0;
    this.baseURL = apiDataService.baseURL;
    //this.profileActivated = false;
    this.haveCards = false;
    this.profileCreated = false;
    this.pausedProfile = false;
    this.profileLogo = "";
    this.queryParams = new SearchQuery();
    //this.logOut();
    //localStorage.setItem('token', JSON.stringify(this.token));
    let token = localStorage.getItem('token');
    if (!token) {
      console.log("Primer uso");
      localStorage.setItem('token', JSON.stringify(this.token));
    } else {
      this.token = JSON.parse(token);
      this.apiDataService.setToken(this.token);
      //Acciones a realizar cuando el token estaba ya guardado pero la data para la interfaz no esta disponible
      //Se sabe que no esta disponible porque apenas se mando llamar el contructor
      if (this.token.length > 0) {
        this.apiDataService.getUserData(this.token).then((response: ServerMessage) => {
          //console.log(response);
          if (response.error == true) {
            console.log(response);
            this.utilitiesService.showErrorToast("A ocurrido un error", "Error");
          } else {
            this.setUserData(response.data.user);

            //TO DO - Crear la logica de las rutas de cada usuario
            /* if (this.user.userType != 0 && this.user.userType != 1 && this.user.userType != 2) {
              this.utilitiesService.showErrorToast( "Usuario desconocido.","Error!");
              this.logOut();
            } else if (this.user.userType == 0 ) {
              console.log("es admin");
              this.getAdminMenu();
            } else if (this.user.userType == 1 ) {
              console.log("es provedor");

            }else if (this.user.userType == 2) {
              this.utilitiesService.showInfoToast("Aun no se cuenta con este servicio.");
              this.logOut();
            } */
            /* console.log(this.user); */
          }
        }, (error) => {
          this.user = new User();
          localStorage.setItem('token', "");
          this.token = localStorage.getItem('token');
          //console.log(error);
        });

      };
    }
  }

  navigateByUrl(url: String) {
    this.route.navigateByUrl(url.toString());
  }

  checkLogin(successCallBack, errorCallBack) {
    //console.log(this.token);
    if (this.token == "") {
      errorCallBack(new LoggedResponse(true, "Sin token"))
    } else {
      this.apiDataService.setToken(this.token);
      //console.log(this.user);
      if (this.user.email == "") {
        this.apiDataService.getUserData(this.token).then((response: ServerMessage) => {
          //console.log(response);
          if (response.error == true) {
            this.navigateByUrl("/login");
            errorCallBack(new LoggedResponse(false, response.message))
          } else {
            //Obtain user sesión data
            this.setUserData(response.data.user);
            
            //this.getUserImage();

            /* if( this.queryParams.idTown == -1 && this.queryParams.idState == -1){
              this.queryParams.location = response.data.user.town.name +", "+ response.data.user.town.state.name;
            }
            if( this.queryParams.idTown == -1 ){
              this.queryParams.idTown = response.data.user.idTown;
            }
            if( this.queryParams.idState == -1 ){
              this.queryParams.idState = response.data.user.idState;
            } */
            
            successCallBack(new LoggedResponse(false, "Con token y usuario actualizado"));
            //this.getFavorites();
            //console.log(this.user);
          }
        }, (error) => {
          console.log(error);
          errorCallBack(new LoggedResponse(true, "A ocurrido un error"));
        });
      } else {
        //this.getFavorites();
        successCallBack(new LoggedResponse(false, "Sesión Con token e información de usuario"));
      }
    }
  }

  loginUser(username: String, password: String) : Promise<ServerMessage>{
    return new Promise((resolve, reject) => {
      this.apiDataService.doLogin(username, password).then((response: ServerMessage) => {
        if (response.error) {
          resolve(response)
        } else {
          //Lógica con la que guardamos los datos del inicio de sesión
          localStorage.setItem('token', JSON.stringify(response.data.token));
          this.token = response.data.token;
          this.apiDataService.setToken(this.token);
          resolve(response);
        }
      }, (error) => {
        reject(error)
      });
    });
  }

  logOut(navigate : boolean = true) {
    this.user = new User();
    this.queryParams = new SearchQuery();
    localStorage.setItem('token', "");
    this.token = localStorage.getItem('token');

    if( navigate == true ) {
      this.route.navigateByUrl('/');
    }
  }

  sessionUserData( userData, token ) {
    //Lógica con la que guardamos los datos del inicio de sesión
    localStorage.setItem('token', JSON.stringify(token));
    this.token = token;
    this.apiDataService.setToken(this.token);
    this.setUserData( userData );
  }

  setUserData(updatedData: any) {
    this.user.idUser = updatedData.idUser;
    this.user.idRol = updatedData.idRol;
    this.user.idTown = updatedData.idTown,
    this.user.username = updatedData.username;
    this.user.password = updatedData.password;
    this.user.passwordGoogle = updatedData.passwordGoogle;
    this.user.passwordFacebook = updatedData.passwordFacebook;
    this.user.email = updatedData.email;
    this.user.step = updatedData.step;
    this.user.name = updatedData.name;
    this.user.telephone = updatedData.telephone;
    this.user.idFacebook = updatedData.idFacebook;
    this.user.facebookPhoto = updatedData.facebookPhoto;
    this.user.idGoogle = updatedData.idGoogle;
    this.user.googlePhoto = updatedData.googlePhoto;
    this.user.photoSelected = updatedData.photoSelected;
    this.user.userHometown = updatedData.userHometown;
    this.user.instagramBasic = updatedData.instagramBasic;
    this.user.ageRange = updatedData.ageRange;
    this.user.birthday = updatedData.birthday;
    this.user.gender = updatedData.gender;
    this.user.link = updatedData.link;
    this.user.location = updatedData.location;
    this.user.conektaToken = updatedData.conektaToken;
    this.user.rememberToken = updatedData.rememberToken;
    this.user.automaticPaymentActive = updatedData.automaticPaymentActive;
    this.user.requireInvoice = updatedData.requireInvoice;
    this.user.monthsBuy = updatedData.monthsBuy;
    this.user.validUntil = new Date(updatedData.validUntil);
    this.user.lastPayment = new Date(updatedData.lastPayment);
    this.user.lastLogin = new Date(updatedData.lastLogin);
    this.user.createdAt = new Date(updatedData.createdAt);
    this.user.updatedAt = new Date(updatedData.updatedAt);

    this.user.town = updatedData.town;

    this.getFavorites();
    this.getActiveRequests();

    if( this.user.idRol == 200 || this.user.idRol == 210 || 
      this.user.idRol == 300 || this.user.idRol == 310 ){
        this.reloadMenu();
    }
  }

  getFavorites(){
    this.favoritesReady = false;
    this.apiDataService.getUserFavorites().then(async (response : ServerMessage)=>{
      //console.log("exito");
      //console.log(response);
      
      if(response.error == true){
        console.log(response);
        this.utilitiesService.showErrorToast(response.message, "Error");
      }else if(response.error == false){
        this.userServiceProfileFavorites = response.data.userServiceProfileFavorites;

        for (let index = 0; index < this.userServiceProfileFavorites.length; index++) {
          this.userServiceProfileFavorites[index];
            //Load logo image new
          this.userServiceProfileFavorites[index].imageLogoFileImageBlob = await this.apiDataService.getImage(this.apiDataService.baseURL +
            this.userServiceProfileFavorites[index].profilePhotho);
          this.userServiceProfileFavorites[index].imageSlideFileImageBlob = await this.apiDataService.getImage(this.apiDataService.baseURL +
            this.userServiceProfileFavorites[index].photo);
          this.userServiceProfileFavorites[index].imagesReady = true;
        }
        await this.utilitiesService.sleep(1000);
        this.favoritesReady = true;
      }
    }).catch((error)=>{
      console.log(error);
      this.utilitiesService.showErrorToast("A ocurrido un error", "Error");
      this.favoritesReady = true;
    });
  }

  getActiveRequests(){
    this.requestReady = false;
    this.apiDataService.getUserOpenRequest().then(async (response : ServerMessage)=>{
      if (response.error == true) {
        console.log(response);
        this.utilitiesService.showErrorToast(response.message, "Error");
      } else if (response.error == false) {
        for (let index = 0; index < response.data.chatList.length; index++) {
          if (response.data.chatList[index].imageUrl.length != 0) {
            //Load logo image
            response.data.chatList[index].imageLogoFileImageBlob = await this.apiDataService.getImage(this.apiDataService.baseURL +
              response.data.chatList[index].imageUrl);

            //this.userServiceProfileFavorites[index].imageSlideFileImageBlob = await this.apiDataService.getImage(this.apiDataService.baseURL +
            //    this.userServiceProfileFavorites[index].photo);
            //this.userServiceProfileFavorites[index].imagesReady = true;
          }
        }
        this.userOpenenedRequests = response.data.chatList;

        try {
          if( this.noUnreadRequest != response.data.totalUnread ){
            var sound = new Howl({
              src: ['assets/audios/notification-tone-swift-gesture.mp3'],
              
            });
            
            sound.play();
          }
          
        } catch (error) {
          console.log(error);
        }
        this.noUnreadRequest = response.data.totalUnread;
      }
      //console.log(this.userOpenenedRequests);
      
      await this.utilitiesService.sleep(1000);
      this.requestReady = true;
    }).catch((error)=>{
      console.log(error);
      this.utilitiesService.showErrorToast("A ocurrido un error", "Error");
      this.requestReady = true;
    });

    if( this.user.idRol == 200 || this.user.idRol == 210 || this.user.idRol == 300 || this.user.idRol == 310 ){
      this.apiDataService.getChatListProfileServiceViewData().then(async (response : ServerMessage)=>{
        if (response.error == true) {
          console.log(response);
          this.utilitiesService.showErrorToast(response.message, "Error");
        } else if (response.error == false) {
          for (let index = 0; index < response.data.chatList.length; index++) {
            if (response.data.chatList[index].imageUrl.length != 0) {
              //Load logo image
              response.data.chatList[index].imageLogoFileImageBlob = await this.apiDataService.getImage(this.apiDataService.baseURL +
                response.data.chatList[index].imageUrl);
            }
          }

          this.profileOpenenedRequests = response.data.chatList;
          try {
            if( this.noUnreadMessages != response.data.totalUnread ){
              var sound = new Howl({
                src: ['assets/audios/notification-tone-swift-gesture.mp3'],
                
              });
              
              sound.play();
            }
            
          } catch (error) {
            console.log(error);
          }

          this.noUnreadMessages = response.data.totalUnread;
        }
        await this.utilitiesService.sleep(1000);
        this.requestReady = true;
      }).catch((error)=>{
        console.log(error);
        this.utilitiesService.showErrorToast("A ocurrido un error", "Error");
        this.requestReady = true;
      });
    }
  }
  haveContactInformation : boolean;
  haveGenresInstruments : boolean;
  haveSliders : boolean;
  havePackages : boolean;
  haveInteriors : boolean;
  haveExteriors : boolean;

  checkProfileActiveData( callBack : any = null) {
    this.apiDataService.getProfileUserActiveFlags().then(async (response : ServerMessage)=>{
      //console.log("exito");
      //console.log(response.data);
      //to do tambien resetear en el catch
      if( response.error == true ){
        this.haveCards = false;
        this.profileActivated = false;
        this.pausedProfile = true;
        //Vista informacion
        this.profileCreated = false;
        this.haveContactInformation = false;
        this.haveGenresInstruments = false;
        //Vista de la galeria
        this.profileLogo = "";
        this.haveSliders = false;
        //Vista de las instalaciones del salón
        this.haveInteriors = false;
        this.haveExteriors = false;
        //Vista paquetes
        this.havePackages = false;
      }else if( response.error == false ){
        this.haveCards = response.data.haveCards;
        this.profileActivated = response.data.profileActivated;
        this.pausedProfile = response.data.pausedProfile;
        //Vista informacion
        this.profileCreated = response.data.profileCreated;
        this.haveGenresInstruments = response.data.haveGenresInstruments;
        this.haveContactInformation = response.data.haveContactInformation;
        //Vista de las instalaciones del salón
        this.haveInteriors = response.data.haveInteriors;
        this.haveExteriors = response.data.haveExteriors;
        //Vista de la galeria
        if (response.data.profileLogo.length != 0) {
          //Load logo image
          this.imageLogoFileImageBlob = await this.apiDataService.getImage(this.apiDataService.baseURL +
            response.data.profileLogo);
        }
        this.profileLogo = response.data.profileLogo;
        this.haveSliders = response.data.haveSliders;
        //Vista paquetes
        this.havePackages = response.data.havePackages;
        
        //recalc alarms menu
        for (let index = 0; index < this.sidebarNavItems.length; index++) {
          const element = this.sidebarNavItems[index];
    
          if( element.path.includes('information') == true ){
            if( this.profileCreated == false || this.haveContactInformation == false ){
              this.sidebarNavItems[index].showAlert = true;
            }else{
              this.sidebarNavItems[index].showAlert = false;
            }


            if( this.user.idRol == 200 || this.user.idRol == 210 ){
              if( this.haveGenresInstruments == false ){
                this.sidebarNavItems[index].showAlert = true;
              }else{
                this.sidebarNavItems[index].showAlert = false;
              }
            }
          }else if( element.path.includes('gallery') == true ){
            if( this.profileLogo.length == 0 || this.haveSliders == false ){
              this.sidebarNavItems[index].showAlert = true;
            }else{
              this.sidebarNavItems[index].showAlert = false;
            }
          }else if( element.path.includes('packages') == true ){
            if( this.havePackages == false ){
              this.sidebarNavItems[index].showAlert = true;
            }else{
              this.sidebarNavItems[index].showAlert = false;
            }
          }else if( element.path.includes('place-facilities') == true ){
            if( this.haveInteriors == false || this.haveExteriors == false ){
              this.sidebarNavItems[index].showAlert = true;
            }else{
              this.sidebarNavItems[index].showAlert = false;
            }
          } // else if( element.path.includes('videos') == true ){
            //if( this.haveInteriors == false || this.haveExteriors == false ){
            //  this.sidebarNavItems[index].showAlert = true;
            //}
          //} 
        } 

        try {
          callBack();
        } catch (error) {
          
        }
      }
    }).catch((error)=>{
      console.log(error);
      
      this.profileActivated = false;
      this.haveCards = false;
      this.profileCreated = false;
      this.pausedProfile = true;
      this.profileLogo = "";
    });
  }

  public sidebarNavItems: RouteInfo[];
  
  reloadMenu(){
    if(this.user.idRol == 200 || this.user.idRol == 210){
      this.sidebarNavItems = BANDMENU;

      if( this.user.idRol == 200 ){
        //Se deshabilitan las opciones del menu
        this.sidebarNavItems = this.sidebarNavItems.filter((sidebarItem)=>{
          return !sidebarItem.path.includes('schedule') == true /* || 
            !sidebarItem.path.includes('messages') == true  */
        });
      }
    }else if( this.user.idRol == 300 || this.user.idRol == 310 ){
      this.sidebarNavItems = EVENTHALLMENU;
    }

    this.checkProfileActiveData(()=>{});
  }
}
