
/* user types
    0 = admin
    1 = provider
    2 = customer 
*/

import { BillingInformation } from "./billingInformation.class";
import { Town } from "./statesTowns.class ";
import { ServiceProfile } from "./serviceProfile.class";
import { SafeUrl } from "@angular/platform-browser";

export class User {
    idUser: number;
    idRol: number;
    idTown : number;
    username: string;
    password: string;
    passwordGoogle: string;
    passwordFacebook: string;
    email: string;
    step: number;
    name: string;
    telephone: string;
    idFacebook: string;
    facebookPhoto: string;
    idGoogle: string;
    googlePhoto: string;
    photoSelected: string;
    userHometown: string;
    instagramBasic: string;
    ageRange: string;
    birthday: string;
    gender: string;
    link: string;
    location: string;
    conektaToken: string;
    rememberToken: string;
    automaticPaymentActive: number;
    requireInvoice: boolean;
    monthsBuy : number;
    validUntil: Date;
    lastPayment: Date;
    lastLogin: Date;
    createdAt: Date;
    updatedAt: Date;

    billingInformation : BillingInformation;
    town : Town;

    constructor() {
        this.idUser = -1;
        this.idRol = -1;
        this.idTown = -1;
        this.username = "";
        this.password = "";
        this.passwordGoogle = "";
        this.passwordFacebook = "";
        this.email = "";
        this.step = -1;
        this.name = "";
        this.telephone = "";
        this.idFacebook = "";
        this.facebookPhoto = "";
        this.idGoogle = "";
        this.googlePhoto = "";
        this.photoSelected = "";
        this.userHometown = "";
        this.instagramBasic = "";
        this.ageRange = "";
        this.birthday = "";
        this.gender = "";
        this.link = "";
        this.location = "";
        this.conektaToken = "";
        this.rememberToken = "";
        this.automaticPaymentActive = -1;
        this.requireInvoice = false;
        this.monthsBuy = 0;
        this.validUntil = new Date();
        this.lastPayment = new Date();
        this.lastLogin = new Date();
        this.createdAt = new Date();
        this.updatedAt = new Date();
        this.billingInformation = new BillingInformation();

        this.town = new Town();
    }
}

export class NormalUserDto {
    idUser: number;
    username: string;
    email: string;
    name: string;
    telephone: string;
    idFacebook: string;
    facebookPhoto: string;
    idGoogle: string;
    googlePhoto: string;
    photoSelected: string;
    userHometown: string;
    instagramBasic: string;
    ageRange: string;
    birthday: string;
    gender: string;
    link: string;
    location: string;
    lastLogin: Date;
    createdAt: Date;
    updatedAt: Date;

    bandReviews : number;
    bandFavorites : number;
    bandComments : number;

    profileReviews : number;
    serviceProfileFavorites : number;
    serviceProfileComments : number;

    constructor() {
        this.idUser = -1;
        this.username = "";
        this.email = "";
        this.name = "";
        this.telephone = "";
        this.idFacebook = "";
        this.facebookPhoto = "";
        this.idGoogle = "";
        this.googlePhoto = "";
        this.photoSelected = "";
        this.userHometown = "";
        this.instagramBasic = "";
        this.ageRange = "";
        this.birthday = "";
        this.gender = "";
        this.link = "";
        this.location = "";
        this.lastLogin = new Date();
        this.createdAt = new Date();
        this.updatedAt = new Date();

        this.bandReviews = -1;
        this.bandFavorites = -1;
        this.bandComments = -1;
    
        this.profileReviews = -1;
        this.serviceProfileFavorites = -1;
        this.serviceProfileComments = -1;
    }
}

export class BandUserDto {
    idUser: number;
    idRol: number;
    username: string;
    email: string;
    bandName: string;
    bandStateLocation : string;
    telephone: string;
    automaticPaymentActive: boolean;
    requireInvoice: boolean;
    validUntil: Date;
    lastPayment: Date;
    lastLogin: Date;
    activated : boolean;
    overduePayment : boolean;

    bandReviews: number;
    bandFavorites: number;
    bandComments: number;

    constructor() {
        this.idUser = -1;
        this.idRol = -1;
        this.username = "";
        this.email = "";
        this.bandName = "";
        this.bandStateLocation = "";
        this.telephone = "";
        this.automaticPaymentActive = false;
        this.requireInvoice = false;
        this.validUntil = new Date();
        this.lastPayment = new Date();
        this.lastLogin = new Date();
        this.activated = false;
        this.overduePayment = false;

        this.bandReviews = -1;
        this.bandFavorites = -1;
        this.bandComments = -1;
    }
}

export class EventHallUserDto {
    idUser: number;
    idRol: number;
    username: string;
    email: string;
    serviceProfileName: string;
    serviceProfileStateLocation : string;
    telephone: string;
    automaticPaymentActive: boolean;
    requireInvoice: boolean;
    validUntil: Date;
    lastPayment: Date;
    lastLogin: Date;
    activated : boolean;
    overduePayment : boolean;

    profileReviews: number;
    serviceProfileFavorites: number;
    serviceProfileComments: number;

    constructor() {
        this.idUser = -1;
        this.idRol = -1;
        this.username = "";
        this.email = "";
        this.serviceProfileName = "";
        this.serviceProfileStateLocation = "";
        this.telephone = "";
        this.automaticPaymentActive = false;
        this.requireInvoice = false;
        this.validUntil = new Date();
        this.lastPayment = new Date();
        this.lastLogin = new Date();
        this.activated = false;
        this.overduePayment = false;

        this.profileReviews = -1;
        this.serviceProfileFavorites = -1;
        this.serviceProfileComments = -1;
    }
}


export class EventHallFavoritesDtoNav {
    idServiceProfile: number;
    name: string;
    profilePhotho: string;
    photo: string;
    basePrice: number;
    reviews: number;
    score: number;
    townName: string;
    stateName: string;
    imagesReady: boolean;
    type : number;

    imageLogoFileImageBlob : SafeUrl;
    imageSlideFileImageBlob : SafeUrl;
    
    constructor() {
        this.idServiceProfile = -1;
        this.name = "";
        this.profilePhotho = "";
        this.photo = "";
        this.basePrice = 0;
        this.reviews = 0;
        this.score = 0;
        this.type = -1;
        this.townName = "";
        this.stateName = "";
        this.imagesReady = false;
        
        this.imageLogoFileImageBlob = "";
        this.imageSlideFileImageBlob = "";
    }
}


export class AgentUserDto {
    idUser: number;
    username: string;
    email: string;
    name: string;
    telephone: string;
    //photoSelected: user.photoSelected,
    //userHometown: this.checkNullUndefined(user.userHometown) ? "" : user.userHometown,
    birthday: Date;
    gender: string;
    location: string;
    lastLogin: Date;

    payed : number;
    toPay : number;

    constructor() {
        this.idUser = -1;
        this.username = "";
        this.email = "";
        this.name = "";
        this.telephone = "";
        //photoSelected: user.photoSelected,
        //userHometown: this.checkNullUndefined(user.userHometown) ? "" : user.userHometown,
        this.birthday = new Date();
        this.gender = "";
        this.location = "";
        this.lastLogin = new Date();
    
        this.payed = -1;
        this.toPay = -1;
    }
}

export class Role {
    idRol : number;
    name : string;
    description : string;
    monthlyCost : string;
    currency : string;
    duration : number;
    satCodeProduct : string;
    unitSatCode : string;
    createdAt : Date;

    constructor() {
        this.idRol = -1;
        this.name = "";
        this.description = "";
        this.monthlyCost = "";
        this.currency = "";
        this.duration = 0;
        this.satCodeProduct = "";
        this.unitSatCode = "";
        this.createdAt = new Date();
    }
}