

<div class="col-sm-12" style="text-align: -webkit-center;align-self: center;padding: 0px;">
    <div class="auth-wrapper d-flex no-block justify-content-center align-items-center image-background">
        <div class="auth-box on-sidebar with-sidebar sidebar-personal">
            <div class="card " style="border-radius: 29px;">
                <div class="card-body max-w-500" style="padding: 40px;">
                    <div id="loginform">
                        <div class="logo">
                            <a class="db" routerLink="/">
                                <img src="assets/images/personajes.png" alt="logo" style="height: 150px;" />
                            </a>
                            <h1 class="font-medium m-b-20">Registrarse</h1>
                            <h4 >Ingresa tus datos</h4>
                            <h5 ><a routerLink="/preregister" class="text-dark text-grey">Registrar mi negocio.</a></h5>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <form autocomplete="off" class="form-horizontal m-t-20" id="loginform">
                                    <div class="input-group mb-3">  
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"><i class="ti-user"></i></span>
                                        </div>
                                        <input required name="username" [disabled]="showBtnLoading"
                                            [(ngModel)]="userName" 
                                            (focusout)="onFocusOutFormUsername($event)" 
                                            [ngModelOptions]="{standalone: true}"
                                            type="text" class="form-control form-control-lg" placeholder="Usuario" >
                                        <label *ngIf="!validUsername" class="validation-label">
                                            * El nombre de usuario debe contener un mínimo de 8 caracteres.
                                        </label>
                                    </div>

                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon2"><i class="ti-email"></i></span>
                                        </div>  
                                        <input 
                                            required [disabled]="showBtnLoading"
                                            [(ngModel)]="email" 
                                            (focusout)="onFocusOutFormRecoverEmail($event)" 
                                            [ngModelOptions]="{standalone: true}"
                                            class="form-control form-control-lg" type="email" required placeholder="Correo Electrónico">
                                        <label *ngIf="!validEmail" class="validation-label">
                                            * Correo invalido.
                                        </label>
                                    </div>

                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon2"><i class="ti-pencil"></i></span>
                                        </div>  
                                        <input required name="password" [disabled]="showBtnLoading"
                                            [(ngModel)]="password" 
                                            (focusout)="onFocusOutFormPassword($event)" 
                                            [ngModelOptions]="{standalone: true}"
                                            type="password" class="form-control form-control-lg" placeholder="Contraseña">
                                        <label *ngIf="!validPassword" class="validation-label">
                                            * La contraseña debe contener un mínimo de 8 caracteres.
                                        </label>
                                    </div>

                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon2"><i class="ti-pencil"></i></span>
                                        </div>  
                                        <input required [disabled]="showBtnLoading" name="confirmPassword" 
                                            [(ngModel)]="confirmPassword" 
                                            (focusout)="onFocusOutFormConfirmPassword($event)" 
                                            [ngModelOptions]="{standalone: true}"
                                            type="password" class="form-control form-control-lg" placeholder="Confirmar contraseña">
                                        <label *ngIf="!validConfirmPassword" class="validation-label">
                                            * Las contraseñas no coinciden.
                                        </label>
                                    </div>

                                    <div class="form-group text-center">
                                        <div class="col-xs-12 p-b-20">
                                            <!-- <button routerLink="/dashboard/admin/home" class="btn btn-block btn-lg btn-info" type="submit">Iniciar Sesión</button> -->
                                            <button
                                                [disabled]="
                                                userName.length < 8 || 
                                                !re.test(email.toLowerCase() ) ||
                                                password.length < 8 || 
                                                confirmPassword != password ||
                                                showBtnLoading == true"
                                                (click)="registerUser()" class="btn btn-block btn-lg btn-success color-white max-btn-animate" type="submit">

                                                <div class="loader loader-btn" *ngIf="showBtnLoading">Loading...</div>
                                                <label *ngIf="!showBtnLoading" class="no-margin">Registrar</label>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-12 no-margin">
                                            <div class="custom-control no-padding">
                                                <h3 id="to-recover" class="text-dark float-center">
                                                    <!-- <i class="fa fa-lock m-r-5"></i>  -->
                                                    Ó</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group text-center">
                                        <div class="col-xs-12 p-b-20">
                                            <!-- <button routerLink="/dashboard/admin/home" class="btn btn-block btn-lg btn-info" type="submit">Iniciar Sesión</button> -->
                                            <button [disabled]="showBtnLoading" class="btn btn-block btn-lg btn-success color-white max-btn-animate" routerLink="/login" type="submit">Iniciar Sesión</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>