<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
    <li class="nav-item d-none d-md-block">
        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light" href="javascript:void(0)" data-sidebartype="mini-sidebar">
            <i class="fa-solid fa-bars font-20"></i>
        </a>
    </li>
    <!-- ============================================================== -->
    <!-- Messages -->
    <!-- ============================================================== -->
    <li class="nav-item" ngbDropdown>
        <a ngbDropdownToggle class="nav-link waves-effect waves-dark" href="javascript:void(0)" id="2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="font-20 ti-email"></i>
        </a>
        <!-- <div class="mailbox" aria-labelledby="2" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-danger"></span>
            </span>
            <ul class="list-style-none">
                <li>
                    <div class="drop-title bg-danger text-white">
                        <h4 class="m-b-0 m-t-5">5 New</h4>
                        <span class="font-light">Messages</span>
                    </div>
                </li>
                <li>
                    <div class="message-center message-body" [perfectScrollbar]="config">
                         
                        <a href="javascript:void(0)" class="message-item" *ngFor="let mymessage of mymessages">
                            <span class="user-img">
                                <img src="{{mymessage.useravatar}}" alt="user" class="rounded-circle">
                                <span class="profile-status {{mymessage.status}} pull-right"></span>
                            </span>
                            <span class="mail-contnet">
                                <h5 class="message-title">{{mymessage.from}}</h5>
                                <span class="mail-desc">{{mymessage.subject}}</span>
                                <span class="time">{{mymessage.time}}</span>
                            </span>
                        </a>
                    </div>
                </li>
                <li>
                    <a ngbDropdownToggle class="nav-link text-center link" routerLink="/dashboard/admin/messages" id="2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <b>Ver todos los mensajes</b>
                        <i class="fa fa-angle-right"></i>
                    </a>
                </li>
            </ul>
        </div> -->
    </li>
    <!-- ============================================================== -->
    <!-- End Messages -->
    <!-- ============================================================== -->


</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
    <li class="nav-item" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <!-- <i class="flag-icon flag-icon-mx" style="padding: 16px;margin: 15px;"></i> -->
            <img src="assets/images/users/no-user-image.jpg" alt="user" class="rounded-circle" width="31">
        </a>
        <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
            <span class="with-arrow">
                <span class="bg-primary"></span>
            </span>
            <div class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
                <div class="">
                    <img src="assets/images/users/no-user-image.jpg" alt="user" class="img-circle" width="60">
                </div>
                <div class="m-l-10">
                    <h4 class="m-b-0">{{dataSessionService.user.name}}</h4>
                    <p class=" m-b-0">{{dataSessionService.user.email}}</p>
                </div>
            </div>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" routerLink="/dashboard-admin/account-settings">
                <i class="ti-settings m-r-5 m-l-5"></i> Ajustes de la cuenta</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript:void(0)" (click)="logOut()">
                <i class="fa fa-power-off m-r-5 m-l-5"></i> Cerrar sesión</a>
            <div class="dropdown-divider"></div>
            <!-- <div class="p-l-30 p-10">
                <a href="javascript:void(0)" class="btn btn-sm btn-success btn-rounded">View Profile</a>
            </div> -->
        </div>
    </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
</ul>