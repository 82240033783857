import { Component, OnInit } from '@angular/core';
import { DataSessionService } from '../../../services/dataSession/data-session.service';
import { UtilitiesService } from '../../../services/utilities/utilities.service';
import { LoggedResponse } from '../../../classes/loggedResponse.class';
import { ServerMessage } from '../../../classes/serverMessage.class';
import { ApiUserDataService } from '../../../services/apiUserData/api-user-data.service';
import { SEOServiceService } from '../../../services/SEOService/seoservice.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit {

  userName: string;
  email : string;
  password: string;
  confirmPassword: string;


  re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  validUsername : boolean = true;
  validEmail : boolean = true;
  validPassword : boolean = true;
  validConfirmPassword : boolean = true;
  showBtnLoading : boolean = false;

  constructor(public dataSessionService: DataSessionService, private utilitiesService: UtilitiesService,
    private apiUserDataService : ApiUserDataService,private SEOServiceService : SEOServiceService) {
      SEOServiceService.updateMeta(()=>{});
    }

  ngOnInit(): void {
    this.clearData();
    // this.dataSessionService.logOut();
    // console.log(this.dataSessionService.user);
    this.dataSessionService.checkLogin((logedResponse: LoggedResponse) => {
      //console.log(logedResponse);
      /* if (this.dataSessionService.user.userType == 0 ) {
        console.log("es admin");
        //this.utilitiesService.showSuccessToast( logedResponse.message,"Exito!");
        this.dataSessionService.navigateByUrl("/dashboard-admin/home");
      } else if (this.dataSessionService.user.userType == 1 ) {
        console.log("es provedor");
        //this.utilitiesService.showSuccessToast( logedResponse.message,"Exito!");
        this.dataSessionService.navigateByUrl("/dashboard-provider/home");
      }else if (this.dataSessionService.user.userType == 2) {
        this.utilitiesService.showInfoToast("Aun no se cuenta con este servicio.");
        this.dataSessionService.logOut();
      }else{
        this.utilitiesService.showErrorToast( "Usuario desconocido.","Error!");
        this.dataSessionService.logOut();
      } */
    }, (noLoginResponse: LoggedResponse) => {
      //console.log(noLoginResponse);
    });
  }

  onFocusOutFormUsername(event: any){
    //console.log(event.target.value);
    if(this.userName.length < 8){
      this.utilitiesService.showInfoToast( "Nombre de usuario invalido.");
      this.validUsername = false;
    }else{
      this.validUsername = true;
    }
  }

  onFocusOutFormRecoverEmail(event: any){
    if (!this.re.test(String(this.email).toLowerCase()) ) {
      this.utilitiesService.showInfoToast( "Correo invalido.");
      this.validEmail = false;
    }else{
      this.validEmail = true;
    }
  }

  onFocusOutFormPassword(event: any){
    //console.log(event.target.value);
    if(this.password.length < 8){
      this.utilitiesService.showInfoToast("Contraseña invalida.");
      this.validPassword = false;
    }else{
      this.validPassword = true;
    }
  }

  onFocusOutFormConfirmPassword(event: any){
    //console.log(event.target.value);
    if(this.password != this.confirmPassword){
      this.utilitiesService.showInfoToast("Las contraseñas no coinciden.");
      this.validConfirmPassword = false;
    }else{
      this.validConfirmPassword = true;
    }
  }

  clearData() {
    /* this.email = "luismi.luu@gmail.com";
    this.password = "qwertyuiop"; */
    this.userName = "";
    this.password = "";
    this.confirmPassword = "";
    this.email = "";
    this.validUsername = true;
    this.validPassword = true;
    this.validEmail = true;
    this.showBtnLoading = false;
  }

  registerUser() {
    this.showBtnLoading = true;

    this.apiUserDataService.createUser(this.userName , this.email , this.password).then((response : ServerMessage)=>{
      if(response.error == true){
        console.log(response);
        this.utilitiesService.showErrorToast(response.message,"Error");
      }else{
        this.utilitiesService.showSuccessToast(response.message,"Éxito");
        this.dataSessionService.navigateByUrl("/login");
      }
      this.showBtnLoading = false;
      
    }).catch((error)=>{
      console.log("error");
      console.log(error);
      this.showBtnLoading = false;
    });
  }

}
