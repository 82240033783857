/* import { NgModule } from '@angular/core'; */
import { Routes } from '@angular/router';

//Layout para crear una capa donde se renderizan dentro otras vistas
import { BlankComponent } from './layouts/blank/blank.component';

import { FullPublicComponent } from './layouts/full-public/full-public.component';
import { FullAdminComponent } from './layouts/full-admin/full-admin.component';
import { FullBandComponent } from './layouts/full-band/full-band.component';
import { FullEventHallComponent } from './layouts/full-event-hall/full-event-hall.component';
import { FullAgentComponent } from './layouts/full-agent/full-agent.component';

//Vistas de las paginas con layout full y publicas
import { LoginComponent } from './views/public/login/login.component';
import { NotfoundComponent } from './views/public/404/not-found.component';
import { FaqComponent } from './views/public/faq/faq.component';
import { RegisterComponent } from './views/public/register/register.component';
import { FullFinalUserComponent } from './layouts/full-final-user/full-final-user.component';

export const Approutes: Routes = [
  { path: '', redirectTo: '/'/* '/landing-page' */, pathMatch: 'full' },
  {
    path: '',
    component: FullPublicComponent,
    children: [
      {
        path: '',
        //Se carga de esta manera porque dentro del modulo del dashboard vienen componentes que 
        //se comparten y pueden usar en las vistas dentro del modulo
        loadChildren: () => import('./views/public/public.module').then(m => m.PublicModule)
      },
    ]
  },
  {
    path: '',
    //pathMatch: 'full',
    component: BlankComponent,
    children: [
      {
        path: 'register',
        component: RegisterComponent,
        data: {
          title : 'Enbibo | Registro de usuarios.',
          description:'Registrate y se parte de enbibo.com.',
          keywords : 'Iniciar Sesión, enbibo, enbibo.com ',
          ogUrl: 'https://enbibo.com/#/register',
          ogTitle: 'Registro de usuarios',
          ogDescription : 'Registrate y se parte de enbibo.com.',
          ogImage : 'https://enbibo.com/#/assets/minified/images/meta.png',
          urls: [
            /* { title: 'Home', url: '/dashboard-admin/home' }, */
            /* { title: 'Inicio' } */
          ]
        }
      },/* {
        path: 'login',
        component: LoginComponent,
        data: {
          title : 'Iniciar Sesión',
          description:'Iniciar sesión de cuenta en enbibo.com.',
          keywords : 'Iniciar Sesión, enbibo, enbibo.com ',
          ogUrl: 'https://enbibo.com/#/login',
          ogTitle: 'Iniciar Sesión en enbibo.com',
          ogDescription : 'Iniciar sesión de cuenta en enbibo.com.',
          ogImage : 'https://enbibo.com/#/assets/minified/images/meta.png',
          urls: [
            //{ title: 'Home', url: '/dashboard-admin/home' },
            //{ title: 'Inicio' }
          ]
        }
      }, */
      {
        path: '404',
        component: NotfoundComponent
      },
    ]
  },
  {
    path: '',
    component: FullFinalUserComponent,
    children: [
      {
        path: 'user-account',
        //Se carga de esta manera porque dentro del modulo del dashboard vienen componentes que 
        //se comparten y pueden usar en las vistas dentro del modulo
        loadChildren: () => import('./views/dashboard/final-user/dashboard-final-user.module').then(m => m.DashboardFinalUserModule)
      },
    ]
  },
  {
    path: '',
    component: FullAdminComponent,
    children: [
      {
        path: 'dashboard-admin',
        //Se carga de esta manera porque dentro del modulo del dashboard vienen componentes que 
        //se comparten y pueden usar en las vistas dentro del modulo
        loadChildren: () => import('./views/dashboard/admin/dashboard-admin.module').then(m => m.DashboardAdminModule)
      },
    ]
  },
  {
    path: '',
    component: FullBandComponent,
    children: [
      {
        path: 'dashboard-band',
        //Se carga de esta manera porque dentro del modulo del dashboard vienen componentes que 
        //se comparten y pueden usar en las vistas dentro del modulo
        loadChildren: () => import('./views/dashboard/profile/band/dashboard-band.module').then(m => m.DashboardBandModule)
      },
    ]
  },
  {
    path: '',
    component: FullEventHallComponent,
    children: [ 
      {
        path: 'dashboard-event-hall',
        //Se carga de esta manera porque dentro del modulo del dashboard vienen componentes que 
        //se comparten y pueden usar en las vistas dentro del modulo
        loadChildren: () => import('./views/dashboard/profile/event-hall/dashboard-event-hall.module').then(m => m.DashboardEventHallModule)
      },
    ]
  },
  
  {
    path: '',
    component: FullAgentComponent,
    children: [ 
      {
        path: 'dashboard-agent',
        //Se carga de esta manera porque dentro del modulo del dashboard vienen componentes que 
        //se comparten y pueden usar en las vistas dentro del modulo
        loadChildren: () => import('./views/dashboard/agent/dashboard-agent.module').then(m => m.DashboardAgentModule)
      },
    ]
  },
  //Redireccionamiento en caso de que no exista la ruta
  {
    path: '**',
    redirectTo: '404'
  }
];
