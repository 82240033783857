import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataSessionService } from '../../../services/dataSession/data-session.service';
import { UtilitiesService } from '../../../services/utilities/utilities.service';
import { ServerMessage } from '../../../classes/serverMessage.class';
import { SEOServiceService } from '../../../services/SEOService/seoservice.service';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { SocialAuthService, SocialUser } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { State, Town } from '../../../classes/statesTowns.class ';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ApiDataService } from '../../../services/apiData/api-data.service';
import { ApiLiveChatDataService } from '../../../services/liveChat/api-live-chat-data.service';

export class SocialLoginUserDto {
  name: string /* + " " + user.lastName */;
  userEmail: string;
  socialPassword: string;
  photoSelected: string;
  provider: string;
  idTown: number;
  idState: number;
}
export class SocialRegisterUserDto {
  userRegisterName: string;
  passwordRegister: string;
  socialPassword: string;
  fullName: string;
  email: string /* + " " + user.lastName */;
  idTown: string;
  provider: string;
  photoSelected: string;
}

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit {
  loginForm: FormGroup;
  socialRegisterForm: FormGroup;

  socialUser: SocialLoginUserDto;
  isLogged: boolean;

  showBtnLoading: boolean = false;
  @Input() isLogin: boolean;

  @ViewChild("modalUserTownSelection") modalUserTownSelection;
  @ViewChild("modalRedirectPartners") modalRedirectPartners;

  statesData: State[];
  selectedState: State[];

  dropdownStateSettings: IDropdownSettings = {
    singleSelection: true,
    //placeholder : 'Seleccione una ciudad',
    maxHeight: 200,
    idField: 'idState',
    textField: 'name',
    searchPlaceholderText: 'Buscar por nombre',
    noDataAvailablePlaceholderText: 'Sin resultados',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    //itemsShowLimit: 3,
    allowSearchFilter: true,
    clearSearchFilter: true,
    limitSelection: 1,
    closeDropDownOnSelection: true,
  };

  townsData: Town[];
  selectedTown: Town[];

  dropdownTownSettings: IDropdownSettings = {
    singleSelection: true,
    //placeholder : 'Seleccione una ciudad',
    maxHeight: 200,
    idField: 'idTown',
    textField: 'name',
    searchPlaceholderText: 'Buscar por nombre',
    noDataAvailablePlaceholderText: 'Sin resultados',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    //itemsShowLimit: 3,
    allowSearchFilter: true,
    clearSearchFilter: true,
    limitSelection: 1,
    closeDropDownOnSelection: true,
  };

  alreadyOpen: boolean = false;

  constructor(private modalService: NgbModal, public dataSessionService: DataSessionService,
    private utilitiesService: UtilitiesService, private apiDataService: ApiDataService,
    private formBuilder: FormBuilder, private apiLiveChatDataService: ApiLiveChatDataService) {
    this.statesData = [];
    this.townsData = [];
    this.selectedState = [];
    this.selectedTown = [];

    this.initLoginForm();

    if (this.dataSessionService.loginAlreadyFirst == false) {
      this.dataSessionService.loginAlreadyFirst = true;

      this.dataSessionService.authService.authState.subscribe((user: SocialUser) => {
        //console.log(user);
        //console.log("entro en la subscription");

        this.showBtnLoading = true;
        this.loginForm.disable();

        if (this.alreadyOpen == false) {
          if (this.isLogged == false && user) {
            this.isLogged = true;
            let urlImage = '';

            if (user.provider == "FACEBOOK") {
              urlImage = user.response.picture.data.url;
            } else if (user.provider == "GOOGLE") {
              urlImage = user.photoUrl;
            }

            this.socialUser = {
              name: user.name,
              userEmail: user.email,
              socialPassword: user.id,
              photoSelected: urlImage,
              provider: user.provider,
              idTown: -1,
              idState: -1,
            };

            this.apiDataService.doSocialLogin(this.socialUser).then((response: ServerMessage) => {
              //console.log("exito"); 
              //console.log(response);

              if (response.error == true) {
                this.utilitiesService.showWarningToast(response.message, "Error!");
              } else if (response.error == false) {
                if (response.data.isNewUser == true) {
                  this.apiDataService.getStatesTownsCatalogData().then((response: ServerMessage) => {
                    if (response.error == true) {
                      console.log(response);
                      this.utilitiesService.showErrorToast(response.message, "Error");
                    } else if (response.error == false) {
                      this.statesData = response.data.statesData;

                      this.openSocialRegister();
                    }
                    this.showBtnLoading = false;
                    this.loginForm.enable();
                  }).catch((error) => {
                    console.log(error);
                    this.utilitiesService.showErrorToast("Ups! A ocurrido un error!", "Error");
                    this.showBtnLoading = false;
                    this.loginForm.enable();
                  });
                } else if (response.data.isNewUser == false) {
                  this.utilitiesService.showSuccessToast(response.message, "Éxito!");
                  this.showBtnLoading = false;
                  this.loginForm.enable();
                  this.dataSessionService.sessionUserData(response.data.loginData.user, response.data.loginData.token);

                  this.closeSuccessModal();
                }
              }
            }, (error) => {
              console.log(error);
              this.utilitiesService.showWarningToast(error.message, "Error!");
              this.showBtnLoading = false;
              this.loginForm.enable();
            });
          } else {
            this.showBtnLoading = false;
            this.loginForm.enable();
          }
        }
      });
    }
  }

  ngOnInit(): void {
    this.initLoginForm();
  }

  signInWithGoogle(): void {
    if (this.isLogged == false) {
      this.showBtnLoading = true;
      this.loginForm.disable();
      this.dataSessionService.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    } else {
      this.utilitiesService.showWarningToast("Actualmente logueado", "Error");
      this.signOut();
    }
  }

  signInWithFB(): void {
    if (this.isLogged == false) {
      this.showBtnLoading = true;
      this.loginForm.disable();
      this.dataSessionService.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    } else {
      this.utilitiesService.showWarningToast("Actualmente logueado", "Error");
      this.signOut();
    }
  }

  refreshGoogleToken(): void {
    if (this.isLogged == false) {
      this.showBtnLoading = true;
      this.dataSessionService.authService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
    } else {
      this.utilitiesService.showWarningToast("Actualmente logueado", "Error");
      this.signOut();
    }
  }

  signOut(): void {
    try {
      if (this.isLogged == true) {
        this.dataSessionService.authService.signOut(true);
      }
    } catch (e) {

    }
  }

  closeCancelModal() {
    this.showBtnLoading = false;
    this.isLogged = false;
    this.statesData = [];
    this.townsData = [];
    this.selectedState = [];
    this.selectedTown = [];

    this.signOut();
    this.modalService.dismissAll({ result: "cancel" });
  }

  closeAll() {
    this.modalService.dismissAll({ result: "cancel" });

    this.signOut();
  }

  alreadyRedirectOpen: boolean = false;

  closeSuccessModal() {
    this.showBtnLoading = false;
    this.isLogged = false;
    this.statesData = [];
    this.townsData = [];
    this.selectedState = [];
    this.selectedTown = [];

    /* Init chat data */
    if (this.apiLiveChatDataService.isOnline == false) {
      this.apiLiveChatDataService.connect(async () => {
        while (this.apiLiveChatDataService.isOnline == false) {
          await this.utilitiesService.sleep(500);
        }

        //this.getChatListEventHallViewData();
        //this.getChatRecordListProfileServiceViewData();
        /* historial  */
        /* this.apiLiveChatDataService.suscribeToRecordChats( ( dataToUpdate : { idChat : number }) => {
          if( dataToUpdate.idChat == this.actualChatSelected.idChat ){
            this.getChatMessagesEventHallData(this.actualChatSelected);
            this.actualChatSelected.closed = true;
            this.active = 1;
            this.owlCarLeft.to(1 + "");
            this.modalService.dismissAll();
            //this.actualChatSelected.comment = response.data.chatList[index].comment;
          }

          this.getChatListEventHallViewData();
          this.getChatRecordListProfileServiceViewData();
          console.log("se cerro un chat");
        }); */

        /* nuevos mensajes */
        this.apiLiveChatDataService.suscribeToChats((dataToUpdate: { idChat: number }) => {
          /* if( dataToUpdate.idChat == this.actualChatSelected.idChat ){
            this.getChatMessagesEventHallData(this.actualChatSelected);
          }else{
            this.getChatListEventHallViewData();
          } */
          this.dataSessionService.getActiveRequests();
        });
      });
    }

    if (this.dataSessionService.user.idRol == 300 || this.dataSessionService.user.idRol == 310 ||
      this.dataSessionService.user.idRol == 200 || this.dataSessionService.user.idRol == 210) {
      this.dataSessionService.checkProfileActiveData();
    }

    if (this.dataSessionService.user.idRol == 300 || this.dataSessionService.user.idRol == 310 ||
      this.dataSessionService.user.idRol == 200 || this.dataSessionService.user.idRol == 210 ||
      this.dataSessionService.user.idRol == 100) {
      if (this.alreadyRedirectOpen == false) {
        this.openModalRedirect();
      }
    } else {
      this.modalService.dismissAll({ result: "login-success" });
      this.signOut();
    }
  }

  initLoginForm() {
    this.showBtnLoading = false;
    this.isLogged = false;
    this.statesData = [];
    this.townsData = [];
    this.selectedState = [];
    this.selectedTown = [];
    this.alreadyRedirectOpen = false;


    //this.userName = "superadmin";
    //this.password = "qwertyuiop";

    this.loginForm = this.formBuilder.group({
      userName: ['',[Validators.minLength(8), Validators.required]],
      password: ['',[Validators.minLength(8), Validators.required]],
      //userName: ['superadmin', [Validators.minLength(8), Validators.required]],
      //password: ['qwertyuiop', [Validators.minLength(8), Validators.required]],
    });

    this.loginForm.enable();

    if (this.isLogin == false) {
      this.socialRegisterForm = this.formBuilder.group({
        fullName: ['', [Validators.minLength(5), Validators.required]],
        userRegisterName: ['', [Validators.minLength(8), Validators.required]],//
        email: ['', [Validators.email, Validators.required]],
        idState: [-1, [Validators.required]], //
        idTown: [-1, [Validators.required]], //
        passwordRegister: ['', [Validators.minLength(8), Validators.required]],//
        confirmPassRegister: ['', [Validators.minLength(8), Validators.required]],//
        socialPassword: ['', [Validators.minLength(5), Validators.required]],
        photoSelected: ['DEFAULT', [Validators.minLength(5), Validators.required]],
        provider: ['DEFAULT', [Validators.minLength(5), Validators.required]],
        acceptTerms: [false, [Validators.requiredTrue, Validators.required]],//
      });

      this.apiDataService.getStatesTownsCatalogData().then((response: ServerMessage) => {
        if (response.error == true) {
          console.log(response);
          this.utilitiesService.showErrorToast(response.message, "Error");
        } else if (response.error == false) {
          this.statesData = response.data.statesData;

          //this.openSocialRegister();
        }
        this.showBtnLoading = false;
      }).catch((error) => {
        console.log(error);
        this.utilitiesService.showErrorToast("Ups! A ocurrido un error!", "Error");
        this.showBtnLoading = false;
        this.loginForm.enable();
      });
    }
  }

  get userName(): AbstractControl {
    return this.loginForm.get('userName');
  }
  get password(): AbstractControl {
    return this.loginForm.get('password');
  }

  loginUser() {
    this.showBtnLoading = true;
    this.dataSessionService.loginUser(this.userName.value, this.password.value).then(async (response: ServerMessage) => {
      //console.log("exito");
      //console.log(response);
      await this.utilitiesService.sleep(500);
      if (response.error == true) {
        this.utilitiesService.showWarningToast(response.message, "Error!");
      } else if (response.error == false) {
        //load user data
        //this.clearData();
        this.dataSessionService.sessionUserData(response.data.user, response.data.token);
        this.closeSuccessModal();
      }
      this.showBtnLoading = false;
    }, async (error) => {
      console.log(error);
      await this.utilitiesService.sleep(1000);
      this.utilitiesService.showWarningToast(error.message, "Error!");
      this.showBtnLoading = false;
    });
  }
  //Modal Register functions
  openManualRegister() {
    this.apiDataService.checkEmailAvailable(this.email.value).then((response: ServerMessage) => {
      if (response.error == true) {
        this.utilitiesService.showInfoToast(response.message);
      } else if (response.error == false) {
        if (response.data.already == true) {
          this.utilitiesService.showInfoToast(response.message);
        } else if  (response.data.already == false) {
          this.socialRegisterForm = this.formBuilder.group({
            fullName: [this.fullName.value, [Validators.minLength(5), Validators.required]],
            userRegisterName: ['', [Validators.minLength(8), Validators.required]],//
            email: [this.email.value, [Validators.email, Validators.required]],
            idState: [-1, [Validators.required]], //
            idTown: [-1, [Validators.required]], //
            passwordRegister: ['', [Validators.minLength(8), Validators.required]],//
            confirmPassRegister: ['', [Validators.minLength(8), Validators.required]],//
            socialPassword: ["123456", [Validators.minLength(5), Validators.required]],
            photoSelected: ["DEFAULT", [Validators.minLength(5), Validators.required]],
            provider: ["DEFAULT", [Validators.minLength(5), Validators.required]],
            acceptTerms: [true, [Validators.requiredTrue, Validators.required]],//
          });
      
          this.alreadyOpen = true;
      
          this.modalService.open(this.modalUserTownSelection, {
            ariaLabelledBy: 'modal-basic-title', centered: true, size: 'md'
          }).result.then((result) => {
            //this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
        }
      }
    }, (error) => {
      console.log(error);
      this.utilitiesService.showWarningToast(error.message, "Error!");
      this.socialRegisterForm.enable();
      this.showBtnLoading = false;
    });
  }

  openSocialRegister() {
    this.socialRegisterForm = this.formBuilder.group({
      fullName: [this.socialUser.name, [Validators.minLength(5), Validators.required]],
      userRegisterName: ['', [Validators.minLength(8), Validators.required]],//
      email: [this.socialUser.userEmail, [Validators.email, Validators.required]],
      idState: [-1, [Validators.required]], //
      idTown: [-1, [Validators.required]], //
      passwordRegister: ['', [Validators.minLength(8), Validators.required]],//
      confirmPassRegister: ['', [Validators.minLength(8), Validators.required]],//
      socialPassword: [this.socialUser.socialPassword, [Validators.minLength(5), Validators.required]],
      photoSelected: [this.socialUser.photoSelected, [Validators.minLength(5), Validators.required]],
      provider: [this.socialUser.provider, [Validators.minLength(5), Validators.required]],
      acceptTerms: [true, [Validators.requiredTrue, Validators.required]],//
    });

    this.alreadyOpen = true;

    this.modalService.open(this.modalUserTownSelection, {
      ariaLabelledBy: 'modal-basic-title', centered: true, size: 'md'
    }).result.then((result) => {
      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  socialRegisterUser() {
    this.showBtnLoading = true;
    this.socialRegisterForm.disable();

    this.apiDataService.createNewSocialProfileUser(this.socialRegisterForm.value).then((response: ServerMessage) => {
      if (response.error == true) {
        this.utilitiesService.showWarningToast(response.message, "Error!");
      } else if (response.error == false) {
        this.dataSessionService.loginUser(this.userRegisterName.value, this.passwordRegister.value).then((response: ServerMessage) => {
          if (response.error == true) {
            this.utilitiesService.showWarningToast(response.message, "Error!");
          } else if (response.error == false) {
            //load user data
            //this.clearData();
            this.utilitiesService.showSuccessToast(response.message, "Éxito!");
            this.socialRegisterForm.enable();
            this.showBtnLoading = false;
            this.dataSessionService.setUserData(response.data.user);

            this.closeSuccessModal();
          }
        }, (error) => {
          console.log(error);
          this.utilitiesService.showWarningToast(error.message, "Error!");
          this.socialRegisterForm.enable();
          this.showBtnLoading = false;
        });
      }
    }, (error) => {
      console.log(error);
      this.utilitiesService.showWarningToast(error.message, "Error!");
      this.socialRegisterForm.enable();
      this.showBtnLoading = false;
    });
  }

  get fullName(): AbstractControl {
    return this.socialRegisterForm.get('fullName');
  }
  get userRegisterName(): AbstractControl {
    return this.socialRegisterForm.get('userRegisterName');
  }
  get email(): AbstractControl {
    return this.socialRegisterForm.get('email');
  }
  get idState(): AbstractControl {
    return this.socialRegisterForm.get('idState');
  }
  get idTown(): AbstractControl {
    return this.socialRegisterForm.get('idTown');
  }
  get passwordRegister(): AbstractControl {
    return this.socialRegisterForm.get('passwordRegister');
  }
  get photoSelected(): AbstractControl {
    return this.socialRegisterForm.get('photoSelected');
  }
  get confirmPassRegister(): AbstractControl {
    return this.socialRegisterForm.get('confirmPassRegister');
  }
  get acceptTerms(): AbstractControl {
    return this.socialRegisterForm.get('acceptTerms');
  }
  get provider(): AbstractControl {
    return this.socialRegisterForm.get('provider');
  }

  onStateSelect(itemState: State) {
    this.idState.setValue(itemState.idState);

    this.townsData = this.statesData.find((state: State) => {
      return state.idState == itemState.idState;
    }).towns;
    this.selectedTown = [];
    this.idTown.setValue(-1);
  }

  onStateDeSelect(items: any) {
    this.idState.setValue(-1);
    this.idTown.setValue(-1);

    this.selectedState = [];
    this.townsData = [];
    this.selectedTown = [];
  }

  onTownSelect(itemTown: Town) {
    this.idTown.setValue(itemTown.idTown);
  }

  onTownDeSelect(items: any) {
    this.idTown.setValue(-1);
    this.selectedTown = [];
  }

  openModalRedirect() {
    this.alreadyRedirectOpen = true;
    this.modalService.open(this.modalRedirectPartners, {
      ariaLabelledBy: 'modal-basic-title', centered: true, size: 'md'
    }).result.then((result) => {
      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

      this.modalService.dismissAll({ result: "login-success" });
      this.signOut();
    });
  }

  redirectUser() {
    if (this.dataSessionService.user.idRol == 1000) {                                                    //Agent
      console.log("es un agente");
      this.dataSessionService.navigateByUrl("/dashboard-agent/home");
    } else if (this.dataSessionService.user.idRol == 400) {                                                //Normal
      /* console.log("es normal");
      this.dataSessionService.navigateByUrl("/"); */
    } else if (this.dataSessionService.user.idRol == 310 || this.dataSessionService.user.idRol == 300) { //Event Hall
      console.log("es salon");
      this.dataSessionService.navigateByUrl("/dashboard-event-hall/home");
    } else if (this.dataSessionService.user.idRol == 210 || this.dataSessionService.user.idRol == 200) { //Band
      console.log("es banda");
      this.dataSessionService.navigateByUrl("/dashboard-band/home");
    } else if (this.dataSessionService.user.idRol == 100) {                                              //Admin
      console.log("es admin");
      this.dataSessionService.navigateByUrl("/dashboard-admin/home");
    } else {
      this.utilitiesService.showErrorToast("Usuario desconocido.", "Error!");
      this.dataSessionService.logOut();
    }
    this.modalService.dismissAll({ result: "login-success" });
    this.signOut();
  }
}
