import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiDataService } from '../../../services/apiData/api-data.service';
import { DataSessionService } from '../../../services/dataSession/data-session.service';
import { UtilitiesService } from '../../../services/utilities/utilities.service';
import { ServerMessage } from '../../../classes/serverMessage.class';

import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Options } from '../../../classes/imageCompressorOptions.class';

@Component({
  selector: 'app-slider-sender',
  templateUrl: './slider-sender.component.html',
  styleUrls: ['./slider-sender.component.scss']
})
export class SliderSenderComponent implements OnInit {
  selectedFiles : File[];
  progressInfos: any[] = [];
  messageErrors: {
    message: string,
    error: boolean,
    loading: boolean
  }[] = [];
  filesSourceToUpload: string[] = [];

  //fileInfos?: Observable<any>;

  actualNumberImages;
  idServiceProfile;

  resultUploadSuccess: boolean;

  aceptTerms: boolean;

  // Emit an event when a file has been picked. Here we return the file itself
  @Output() onChange: EventEmitter<File> = new EventEmitter<File>();

  constructor(private modalService: NgbModal, private apiDataService: ApiDataService, public dataSessionService: DataSessionService,
    public utilitiesService: UtilitiesService) {
    this.resultUploadSuccess = false;
  }

  async ngOnInit() {
    this.cancelImage();
    this.resultUploadSuccess = false;

    /*  await this.utilitiesService.sleep(500);
     this.actualSimulateProgress = 20;
     await this.utilitiesService.sleep(500);
 
     this.actualSimulateProgress = 30;
     await this.utilitiesService.sleep(300);
 
     this.actualSimulateProgress = 50; */
  }

  closeCancelModal() {
    this.cancelImage();
    this.modalService.dismissAll({ result: "cancel" });
  }

  async closeSuccessModal() {
    this.cancelImage();
    this.modalService.dismissAll({ result: "file-sliders-uploaded" });
  }

  deleteActualSavedSpecificImage(imageIndex: number) {
    for (var i = 0; i < this.filesSourceToUpload.length; i++) {
      if (i == imageIndex) {
        let deletedElement: any[] = this.selectedFiles.splice(i, 1);
        let deletedElementSource: any[] = this.filesSourceToUpload.splice(i, 1);
        let deletedElementInfos: any[] = this.progressInfos.splice(i, 1);
        let deletedElementErrors: any[] = this.messageErrors.splice(i, 1);
      }
    }
  }

  // If the input has changed(file picked) we project the file into the img previewer
  selectFiles(event): void {
    //this.messageErrors = [];
    //this.progressInfos = [];
    //this.filesSourceToUpload = [];
    //this.selectedFiles = null;// event.target.files;
    //this.resultUploadSuccess = false;

    let realLimit = 0;

    if (this.dataSessionService.user.idRol == 210 || this.dataSessionService.user.idRol == 310) {
      realLimit = 200 - this.actualNumberImages;
    } else if (this.dataSessionService.user.idRol == 200 || this.dataSessionService.user.idRol == 300) {
      realLimit = 5 - this.actualNumberImages;
    }

    if ( realLimit > 0 && realLimit < ( event.target.files.length + this.selectedFiles.length )) {
      this.utilitiesService.showWarningToast("El numero de archivos seleccionados excede el limite disponible!", "");
      alert("El numero de archivos seleccionados excede el limite disponible");
    } else {
      for (let index = 0; index < event.target.files.length; index++) {
        let element = event.target.files[index];

        // We access he file with $event.target['files'][0]
        if (element.size > (1048576 * 20)) {
          alert("El archivo " + index + " es muy grande!");
          this.utilitiesService.showErrorToast("El archivo es muy grande!", "");
        } else {
          //this.fileToUpload = $event.target['files'][0];
          let file = element;

          const options: Options = {
            maxSizeMB: 1,
            useWebWorker: true,
            initialQuality: 0.8
          }

          this.utilitiesService.imageFileCompression(file, options).then((compressedFile) => {
            let reader = new FileReader;
            // TODO: Define type of 'e'
            reader.onload = (e: any) => {
              this.selectedFiles.push(compressedFile);
              // Simply set e.target.result as our <img> src in the layout
              this.filesSourceToUpload.push(e.target.result);
              this.messageErrors.push({
                message: "",
                error: false,
                loading: false
              });
              this.onChange.emit(compressedFile);
            };
            // This will process our file and get it's attributes/data
            reader.readAsDataURL(compressedFile);

            //this.progressInfos[index] = { value: 0, fileName: file.name };
            this.progressInfos.push({ value: 0, fileName: file.name });
          }, (error) => {
            console.log(error);
            this.utilitiesService.showErrorToast("A ocurrido un error comprimiendo la imagen", "Error");
          });
        };
      }
    }
  }

  async uploadFiles() {
    if (this.selectedFiles != null) {
      this.resultUploadSuccess = false;
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.messageErrors[i] = {
          message: "",
          error: false,
          loading: true
        };
        await this.upload(i, this.selectedFiles[i], this.actualNumberImages + i);
      }
      this.resultUploadSuccess = true;
      this.dataSessionService.checkProfileActiveData();
    }
  }

  cancelImage() {
    this.aceptTerms = false;
    this.messageErrors = [];
    this.progressInfos = [];
    this.filesSourceToUpload = [];
    this.selectedFiles = [];
    this.resultUploadSuccess = false;
  }

  async upload(idx: number, file: File, position: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.progressInfos[idx] = { value: 0, fileName: file.name };

      if (file) {
        if ( this.dataSessionService.profileActivated ) {
          if (this.idServiceProfile != -1) {
            this.apiDataService.uploadSlideImageServiceProfile(file, this.idServiceProfile, position).subscribe(
              (event: any) => {
                if (event.type === HttpEventType.UploadProgress) {
                  this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
                } else if (event instanceof HttpResponse) {
                  const msg = 'Uploaded the file successfully: ' + file.name;
                  //this.message.push(msg);
                  this.messageErrors[idx] = {
                    message: msg,
                    error: false,
                    loading: false
                  };
                  //this.fileInfos = this.uploadService.getFiles();
                }
                resolve();
              },
              (err: any) => {
                this.progressInfos[idx].value = 0;
                const msg = 'Could not upload the file: ' + file.name;
                this.messageErrors[idx] = {
                  message: msg,
                  error: true,
                  loading: false
                };
                resolve();
                //this.fileInfos = this.uploadService.getFiles();
              });
          } else {
            console.log("TO DO falta abrir la url para las imagenes de la banda");
            resolve();
          }
        }
      }
    });
  }
}
