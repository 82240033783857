<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<span class="set-dashboard-style-user">
    <div id="main-wrapper" data-layout="vertical" data-sidebartype="full" [ngClass]="{'show-sidebar': showMobileMenu}"
        [dir]="options.dir" [attr.data-theme]="options.theme" [attr.data-layout]="options.layout"
        [attr.data-sidebartype]="options.sidebartype" [attr.data-sidebar-position]="options.sidebarpos"
        [attr.data-header-position]="options.headerpos" [attr.data-boxed-layout]="options.boxed">
        <!-- ============================================================== -->
        <!-- Topbar header - style you can find in topbar.scss -->
        <!-- ============================================================== -->
        <header class="topbar" [attr.data-navbarbg]="'skin6'" style="height: 80px;">
            <!-- <a (click)="showMobileMenu = !showMobileMenu;" 
            class="nav-toggler waves-effect waves-light d-block d-md-none  full-centered" 
            href="javascript:void(0)" style="height: 80px; display: flex !important;">
            <i [ngClass]="(showMobileMenu)?'fa-solid fa-rectangle-xmark':'fa-solid fa-bars'"></i>
        </a> -->
            <app-navigation-public [searchClosed]="false" (toggleSidebar)="toggleSidebarType()"></app-navigation-public>
        </header>
        <!-- ============================================================== -->
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- <aside [attr.data-sidebarbg]="options.sidebarbg" (mouseout)="showMobileMenu = false;" (mouseover)="showMobileMenu = true;"
        [class]="'left-sidebar '+(showMobileMenu ? 'show-sidebar-final-user': 'hide-sidebar-final-user') " > -->

        <aside *ngIf="dataSessionService.user.idUser != -1" [attr.data-sidebarbg]="options.sidebarbg"
            [class]="'left-sidebar '+(showMobileMenu ? 'show-sidebar-final-user': 'hide-sidebar-final-user') ">
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar" [perfectScrollbar]="config" style="height: auto;">
                <a (click)="showMobileMenu = !showMobileMenu;"
                    class="nav-toggler waves-effect waves-light d-block full-centered no-display-menu-final-user"
                    href="javascript:void(0)" style="height: 80px;">
                    <div class="row full-width">
                        <div class="col-8 full-left hide-menu">
                            <h3 class="pa-l-20">
                                <strong>
                                    Mi cuenta
                                </strong>
                            </h3>
                        </div>
                        <div class="col full-centered">
                            <span *ngIf="showMobileMenu == false" class="fs-xxl">
                                <i class="fa-solid fa-bars"></i>
                            </span>
                            <i *ngIf="showMobileMenu == true" class="fa-solid fa-rectangle-xmark"></i>
                        </div>
                    </div>
                </a>
                <app-sidebar-final-user (click)="toggleSidebarType()" (mouseout)="showMobileMenu = false;" (mouseover)="showMobileMenu = true;">
                </app-sidebar-final-user>
            </div>
            <!-- End Sidebar scroll-->
        </aside>
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="content">
            <div class="page-wrapper" style="padding-top: 0px;" [style.margin-left]="dataSessionService.user.idUser != -1 ? '' : '0px'">
                <!-- <app-breadcrumb></app-breadcrumb> -->
                <!-- ============================================================== -->
                <!-- Container fluid  -->
                <!-- ============================================================== -->
                <div class="container-fluid no-padding bg-page-body preregister-box" style="min-height: calc(100vh - 144px);">
                    <!-- ============================================================== -->
                    <!-- Start Page Content -->
                    <!-- ============================================================== -->
                    <router-outlet></router-outlet>
                    <!-- ============================================================== -->
                    <!-- End Start Page Content -->
                    <!-- ============================================================== -->
                </div>
                <!-- ============================================================== -->
                <!-- End Container fluid  -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- footer -->
                <!-- ============================================================== -->
                <!-- <footer class="footer text-center">
            All Rights Reserved by Enbibo.com.
        </footer> -->
                <app-footer-public class="stays-bottom"></app-footer-public>
                <!-- ============================================================== -->
                <!-- End footer -->
                <!-- ============================================================== -->
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
        <div class="chat-windows"></div>
    </div>
</span>