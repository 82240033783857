import { ApiDataService } from './../apiData/api-data.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { ServerMessage } from '../../classes/serverMessage.class';

@Injectable({
  providedIn: 'root'
})
export class ApiUserDataService {

  constructor(private http: HttpClient, private sanitizer: DomSanitizer,private apiDataService : ApiDataService) {}

  createUser(username: string, email : String, password : String) : Promise<ServerMessage>{
    return new Promise((resolve,reject)=>{
      const data = {
        username : username,
        email : email , 
        password : password
      };

      this.http.post<ServerMessage>(this.apiDataService.baseURL + 'user/create-normal-user',data,{}).subscribe((response : ServerMessage)=>{
        resolve(response);
      },(error)=>{
        reject(error)
      });
    })
  }
}
