import { RouteInfo } from './sidebar-admin.metadata';

export const ADMINMENU: RouteInfo[] = [
    {
        path: '/dashboard-admin/home',
        title: 'Inicio',
        icon: 'fas fa-home',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard-admin/messages',
        title: 'Mensajes',
        icon: 'fas fa-comments',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard-admin/products/product',
        title: 'Catálogos',
        icon: 'fas fa-clipboard-list',
        class: 'has-arrow',
        extralink: false,
        submenu: [
            {
                path: '/dashboard-admin/promotions',
                title: 'Promociones',
                icon: ' fas fa-barcode',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },
            {
                path: '/dashboard-admin/event-types',
                title: 'Tipos de Evento',
                icon: ' fas fa-compact-disc',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },
            {
                path: '/dashboard-admin/instruments',
                title: 'Instrumentos',
                icon: ' fas fa-guitar',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },
            {
                path: '/dashboard-admin/musical-genres',
                title: 'Géneros Musicales',
                icon: ' fas fa-music',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },
            {
                path: '/dashboard-admin/states-and-cities',
                title: 'Ciudades y Estados',
                icon: 'fas fa-globe-americas',//<i class="fas fa-globe-americas"></i>
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },
            /* {
                path: '/dashboard-admin/products/categorys',
                title: 'Métodos de Envió',
                icon: 'icon-Car-Wheel',
                class: '',
                extralink: false,
                submenu: []
            }, */
        ]
    },
    {
        path: '/dashboard-admin/orders',
        title: 'Usuarios',
        icon: 'fas fa-users ',
        class: 'has-arrow',
        extralink: false,
        submenu: [{
                path: '/dashboard-admin/normal-users',
                title: 'Normales',
                icon: 'fas fa-user',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },{
                path: '/dashboard-admin/band-users',
                title: 'Músicos',
                icon: 'fas fa-user-tie',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },{
                path: '/dashboard-admin/event-hall-users',
                title: 'Salones',
                icon: 'fas fa-user-tie',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },{
                path: '/dashboard-admin/agent-users',
                title: 'Agentes',
                icon: 'fas fa-user-secret',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },
        ]
    },
    {
        path: '/dashboard-admin/orders',
        title: 'Administrador',
        icon: ' fas fa-tasks',
        class: 'has-arrow',
        extralink: false,
        submenu: [{
                path: '/dashboard-admin/pending-payments',
                title: 'Pagos',
                icon: 'fas fa-receipt',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },{
                path: '/dashboard-admin/invoices',
                title: 'Facturas',
                icon: 'fas fa-file-invoice',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },{
                path: '/dashboard-admin/images-pending-approval',
                title: 'Imágenes',
                icon: 'fas fa-clipboard-check',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },{
                path: '/dashboard-admin/comments',
                title: 'Comentarios',
                icon: 'fas fa-comment-dots',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },{
                path: '/dashboard-admin/reviews',
                title: 'Reseñas',
                icon: 'fas fa-star-half-alt',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },/* {
                path: '/dashboard-admin/categorys/mens',
                title: 'Comisiones',
                icon: ' fas fa-share-square',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            }, */
        ]
    },
    {
        path: '/dashboard-admin/orders',
        title: 'Publicidad',
        icon: 'far fa-window-restore    ',
        class: 'has-arrow',
        extralink: false,
        submenu: [{
                path: '/dashboard-admin/sliders-home',
                title: 'Sliders Home',
                icon: 'fas fa-images',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },{
                path: '/dashboard-admin/banners',
                title: 'Banners',
                icon: 'far fa-window-restore'/* 'fas fa-user-plus' */,
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },{
                path: '/dashboard-admin/ads-codes',
                title: 'AdSense',
                icon: 'fas fa-code',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },
        ]
    },
    {
        path: '/dashboard-admin/account-setting',
        title: 'Ajustes de la cuenta',
        icon: 'fas fa-cogs',
        class: '',
        extralink: false,
        submenu: []
    },
/*     {
        path: '/apps/email',
        title: 'Email',
        icon: 'icon-Mailbox-Empty',
        class: '',
        extralink: false,
        submenu: []
    }, */
];
