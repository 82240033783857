<nav class="sidebar-nav">
    <ul id="sidebarnav">
        <!-- User Profile-->
        <li style="height: 100px;">
            <!-- User Profile-->
            <div class="user-profile" ngbDropdown>
                <div class="user-pic hide-menu">
                    <span *ngIf="dataSessionService.user.idRol == 100">
                        <img src="assets/images/users/admin-user-image.jpg">
                    </span>
                    <span *ngIf="dataSessionService.user.idRol == 400">
                            <!-- TO DO  : si el usuario tiene imagen hay que mostrarla hide-menu-->
                        <i *ngIf="dataSessionService.user.photoSelected== 'DEFAULT'" style="font-size: 50px;"
                            class="fas fa-user-circle"></i>
                        <img *ngIf="dataSessionService.user.photoSelected== 'FACEBOOK'" class="rounded-circle img-fluid"
                            [src]="dataSessionService.user.facebookPhoto">
                        <img *ngIf="dataSessionService.user.photoSelected== 'GOOGLE'" class="rounded-circle img-fluid"
                            [src]="dataSessionService.user.googlePhoto">
                    </span>
                    <span
                        *ngIf="dataSessionService.user.idRol == 310 || dataSessionService.user.idRol == 300 || 
                        dataSessionService.user.idRol == 210 || dataSessionService.user.idRol == 200">
                        <i *ngIf='dataSessionService.profileLogo.length == 0'
                            class="fas fa-user-circle" style="font-size: 50px;"></i>
                        <img *ngIf='dataSessionService.profileLogo.length != 0'
                            style="width: 60px;height: 60px;"
                            [src]="dataSessionService.imageLogoFileImageBlob" alt="users"
                            class="rounded-circle img-fluid" />
                    </span>
                    <!-- <img src="assets/images/users/admin-user-image.jpg" alt="users" class="rounded-circle img-fluid" /> -->
                </div>
                <div class="user-content hide-menu m-t-10">
                    <h5 class="m-b-10 text-capitalize user-name font-medium">{{dataSessionService.user.name}}</h5>
                </div>
            </div>
            <!-- End User Profile-->
        </li>
        <!-- First level menu -->
        <!-- <li class="sidebar-item" [class.selected]="showMenu === sidebarnavItem.title" *ngFor="let sidebarnavItem of sidebarnavItems"
            [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'"> -->
        <li class="sidebar-item" 
            *ngFor="let sidebarnavItem of sidebarNavItemsAdmin" class="sidebar-item"
            [class.selected]="showMenu === sidebarnavItem.title && sidebarnavItem.submenu.length != 0"
            [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'selected active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">
                <i [ngClass]="[sidebarnavItem.icon]"></i>
                <span class="hide-menu ">{{sidebarnavItem.title}}</span>
            </div>
            <a class="sidebar-link waves-effect waves-dark" [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
                [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;" (click)="addExpandClass(sidebarnavItem.title)"
                [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
                <i [ngClass]="[sidebarnavItem.icon]" class="pa-icon"></i>
                <span class="hide-menu pa-l-10">{{sidebarnavItem.title}}</span>
            </a>
            <!-- Second level menu -->
            <ul aria-expanded="false" class="collapse ma-l-10p" *ngIf="sidebarnavItem.submenu.length > 0" [ngClass]="{'in' : showMenu === sidebarnavItem.title }">
                <li class="sidebar-item" *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu" [class.active]="showSubMenu === sidebarnavSubItem.title"
                    [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'selected active'">
                    <a class="sidebar-link" [routerLink]="sidebarnavSubItem.submenu.length > 0 ? null : [sidebarnavSubItem.path]" [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'router-link-active'"
                        [ngClass]="[sidebarnavSubItem.class]" *ngIf="!sidebarnavSubItem.extralink;" (click)="addActiveClass(sidebarnavSubItem.title)">
                        <i [ngClass]="[sidebarnavSubItem.icon]"></i>
                        <span class="hide-menu">{{sidebarnavSubItem.title}}</span>
                    </a>
                    <!-- Third level menu -->
                    <ul aria-expanded="false" class="collapse second-level" *ngIf="sidebarnavSubItem.submenu.length > 0" [ngClass]="{'in' : showSubMenu === sidebarnavSubItem.title }">
                        <li class="sidebar-item" *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.submenu" routerLinkActive="active" [ngClass]="[sidebarnavSubsubItem.class]">
                            <a class="sidebar-link" [routerLink]="[sidebarnavSubsubItem.path]" *ngIf="!sidebarnavSubsubItem.extralink;" [routerLinkActive]="sidebarnavSubsubItem.submenu.length > 0 ? '' : 'selected active'">
                                <i [ngClass]="[sidebarnavSubsubItem.icon]"></i>
                                <span class="hide-menu" style="color: #abafb9;">{{sidebarnavSubsubItem.title.toUpperCase()}}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
</nav>