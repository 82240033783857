import { Component, HostListener, AfterContentInit, Input, Output, EventEmitter } from '@angular/core';
import {
  NgbModal, NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DataSessionService } from '../../../services/dataSession/data-session.service';
import { EventType } from '../../../classes/eventType.class';
import { ApiDataService } from '../../../services/apiData/api-data.service';
import { UtilitiesService } from '../../../services/utilities/utilities.service';
import { ServerMessage } from '../../../classes/serverMessage.class';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ApiLiveChatDataService } from '../../../services/liveChat/api-live-chat-data.service';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { SearchQuery } from '../../../classes/searchQuerys.class';
import { MusicalGenre } from '../../../classes/musicalGenre.class';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-navigation-public',
  templateUrl: './navigation-public.component.html',
  styleUrls: ['./navigation-public.component.scss']
})
export class NavigationPublicComponent implements AfterContentInit {
  @Output() toggleSidebar = new EventEmitter<void>();
  @Input() searchClosed;

  config: PerfectScrollbarConfigInterface = {};

  isTop: boolean;
  openMenu: boolean;
  openCols: boolean;

  eventTypesData: EventType[];
  dropdownConfigEventTypes: IDropdownSettings = {
    singleSelection: false,
    maxHeight: 300,
    idField: 'idEventType',
    textField: 'name',
    searchPlaceholderText: 'Buscar por nombre',
    noDataAvailablePlaceholderText: 'Sin resultados',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    itemsShowLimit: 1,
    allowSearchFilter: true,
    clearSearchFilter: true,
    //limitSelection : 1,
    enableCheckAll: false,
    closeDropDownOnSelection: true,
  };
  
  genreTypesData: MusicalGenre[];
  dropdownConfigGenreTypes: IDropdownSettings = {
    singleSelection: false,
    maxHeight: 300,
    idField: 'idMusicalGenre',
    textField: 'name',
    searchPlaceholderText: 'Buscar por nombre',
    noDataAvailablePlaceholderText: 'Sin resultados',
    selectAllText: 'Seleccionar todo',
    unSelectAllText: 'Deseleccionar todo',
    itemsShowLimit: 1,
    allowSearchFilter: true,
    clearSearchFilter: true,
    //limitSelection : 1,
    enableCheckAll: false,
    closeDropDownOnSelection: true,
  };

  searchIncidents:  { idTown: number, idState?: number, name: string }[];

  constructor(public dataSessionService: DataSessionService, public apiLiveChatDataService: ApiLiveChatDataService,  private route: Router, 
      private modalService: NgbModal, private apiDataService: ApiDataService, public utilitiesService: UtilitiesService) {
    this.isTop = true;
    this.openMenu = true;
    this.openCols = true;
    this.eventTypesData = [];
    this.genreTypesData = [];
    this.searchIncidents = [];
    
    // check for Geolocation support
    /* if (navigator.geolocation) {
      console.log('Geolocation is supported!');
      navigator.geolocation.getCurrentPosition(function(position) {
        console.log(position);
      });
    } else {
      console.log('Geolocation is not supported for this Browser/OS.');
    } */
  }

  async ngAfterContentInit(){
    //console.log(this.searchClosed);
    this.apiDataService.getSearchBarData().then((result: ServerMessage) => {
      //console.log(result);
      if (result.error == true) {
        console.log(result);
        this.utilitiesService.showErrorToast(result.message, "Error");
      } else if (result.error == false) {
        this.eventTypesData = result.data.eventTypes;
        this.genreTypesData = result.data.genreTypes;
      }
    }).catch((error) => {
      console.log(error);
      this.utilitiesService.showErrorToast("Ups a ocurrido un error", "Error");
    });
    
    if (this.searchClosed == false) {
      if (this.isTop == false) {
        this.isTop = true;
      }
      document.querySelector('.navbar-register').classList.remove('fixed-shadow');
      this.openMenuNav();
    } else {
      if (this.isTop == true) {
        this.isTop = false;
      }
      await this.utilitiesService.sleep(50);
      document.querySelector('.navbar-register').classList.add('fixed-shadow');
      this.openMenuNav(false);
    }
  }

  @HostListener('window:scroll', [])
  async onWindowScroll() {
    if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
      
      if (this.isTop == true) {
        this.isTop = false;
      }
      document.querySelector('.navbar-register').classList.add('fixed-shadow');
      this.openMenuNav(false);
    } else {
      if (this.searchClosed == false) {

        if (this.isTop == false) {
          this.isTop = true;
        }
        await this.utilitiesService.sleep(50);
        document.querySelector('.navbar-register').classList.remove('fixed-shadow');
        this.openMenuNav();
      }
    }
  }

  async openMenuNav(opcMenu: boolean = true) {
    if (opcMenu) {
      if (!this.openMenu) {
        this.openMenu = opcMenu;
        await this.utilitiesService.sleep(50);
      } else {
        this.openMenu = opcMenu;
      }
      this.openCols = opcMenu;
    } else {
      this.openCols = opcMenu;
      this.openMenu = opcMenu;
    }
  }

  getPosibleLocations(event) {
    this.dataSessionService.queryParams.idTown = -1;
    this.dataSessionService.queryParams.idState = -1;
    //let ssearchValue = event.charAt(0).toLowerCase() + event.slice(1);
    let ssearchValue = event.toLowerCase();
    this.apiDataService.getPosibleLocations(ssearchValue).then((result: ServerMessage) => {
      if (result.error == true) {
        console.log(result);
        this.utilitiesService.showErrorToast(result.message, "Error");
      } else if (result.error == false) {
        this.searchIncidents = result.data.townsData;
      }
    }).catch((error) => {
      console.log(error);
      this.utilitiesService.showErrorToast("Ups! a ocurrido un error", "Error")
    });
  }

  selectLocation(locationSelected: { idTown: number, idState?: number, name: string }) {
    this.dataSessionService.queryParams.idTown = locationSelected.idTown;
    this.dataSessionService.queryParams.idState = locationSelected.idState;
    this.dataSessionService.queryParams.location = locationSelected.name;
    this.searchIncidents = [];
  }

  doSearch() {
    if (this.openMenu == true) {
      
      let queryParams : any = {};
      queryParams.searchType = this.dataSessionService.queryParams.searchType;
      queryParams.location = this.dataSessionService.queryParams.location;

      if (this.dataSessionService.queryParams.idTown != -1) {
        queryParams.idTown = this.dataSessionService.queryParams.idTown;
      }
      if (this.dataSessionService.queryParams.idState != -1) {
        queryParams.idState = this.dataSessionService.queryParams.idState;
      }
      if (this.dataSessionService.queryParams.events.length > 0) {
        queryParams.events = this.dataSessionService.queryParams.events.map(event =>{
          return event.idEventType
        }).toString();
      }
      if (this.dataSessionService.queryParams.genres.length > 0) {
        queryParams.genders = this.dataSessionService.queryParams.genres.map(event =>{
          return event.idMusicalGenre;
        }).toString();
      }
      if (this.dataSessionService.queryParams.persons) {
        queryParams.persons = this.dataSessionService.queryParams.persons;
      }
      if (this.dataSessionService.queryParams.limitPrice) {
        queryParams.limitPrice = this.dataSessionService.queryParams.limitPrice;
      }
      this.route.navigate(['/resultados-busqueda'], { queryParams: queryParams });
    } else {
      this.openMenuNav();
    }
  }
  goToDashboards() {
    if (this.dataSessionService.user.idRol == 1000) {                                                    //Agent
      this.dataSessionService.navigateByUrl("/dashboard-agent/home");
    } else if (this.dataSessionService.user.idRol == 400) {                                                //Normal
      this.dataSessionService.navigateByUrl("/");
    } else if (this.dataSessionService.user.idRol == 310 || this.dataSessionService.user.idRol == 300) { //Event Hall
      this.dataSessionService.navigateByUrl("/dashboard-event-hall/home");
    } else if (this.dataSessionService.user.idRol == 210 || this.dataSessionService.user.idRol == 200) { //Band
      this.dataSessionService.navigateByUrl("/dashboard-band/home");
    } else if (this.dataSessionService.user.idRol == 100) {                                              //Admin
      this.dataSessionService.navigateByUrl("/dashboard-admin/home");
    } else {
      this.utilitiesService.showErrorToast("Usuario desconocido.", "Error!");
      this.logOut();
    }
  }
  /* alreadyFavOpen : boolean = false; */
  openDropDownFavorites(myDropFavs){
    this.dataSessionService.getFavorites();
    /* if(this.alreadyFavOpen == true){ 
      this.alreadyFavOpen=true;
      myDropFavs.open();
    } */
    myDropFavs.open();
  }
  openDropDownRequests(myDropRequest){
    this.dataSessionService.getActiveRequests();
    /* if(this.alreadyFavOpen == true){ 
      this.alreadyFavOpen=true;
      myDropFavs.open();
    } */
    myDropRequest.open();
  }
  async openLoginModal(isLogin : boolean) {
    let modalLogin : NgbModalRef = await this.modalService.open(
      LoginModalComponent, {
        ariaLabelledBy: 'modal-basic-title', 
        centered: true, 
        size : 'xl',
        windowClass :'full-window-login-modal'
    });

    modalLogin.componentInstance.isLogin = isLogin;

    modalLogin.result.then((result) => {
      //console.log("result");
      //console.log(result);
    }, (reason) => {
      //console.log("reason");
      //console.log(reason);
      if(reason.result == "login-success"){
        //to do
        //this.dataSessionService.checkProfileActiveData();
        //this.loadProfileData();
      }
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  logOut() {
    this.dataSessionService.logOut(true);
    this.apiLiveChatDataService.disconnect();
  }
}
