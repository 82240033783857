import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DataSessionService } from '../../../services/dataSession/data-session.service';
import { UtilitiesService } from '../../../services/utilities/utilities.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoginModalComponent } from '../login-modal/login-modal.component';
import { ApiDataService } from '../../../services/apiData/api-data.service';
import { ServerMessage } from '../../../classes/serverMessage.class';
import { ProfileReview, ProfileImagesReview } from '../../../classes/serviceProfile.class';

import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Options } from '../../../classes/imageCompressorOptions.class';

@Component({
  selector: 'app-user-review',
  templateUrl: './user-review.component.html',
  styleUrls: ['./user-review.component.scss']
})
export class UserReviewComponent implements OnInit {
  @Input() idProfile: number;
  @Input() profileName: number;
  @Input() type: number;

  actualReview: ProfileReview;

  /* Variables for upload images */
  selectedFiles?: any[];
  progressInfos: any[] = [];
  messageErrors: {
    message: string,
    error: boolean,
    loading: boolean
  }[] = [];
  filesSourceToUpload: string[] = [];
  imagesToDelete: ProfileImagesReview[];

  //fileInfos?: Observable<any>;

  limit = 10;

  resultUploadSuccess: boolean;

  // Emit an event when a file has been picked. Here we return the file itself
  @Output() onChange: EventEmitter<File> = new EventEmitter<File>();

  constructor(public utilitiesService: UtilitiesService, public dataSessionService: DataSessionService,
    private modalService: NgbModal, private apiDataService: ApiDataService, public changeDetectorRef: ChangeDetectorRef) {
    this.actualReview = new ProfileReview();
    this.resultUploadSuccess = false;
  }

  ngOnInit(): void {
    this.actualReview = new ProfileReview();
    this.cancelImages();
    this.resultUploadSuccess = false;
    this.imagesToDelete = [];

    if (this.dataSessionService.user.idUser != -1) {
      this.apiDataService.getUserServiceProfileReview(this.idProfile).then(async (response: ServerMessage) => {
        if (response.error == true) {
          console.log(response);
          this.utilitiesService.showErrorToast(response.message, "Error");
          this.utilitiesService.goToLink('/');
        } else if (response.error == false) {
          if (response.data.haveCalificación != null && response.data.haveCalificación != undefined) {
            if (response.data.haveCalificación == true) {
              //console.log("con calificacion");

              response.data.serviceProfileReview.updatedAt = new Date(response.data.serviceProfileReview.updatedAt);
              response.data.serviceProfileReview.createdAt = new Date(response.data.serviceProfileReview.createdAt);

              for (let index = 0; index < this.actualReview.imagesReview.length; index++) {
                response.data.serviceProfileReview.imagesReview[index].updatedAt =
                  new Date(response.data.serviceProfileReview.imagesReview[index].updatedAt);
                response.data.serviceProfileReview.imagesReview[index].createdAt =
                  new Date(response.data.serviceProfileReview.imagesReview[index].createdAt);
              }
              this.actualReview = response.data.serviceProfileReview;

            } else if (response.data.haveCalificación == false) {
              //console.log("sin calificacion");
              this.actualReview = new ProfileReview();
              this.actualReview.idServiceProfile = this.idProfile;
              this.actualReview.idUser = this.dataSessionService.user.idUser;
            }
            //console.log(this.actualReview);
          }
        }
      });
    } else {

    }
  }
  /* START Images functions */

  cancelImages() {
    this.messageErrors = [];
    this.progressInfos = [];
    this.filesSourceToUpload = [];
    this.selectedFiles = [];
    this.resultUploadSuccess = false;
  }

  // If the input has changed(file picked) we project the file into the img previewer
  selectFiles(event): void {
    this.resultUploadSuccess = false;

    if ((this.limit) < (event.target.files.length + this.selectedFiles.length + this.actualReview.imagesReview.length)) {
      //this.selectedFiles = [];

      this.utilitiesService.showWarningToast("El numero de archivos seleccionados excede el limite disponible!", "");
      alert("El numero de archivos seleccionados excede el limite disponible");
    } else {
      for (let index = 0; index < event.target.files.length; index++) {
        const element = event.target.files[index];

        //(this.selectedFiles as any[]).push(element);
        // We access he file with $event.target['files'][0]
        if (element.size > (1048576 * 4)) {
          alert("El archivo " + index + " es muy grande!");
          this.utilitiesService.showErrorToast("El archivo es muy grande!", "");
        } else {
          //this.fileToUpload = $event.target['files'][0];
          let file = element;

          const options: Options = {
            maxSizeMB: 1,
            useWebWorker: true,
            initialQuality: 0.8
          }

          this.utilitiesService.imageFileCompression(file, options).then((compressedFile) => {
            let reader = new FileReader;
            // TODO: Define type of 'e'
            reader.onload = (e: any) => {
              this.selectedFiles.push(compressedFile);
              // Simply set e.target.result as our <img> src in the layout
              this.filesSourceToUpload.push(e.target.result);
              this.messageErrors.push({
                message: "",
                error: false,
                loading: false
              });
              this.onChange.emit(compressedFile);
            };
            // This will process our file and get it's attributes/data
            reader.readAsDataURL(compressedFile);

            this.progressInfos[index] = { value: 0, fileName: file.name };
          }, (error) => {
            console.log(error);
            this.utilitiesService.showErrorToast("A ocurrido un error comprimiendo la imagen", "Error");
          });

          /* let reader = new FileReader;
          // TODO: Define type of 'e'
          reader.onload = (e: any) => {
            // Simply set e.target.result as our <img> src in the layout
            this.filesSourceToUpload.push(e.target.result);
            this.messageErrors.push({
              message: "",
              error: false,
              loading: false
            });
            //this.showPagesNumber = true;
            this.onChange.emit(file);
            //this.filesToUpload.push( file );
          };
          // This will process our file and get it's attributes/data
          reader.readAsDataURL(file);

          this.progressInfos.push({ value: 0, fileName: file.name }); */
        };
      }
    }
  }

  deleteSpecificImage(imageIndex: number) {
    for (var i = 0; i < this.selectedFiles.length; i++) {
      if (i == imageIndex) {
        this.messageErrors.splice(i, 1);
        this.progressInfos.splice(i, 1);
        this.filesSourceToUpload.splice(i, 1);
        this.selectedFiles.splice(i, 1);
      }
    }
  }

  deleteActualSavedSpecificImage(imageIndex: number) {
    for (var i = 0; i < this.actualReview.imagesReview.length; i++) {
      if (i == imageIndex) {
        let deletedElement: ProfileImagesReview[] = this.actualReview.imagesReview.splice(i, 1);
        this.imagesToDelete = [...this.imagesToDelete, ...deletedElement];
      }
    }
  }

  showLoadingUpload: boolean = false;

  async uploadNewReviewAndFiles() {
    if (this.selectedFiles.length != 0) {
      this.resultUploadSuccess = false;
      this.showLoadingUpload = true;

      this.apiDataService.updateUserProfileReview({
        numberImages: this.selectedFiles.length,
        review: this.actualReview,
        imagesToDelete: this.imagesToDelete,
      }).then(async (response: ServerMessage) => {
        //console.log(response);
        if (response.error == true) {
          console.log(response);
          this.utilitiesService.showErrorToast(response.message, "Error");
          this.resultUploadSuccess = false;
          this.showLoadingUpload = false;
        } else if (response.error == false) {
          //this.utilitiesService.showSuccessToast(response.message, "Exito")
          response.data.newActualReview;
          response.data.newImagesIds;

          if (this.selectedFiles.length == response.data.newImagesIds.length) {
            let newFilesFixed: any[] = [];

            for (let i = 0; i < response.data.newImagesIds.length; i++) {
              this.messageErrors[i] = {
                message: "",
                error: false,
                loading: true
              };
              let fileToUpload: File = new File(
                [this.selectedFiles[i]],
                response.data.newActualReview.idServiceProfile + '-' +
                response.data.newImagesIds[i].idProfileReview + '-' +
                response.data.newImagesIds[i].idProfileImagesReview + '.jpg',
                {
                  type: this.selectedFiles[i].type,
                  lastModified: this.selectedFiles[i].lastModified,
                });
              newFilesFixed.push(fileToUpload);
            }
            for (let i = 0; i < response.data.newImagesIds.length; i++) {
              //console.log(fileToUpload);
              await this.upload(i, newFilesFixed[i],/* this.limit +  */i, response.data.newImagesIds[i]);
            }
            this.resultUploadSuccess = true;
            this.showLoadingUpload = false;
          }
        }
      });
    }
  }

  async uploadUpdatedReviewAndFiles() {
    if ((this.selectedFiles.length + this.actualReview.imagesReview.length) != 0) {
      let haveOneAprovedImage = false;

      for (let index = 0; index < this.actualReview.imagesReview.length; index++) {
        const element = this.actualReview.imagesReview[index];
        if (element.approved == true) {
          haveOneAprovedImage = true;
          index = this.actualReview.imagesReview.length;
        }
      }
      if (this.selectedFiles.length > 0) {
        haveOneAprovedImage = true;
      }

      if (haveOneAprovedImage == false) {
        this.utilitiesService.showInfoToast("Debes de tener por lo menos una imagen nueva o autorizada");
      } else {

        this.resultUploadSuccess = false;
        this.showLoadingUpload = true;

        for (let i = 0; i < this.selectedFiles.length; i++) {
          this.messageErrors[i] = {
            message: "",
            error: false,
            loading: true
          };
        }
        /* console.log({
          numberImages: this.selectedFiles.length,
          review: this.actualReview,
          imagesToDelete: this.imagesToDelete,
        }); */

        this.apiDataService.updateUserProfileReview({ 
          numberImages : this.selectedFiles.length , 
          review : this.actualReview ,
          imagesToDelete : this.imagesToDelete,
        }).then(async (response: ServerMessage) => {
          //console.log(response);
          if (response.error == true) {
            console.log(response);
            this.utilitiesService.showErrorToast(response.message, "Error");
            this.resultUploadSuccess = false;
            this.showLoadingUpload = false;
          } else if (response.error == false) {
            //this.utilitiesService.showSuccessToast(response.message, "Exito")
            response.data.newActualReview;
            response.data.newImagesIds;
            
            if( this.selectedFiles.length == response.data.newImagesIds.length ){
              let newFilesFixed : any[] = [];

              for( let i = 0; i < response.data.newImagesIds.length ; i++ ) {
                this.messageErrors[i] ={ 
                  message : "" , 
                  error : false,
                  loading : true
                };
                let fileToUpload : File = new File(
                  [this.selectedFiles[i]], 
                  response.data.newActualReview.idServiceProfile + '-' +
                  response.data.newImagesIds[i].idProfileReview + '-' +
                  response.data.newImagesIds[i].idProfileImagesReview + '.jpg', 
                  {
                      type: this.selectedFiles[i].type,
                      lastModified: this.selectedFiles[i].lastModified,
                });
                newFilesFixed.push(fileToUpload);
              }
              for( let i = 0; i < response.data.newImagesIds.length ; i++ ) {
                //console.log(fileToUpload);
                await this.upload(i, newFilesFixed[i],i,response.data.newImagesIds[i]);
              }
              this.resultUploadSuccess = true;
              this.showLoadingUpload = false;
            }
          }
        });    
      }
    }
  }

  async upload(idx: number, file: File, position: number, newImagesData: ProfileImagesReview): Promise<any> {
    return new Promise((resolve, reject) => {
      this.progressInfos[idx] = { value: 0, fileName: file.name };
      if (file) {
        if (this.idProfile != -1) {
          this.apiDataService.uploadUserServiceProfileReviewImage(file, newImagesData).subscribe((event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
            } else if (event instanceof HttpResponse) {
              const msg = 'Uploaded the file successfully: ' + file.name;
              //this.message.push(msg);
              this.messageErrors[idx] = {
                message: msg,
                error: false,
                loading: false
              };
              //this.fileInfos = this.uploadService.getFiles();
              resolve();
            }
          }, (err: any) => {
            this.progressInfos[idx].value = 0;
            const msg = 'Could not upload the file: ' + file.name;
            this.messageErrors[idx] = {
              message: msg,
              error: true,
              loading: false
            };
            resolve();
            //this.fileInfos = this.uploadService.getFiles();
          });
        } else {
          console.log("TO DO falta abrir la url para las imagenes de la banda");
          resolve();
        }
      }
    });
  }

  async closeSuccessModal() {
    this.cancelImages();
    this.modalService.dismissAll({ result: "file-sliders-uploaded" });
  }
  /* END Images functions */

  minusQualityOfService() {
    if (this.actualReview.qualityOfService > 0) {
      this.actualReview.qualityOfService--;
    }
  }
  plusQualityOfService() {
    if (this.actualReview.qualityOfService < 5) {
      this.actualReview.qualityOfService++;
    }
  }

  minusResponseTime() {
    if (this.actualReview.responseTime > 0) {
      this.actualReview.responseTime--;
    }
  }
  plusResponseTime() {
    if (this.actualReview.responseTime < 5) {
      this.actualReview.responseTime++;
    }
  }

  minusProfessionalism() {
    if (this.actualReview.professionalism > 0) {
      this.actualReview.professionalism--;
    }
  }
  plusProfessionalism() {
    if (this.actualReview.professionalism < 5) {
      this.actualReview.professionalism++;
    }
  }

  minusPriceQualityRatio() {
    if (this.actualReview.priceQualityRatio > 0) {
      this.actualReview.priceQualityRatio--;
    }
  }
  plusPriceQualityRatio() {
    if (this.actualReview.priceQualityRatio < 5) {
      this.actualReview.priceQualityRatio++;
    }
  }

  minusFlexibility() {
    if (this.actualReview.flexibility > 0) {
      this.actualReview.flexibility--;
    }
  }
  plusFlexibility() {
    if (this.actualReview.flexibility < 5) {
      this.actualReview.flexibility++;
    }
  }

  minusCommunication() {
    if (this.actualReview.communication > 0) {
      this.actualReview.communication--;
    }
  }
  plusCommunication() {
    if (this.actualReview.communication < 5) {
      this.actualReview.communication++;
    }
  }

  dismissCancel() {
    this.cancelImages();
    this.modalService.dismissAll({ result: "cancel" });
  }

  async openLoginModal(isLogin: boolean) {
    let modalLogin: NgbModalRef = await this.modalService.open(
      LoginModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'xl',
      windowClass: 'full-window-login-modal'
    });

    modalLogin.componentInstance.isLogin = isLogin;

    modalLogin.result.then((result) => {
      //console.log("result");
      //console.log(result);
    }, async (reason) => {
      //console.log(reason);
      if (reason.result == "login-success") {
        /* this.loadProfileData(); */

        /* this.userForContact = JSON.parse(JSON.stringify(this.dataSessionService.user));
        await this.utilitiesService.sleep(1000);
        this.getSessionData(); */
      }
    });
  }
  @ViewChild("modalSeeFullSlider") modalSeeFullSlider;
  active: string;

  openModalFullGallerySliders(i) {
    this.changeDetectorRef.detectChanges();
    this.active = /* 'slide-'+ */i + '-full';

    this.modalService.open(this.modalSeeFullSlider, {
      ariaLabelledBy: 'modal-basic-title', centered: true, /* size: 'md', */windowClass: 'full-window-modal-slider'
    }).result.then((result) => {
      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  test(e) {
    //console.log(e);
    this.active = e.current;
    this.changeDetectorRef.detectChanges();
  }
  parseInt(num) {
    return parseInt(num)
  }
}
